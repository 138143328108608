import { Menu } from 'antd';
import styled from 'styled-components';
import { useState } from 'react';
import UsersTable from './UsersTable';

export default function () {
  const [selected, setSelected] = useState('company');

  return (
    <Container>
      <Menu
        selectedKeys={[selected]}
        style={{ height: '100%', width: '200px' }}
        mode='vertical'>
        <Menu.Item key='company'>Company</Menu.Item>
      </Menu>
      <Content>
        <UsersTable />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;
const Content = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 1rem;
`;
