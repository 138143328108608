export default function combineAndRemoveOldData(data, mergeValue, newName) {
  // Filter out the matching elements from data and sum their counts
  const sum = data
    .filter(element => mergeValue.includes(element.name))
    .reduce((acc, element) => acc + element.count, 0);

  // Remove the matching elements from data
  data = data.filter(element => !mergeValue.includes(element.name));

  // Add the new object to the data
  data.push({ name: newName, count: sum });

  // Sort the data by name
  data.sort((a, b) => a.name.localeCompare(b.name));

  // Return the updated data
  return data;
}
