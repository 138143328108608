// GraphQL
import { gql, useMutation } from '@apollo/client';

const GET_STATE = gql`
  mutation GetPartMarkingState($forwardingUrl: String!) {
    getState(forwardingUrl: $forwardingUrl) {
      message
    }
  }
`;

export const useGetState = () => {
  const [mutate, { data, loading, error }] = useMutation(GET_STATE);
  return { mutate, data, loading, error };
};
