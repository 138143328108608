import { Select } from 'antd';
import styled from 'styled-components';
import { GET_USERS } from '../../../../../graphQL/users/queries/getUsers';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

export default function ({ operation, updateOperator }) {
  const [user, setUser] = useState(operation.completedById);

  const { data } = useQuery(GET_USERS, {});

  const users =
    data?.users?.data?.map(user => ({
      label: `${user.givenName} ${user.familyName}`,
      value: user._id,
    })) ?? [];

  useEffect(() => {
    setUser(operation.completedById);
  }, [operation]);

  return (
    <Container>
      <OperatorSelect
        value={user}
        style={{ width: '150px' }}
        options={users}
        onSelect={v => setUser(v)}
      />
      <CompletionButton onClick={() => updateOperator(user)}>Set Operator</CompletionButton>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 10px;
  outline: none;
  border: none;
`;
const CompletionButton = styled.button`
  width: 150px;
  text-align: center;
  cursor: pointer;
  background-color: #173753;
  border-radius: 4px;
  text-align: center;
  outline: none;
  border: none;
  color: white;
  user-select: none;
  &:hover {
    transition-duration: 0.3s;
    background-color: #1c5485;
  }
  &:disabled {
    background-color: lightgrey;
    color: darkgrey;
    cursor: not-allowed;
  }
`;
const OperatorSelect = styled(Select)`
  border: 1px solid lightgrey;
  border-radius: 4px;
  margin-right: 15px;
  outline: none;
  width: 135px;
  text-align: center;
  & > * > * {
    font-size: 14px;
  }
`;
