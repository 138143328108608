// GraphQL
import { gql, useMutation } from "@apollo/client";

const REMOVE_USER =  gql`
  mutation RemoveUser($filter: JSON) {
    removeUser(filter: $filter)
  }
`;

export const useRemoveUser = () => {
  const [mutate, { data, loading, error }] = useMutation(REMOVE_USER);
  return { mutate, data, loading, error };
};
