import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import WorkstationPageTemplate from '../WorkstationPageTemplate';
import FormDropdown from '../../../../reusable/forms/FormDropdown';
import FormStringInput from '../../../../reusable/forms/FormStringInput';
import FormIntegerInput from '../../../../reusable/forms/FormIntegerInput';
import { Button, message, Modal, InputNumber } from 'antd';
import { getBoxLabel, getBinLabel, getPickLabel } from './labels_duplicated';

export default function LabelPrinting({ goBack, promptForQty = true }) {
  const [labelValues, setLabelValues] = useState({});
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [statusText, setStatusText] = useState('Checking printer connection...');
  const [printQty, setPrintQty] = useState(1);

  // Check printer status every 10 seconds while mounted
  useEffect(() => {
    heartbeat();
    let heartbeatInterval = setInterval(() => heartbeat(), 15000);
    return () => clearInterval(heartbeatInterval);
  }, []);

  const heartbeat = async () => {
    // Attempt connection to Zebra Browser Print
    let device;
    try {
      device = await getDefaultDevice();
      // Ensure a default device is set up
      if (typeof device !== 'object' || Object.keys(device).length === 0) {
        setStatusText('No default printer is set.');
        setLoading(false);
        setReady(false);
        return;
      }
      setSelectedPrinter(device);
    } catch (err) {
      setStatusText('Unable to connect to Zebra Browser Print Client.');
      setLoading(false);
      setReady(false);
      return;
    }

    // Check the status of the printer
    try {
      const status = await getSelectedPrinterStatus({ device });
      if (status === undefined || status.includes('Unable')) {
        setStatusText('Unable to connect to printer.');
        setLoading(false);
        setReady(false);
        return;
      } else {
        setReady(true);
        setLoading(false);
        setStatusText(`Ready to print`);
      }
    } catch (err) {
      setStatusText('Error connecting to printer.');
      setLoading(false);
      setReady(false);
      return;
    }
  };

  // Get the default device configured within Zebra Browser Print
  const getDefaultDevice = () =>
    new Promise((resolve, reject) => {
      var req = new XMLHttpRequest();
      req.open('GET', 'http://127.0.0.1:9100/default?type=printer');
      req.onload = () => {
        resolve(JSON.parse(req.response));
      };
      req.onerror = () => {
        reject(req.statusText);
      };
      req.send();
    });

  // Send a ZPL string to the selected printer
  const writeToPrinter = ({ device, data }) =>
    new Promise((resolve, reject) => {
      var req = new XMLHttpRequest();
      req.open('POST', 'http://127.0.0.1:9100/write', true);
      req.onload = () => {
        resolve(req.response);
      };
      req.onerror = () => {
        message.error('Failed to print.');
        reject(req.statusText);
      };
      req.send(JSON.stringify({ device, data }));
    });

  // Read data from the selected printer
  const readFromPrinter = ({ device }) =>
    new Promise((resolve, reject) => {
      var req = new XMLHttpRequest();
      req.open('POST', 'http://127.0.0.1:9100/read', true);
      req.onload = () => {
        resolve(req.response);
      };
      req.onerror = () => {
        reject(req.statusText);
      };
      req.send(JSON.stringify({ device }));
    });

  // Get the status of the selected printer
  const getSelectedPrinterStatus = async ({ device }) => {
    await writeToPrinter({ device, data: '~hs' });
    return await readFromPrinter({ device });
  };

  // For each selected item, print a label
  const onPrint = async () => {
    setLoading(true);
    setStatusText('Printing...');

    try {
      switch (selectedLabel) {
        case 'Box Label':
          await writeToPrinter({
            device: selectedPrinter,
            data: getBoxLabel({ ...labelValues, printQty }),
          });
          break;
        case 'Bin Label':
          await writeToPrinter({
            device: selectedPrinter,
            data: getBinLabel({ ...labelValues, printQty }),
          });
          break;
        case 'Pick Label':
          await writeToPrinter({
            device: selectedPrinter,
            data: getPickLabel({ ...labelValues, printQty }),
          });
          break;
        default:
          return;
      }
    } catch (e) {
      setStatusText('Failed to print');
    } finally {
      setLoading(false);
    }
  };

  return (
    <WorkstationPageTemplate title={'Label Printing'} goBack={goBack}>
      <FormContainer>
        <p style={{ color: loading ? '#c2b011' : ready ? 'green' : '#c21126' }}>
          Printer status: {statusText}
        </p>
        <FormDropdown
          label='Label Type'
          placeholder='Select label type'
          onChange={label => {
            setLabelValues({});
            setSelectedLabel(label);
          }}
          options={['Box Label', 'Bin Label', 'Pick Label']}
        />
        <FormIntegerInput
          label='Qty to Print'
          value={printQty}
          min={0}
          max={50}
          onChange={v => setPrintQty(v)}
        />
        {selectedLabel && <Hr />}
        {selectedLabel &&
          (selectedLabel === 'Box Label' ? (
            <BoxLabelForm labelValues={labelValues} setLabelValues={setLabelValues} />
          ) : selectedLabel === 'Bin Label' ? (
            <BinLabelForm labelValues={labelValues} setLabelValues={setLabelValues} />
          ) : selectedLabel === 'Pick Label' ? (
            <PickLabelForm labelValues={labelValues} setLabelValues={setLabelValues} />
          ) : null)}
        {selectedLabel && (
          <Button
            type='primary'
            style={{ marginTop: '15px' }}
            // disabled={!ready}
            loading={loading}
            onClick={onPrint}>
            Print
          </Button>
        )}
      </FormContainer>
    </WorkstationPageTemplate>
  );
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 500px;
`;

const Hr = styled.hr`
  width: 100%;
  background-color: #ababab;
  height: 3px;
  border-radius: 1.5px;
  border: none;
`;

const BoxLabelForm = ({ labelValues, setLabelValues }) => (
  <>
    <FormDropdown
      label='Work Order Type'
      placeholder='Select'
      options={['stock', 'custom', 'rework']}
      value={labelValues['type']}
      onChange={v => setLabelValues({ ...labelValues, ['type']: v })}
      defaultValue='stock'
    />
    <FormIntegerInput
      label='Work Order Number'
      value={labelValues['workOrderNumber']}
      onChange={v => setLabelValues({ ...labelValues, ['workOrderNumber']: v })}
    />
    <FormStringInput
      label='Part Number'
      value={labelValues['partNumber']}
      onChange={v => setLabelValues({ ...labelValues, ['partNumber']: v })}
    />
    <FormStringInput
      label='Box Quantity'
      value={labelValues['boxQuantity']}
      onChange={v => setLabelValues({ ...labelValues, ['boxQuantity']: v })}
    />
    <FormStringInput
      label='OEM Mfg'
      value={labelValues['oemManufacturer']}
      onChange={v => setLabelValues({ ...labelValues, ['oemManufacturer']: v })}
    />
  </>
);

const BinLabelForm = ({ labelValues, setLabelValues }) => (
  <>
    <FormDropdown
      label='Work Order Type'
      placeholder='Select'
      options={['stock', 'custom', 'rework']}
      value={labelValues['type']}
      onChange={v => setLabelValues({ ...labelValues, ['type']: v })}
      defaultValue='stock'
    />
    <FormIntegerInput
      label='Work Order Number'
      value={labelValues['workOrderNumber']}
      onChange={v => setLabelValues({ ...labelValues, ['workOrderNumber']: v })}
    />
    <FormStringInput
      label='Part Number'
      value={labelValues['partNumber']}
      onChange={v => setLabelValues({ ...labelValues, ['partNumber']: v })}
    />
    <FormStringInput
      label='Qty to Build'
      value={labelValues['qtyToBuild']}
      onChange={v => setLabelValues({ ...labelValues, ['qtyToBuild']: v })}
    />
  </>
);

const PickLabelForm = ({ labelValues, setLabelValues }) => (
  <>
    <FormStringInput
      label='Sales Order Number'
      value={labelValues['salesOrderNumber']}
      onChange={v => setLabelValues({ ...labelValues, ['salesOrderNumber']: v })}
    />
    <FormStringInput
      label='Part Number'
      value={labelValues['partNumber']}
      onChange={v => setLabelValues({ ...labelValues, ['partNumber']: v })}
    />
    <FormStringInput
      label='Qty'
      value={labelValues['qty']}
      onChange={v => setLabelValues({ ...labelValues, ['qty']: v })}
    />
    <FormStringInput
      label='Customer Last'
      value={labelValues['customerLast']}
      onChange={v => setLabelValues({ ...labelValues, ['customerLast']: v })}
    />
    <FormStringInput
      label='Postal Code'
      value={labelValues['postalCode']}
      onChange={v => setLabelValues({ ...labelValues, ['postalCode']: v })}
    />
  </>
);
