import React, { useState, useEffect } from 'react';
// History
import history from '../../../../routing/history';
// Animations
import anime from 'animejs';
// Styled components
import styled from 'styled-components';
// Feather icons
import { Home, Tag, List, Monitor } from 'react-feather';
// Material UI
import AttachMoneyRounded from '@material-ui/icons/AttachMoneyRounded';
// Custom components
import NavButton from './NavButton';

const navButtons = [
  { title: 'Dashboard', route: '/app/dashboard', icon: () => <Home /> },
  { title: 'Products', route: '/app/products', icon: () => <Tag /> },
  { title: 'Sales Orders', route: '/app/sales-orders', icon: () => <AttachMoneyRounded /> },
  { title: 'Production', route: '/app/work-orders', icon: () => <List /> },
  { title: 'Workstations', route: '/app/workstations', icon: () => <Monitor /> },
];

function NavButtonGroup() {
  const [selectedButton, setSelectedButton] = useState(null);

  useEffect(() => {
    const navRoutes = navButtons.map(navButton => navButton.route);
    if (!navRoutes.includes(history.location.pathname)) {
      setSelectedButton(null);
    } else {
      const newIndex = navRoutes.findIndex(route => route === history.location.pathname);
      setSelectedButton(newIndex);
      anime({
        targets: '#ButtonFollower',
        top: 20 + newIndex * 77,
        easing: 'easeOutElastic(1, 2)',
        duration: 500,
      });
    }
  }, [history.location]);

  const onClick = ({ route }) => {
    // Go to the new route
    history.push(route);
  };

  return (
    <div>
      <ButtonFollower id='ButtonFollower' show={selectedButton !== null} />
      {navButtons.map((navButton, index) => (
        <NavButton
          key={'NavButton' + index}
          index={index}
          data={navButton}
          selected={selectedButton === index}
          onClick={() => onClick({ route: navButton.route })}
        />
      ))}
    </div>
  );
}
export default NavButtonGroup;

const ButtonFollower = styled.div`
  position: absolute;
  top: calc(20px);
  left: 0;
  width: 5px;
  height: 57px;
  background-color: ${props => (props.show ? '#ff6666' : 'rgba(0, 0, 0, 0)')};
  border-radius: 0 3px 3px 0;
`;
