// GraphQL
import { gql, useMutation } from '@apollo/client';

const VERIFY_BETA_CODE = gql`
  mutation VerifyBetaCode($betaCode: String!) {
    verifyBetaCode(betaCode: $betaCode)
  }
`;

export const useVerifyBetaCode = () => {
  const [mutate, { data, loading, error }] = useMutation(VERIFY_BETA_CODE);
  return { mutate, data, loading, error };
};
