import React from 'react';
// Styled components
import styled from 'styled-components';

export default function FormIntegerInput({
  style,
  inputStyle,
  label,
  value,
  onChange,
  disabled,
  min,
  max,
}) {
  // Handle data changes differently based on the type.
  const handleChange = e => {
    let newValue = parseInt(e.target.value);
    if (!isNaN(newValue)) {
      onChange(newValue);
    } else {
      onChange('');
    }
  };

  return (
    <Container style={style}>
      {label && <InputLabel>{label}</InputLabel>}
      <Input
        style={inputStyle}
        type='number'
        step={1}
        value={value || ''}
        min={min}
        max={max}
        onChange={onChange ? handleChange : null}
        disabled={!onChange || disabled ? true : false}
      />
    </Container>
  );
}
const Container = styled.div``;

const InputLabel = styled.div`
  font-size: 12px;
  color: black;
`;

const Input = styled.input`
  font-size: 16px;
  width: 100%;
  border-radius: 2px;
  border: none;
  outline: none;
  padding: 4px 8px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.05);

  &:disabled {
    background-color: #ededed;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
