import React, { useState, useEffect } from 'react';
// Routing
import { withRouter, Prompt } from 'react-router-dom';
// Styled components
import styled from 'styled-components';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
// Ant Design
import { Button } from 'antd';
// GraphQL
import { useQuery } from '@apollo/client';
import { GET_SALESORDER } from '../../../../graphQL/salesOrders/queries/getSalesOrder';
import { useUpdateSalesOrder } from '../../../../graphQL/salesOrders/mutations/updateSalesOrder';
// Custom Components
import DetailsCard from './cards/DetailsCard';
import LineItemsCard from './cards/LineItemsCard/LineItemsCard';
import CustomerCard from './cards/CustomerCard';
// Deep Object Diff
import { diff } from 'deep-object-diff';
// Bar Loader
// import BarLoader from 'react-bar-loader';
// Helper Functions
import setNestedKeyOnClone from '../../../../reusable/helper functions/setNestedKeyOnClone';
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

function EditSalesOrder({ match, goBack }) {
  const { salesOrderNumber } = match.params ?? {};

  const {
    loading: queryLoading,
    data: queryData,
    error,
  } = useQuery(GET_SALESORDER, {
    fetchPolicy: 'network-only',
    notifyOnNetsalesStatusChange: true,
    variables: {
      filter: { salesOrderNumber },
    },
  });

  // When the current sales order is fetched, set it in state
  const [originalSalesOrder, setOriginalSalesOrder] = useState({});
  const [editedSalesOrder, setEditedSalesOrder] = useState({});
  useEffect(() => {
    const salesOrder = queryData?.salesOrder?.data?.[0];
    if (salesOrder) {
      setEditedSalesOrder(salesOrder);
      setOriginalSalesOrder(salesOrder);
    }
  }, [queryData]);

  // Editing the sales order
  const onChange = ({ path, value }) => {
    const newEditedSalesOrder = setNestedKeyOnClone({ object: editedSalesOrder, path, value });
    setEditedSalesOrder(newEditedSalesOrder);
  };

  // Track when the edited sales order is different than the original sales order
  const [salesOrderIsEdited, setSalesOrderIsEdited] = useState(false);
  useEffect(() => {
    const changes = diff(originalSalesOrder, editedSalesOrder);
    if (Object.keys(changes).length > 0) {
      setSalesOrderIsEdited(true);
      // If there are unsaved changes, prompt the user if they try to close
      // the tab or refresh the page
      window.onbeforeunload = () => true;
    } else {
      setSalesOrderIsEdited(false);
      window.onbeforeunload = undefined;
    }
  }, [originalSalesOrder, editedSalesOrder]);

  const onBack = () => {
    // TODO: Check for changes before we go back. If there
    // are changes, prompt the user to save the changes.
    goBack();
  };

  // When the user has saved, automatically go back. We must wait for "productIsEdited" to be false
  const [goBackFlag, setGoBackFlag] = useState(false);
  useEffect(() => {
    if (goBackFlag && !salesOrderIsEdited) goBack();
  }, [goBackFlag, salesOrderIsEdited]);

  const BackButton = () => (
    <IconButton onClick={onBack}>
      <BackIcon size='small' />
    </IconButton>
  );

  // Reset the sales order to original state
  const onCancel = () => {
    setEditedSalesOrder(originalSalesOrder);
  };

  // Save the changes to the sales order
  const { mutate: updateSalesOrder, loading: saveLoading } = useUpdateSalesOrder();
  const onSave = async () => {
    try {
      await updateSalesOrder({
        variables: {
          filter: { salesOrderNumber: editedSalesOrder.salesOrderNumber },
          salesOrder: editedSalesOrder,
        },
        refetchQueries: ['GetSalesOrder'],
      });

      setGoBackFlag(true);
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  return (
    <>
      {/* 
          If there are unsaved changes, prompt the user before navigating 
          using any kind of routing within the app 
      */}
      <Prompt
        when={salesOrderIsEdited}
        message='You have unsaved changes. Are you sure you want to leave the page?'
      />
      <Header>
        <HeaderContent>
          <BackButton />
          <Title>Edit Sales Order | {salesOrderNumber}</Title>
          {salesOrderIsEdited ? (
            <>
              <CancelButton
                size='large'
                onClick={onCancel}>
                Cancel
              </CancelButton>
              <SaveButton
                size='large'
                type='primary'
                onClick={onSave}>
                Save Changes <ArrowBackRounded fontSize='small' />
              </SaveButton>
            </>
          ) : null}
        </HeaderContent>
        {/* {queryLoading || saveLoading ? <StyledBarLoader height={3} color='#8aa1b5' /> : null} */}
      </Header>
      <Body>
        <DetailsCard
          editedSalesOrder={editedSalesOrder}
          onChange={onChange}
        />
        <CustomerCard
          editedSalesOrder={editedSalesOrder}
          onChange={onChange}
        />
        <LineItemsCard
          editedSalesOrder={editedSalesOrder}
          onChange={onChange}
        />
      </Body>
    </>
  );
}
export default withRouter(EditSalesOrder);

const Header = styled.div`
  border-bottom: 2px solid #7e95a8;
  padding-bottom: 15px;
`;
const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  column-gap: 30px;
  overflow-y: auto;
  height: 100%;
  border-radius: 8px;
  padding-top: 15px;
`;
const Title = styled.div`
  font-size: 24px;
  margin-left: 35px;
`;
const CancelButton = styled(Button)`
  margin-left: auto;
`;
const SaveButton = styled(Button)`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
// const StyledBarLoader = styled(BarLoader)`
//   position: relative;
//   margin-top: -3px;
//   bottom: -14px;
// `;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
