import React from 'react';
// Styled Components
import styled from 'styled-components';

export default function Actions({ actionButtons, setFilters }) {
  return (
    <Container>
      <Label>actions</Label>
      <ActionsContainer>
        {actionButtons.map((ActionButton, index) => (
          <ActionButton key={`ActionButton_${index}`} setFilters={setFilters} />
        ))}
      </ActionsContainer>
    </Container>
  );
}

const Container = styled.div``;

const Label = styled.label`
  color: #173753;
  font-size: 14px;
  font-variant: small-caps;
  margin-left: 5px;
`;

const ActionsContainer = styled.div`
  width: fit-content;
  background-color: #dddddd;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 4px 10px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
`;
