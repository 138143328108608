import React from 'react';
// Styled
import styled from 'styled-components';

const EnvBanner = () => {
  const env = process.env.REACT_APP_NODE_ENV ?? process.env.NODE_ENV;

  const envs = {
    development: {
      background: '#444444',
    },
    qa: {
      background: '#21805a',
    },
    production: {
      background: '',
    },
  };

  return (
    (env == 'development' || env == 'qa') && (
      <>
        <Container style={{ background: envs?.[env]?.background }}>
          Env: <Span>{env}</Span>
        </Container>
      </>
    )
  );
};

export default EnvBanner;

const Container = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background: #173753;
  padding: 8px 15px 9px;
  border-radius: 5px;
  color: #fff;
  line-height: normal;
  font-weight: 300;
`;

const Span = styled.span`
  font-weight: 600;
`;
