import React from 'react';
//Material UI
import IconButton from '@material-ui/core/IconButton';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Tooltip from '@material-ui/core/Tooltip';
// GraphQL
import { gql, useMutation } from '@apollo/client';
// Helper Functions
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

export default function RenumberOpenPriorityButton() {
  // Mutation for re-number open priorities.
  const [renumberOpenPriorities, { loading, error }] = useMutation(
    gql`
      mutation RenumberOpenPriorities {
        renumberOpenPriorities {
          workOrderNumber
        }
      }
    `
  );

  const onRenumber= async () => {
    try {
      await renumberOpenPriorities({
        refetchQueries: ['GetWorkOrders'],
      });
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  return (
    <Tooltip title='Renumber open priorities' placement='top' enterDelay={1500}>
      <IconButton
        aria-label='renumber open priorities'
        style={{ color: '#2e2e2e' }}
        onClick={onRenumber}
        disabled={loading}>
        <ImportExportIcon/>
      </IconButton>
    </Tooltip>
  );
}
