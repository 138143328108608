import React, { useState } from 'react';
// Styled Components
import styled from 'styled-components';
// Material Design
import FilterIcon from '@material-ui/icons/FilterListRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import IconButton from '@material-ui/core/IconButton';
// Moment
import moment from 'moment';
// Ant Design
import { Popover, Input, DatePicker } from 'antd';

export default function FilterPopOver({ column, onFilterColumn }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterInput, setFilterInput] = useState(null);
  const [filterValues, setFilterValues] = useState([]);

  const addFilter = value => {
    if (filterValues.includes(value)) return;
    if (value === undefined || value === null || value === '') return;

    // Set the filterValues in the UI
    const newFilterValues = [...filterValues, value];
    setFilterValues(newFilterValues);

    // Reset the input
    setFilterInput(null);

    // Set the filterValues in the query
    onFilterColumn({ field: column.dataIndex, values: newFilterValues });
  };

  const removeFilter = value => {
    // Remove the filter from the UI
    const newFilterValues = [...filterValues].filter(filterValue => filterValue !== value);
    setFilterValues(newFilterValues);

    // Remove the filter from the query
    onFilterColumn({ field: column.dataIndex, values: newFilterValues });
  };

  const clearFilters = () => {
    setFilterValues([]);
    onFilterColumn({ field: column.dataIndex, values: null });
    setAnchorEl(null);
  };

  const popoverContent = (
    <PopoverContainer>
      <FilterTitle>Filter</FilterTitle>
      <Hr />
      <InputContainer>
        {column.dataType === 'date' ? (
          <StyledDatePicker
            value={filterInput}
            format='MM/DD/YYYY'
            allowClear={false}
            onChange={momentDate => {
              if (momentDate && momentDate instanceof moment) {
                addFilter(momentDate.toDate());
              }
            }}
          />
        ) : column.dataType === 'number' ? (
          <>
            <StyledInput
              type='number'
              value={filterInput}
              onChange={e => {
                const value = e?.target?.value;
                setFilterInput(value ? Number(value) : null);
              }}
              onPressEnter={() => {
                addFilter(filterInput);
              }}
            />
            <IconButton size='small' onClick={() => addFilter(filterInput)}>
              <AddIcon />
            </IconButton>
          </>
        ) : (
          <>
            <StyledInput
              value={filterInput}
              onChange={e => setFilterInput(e.target.value)}
              onPressEnter={() => addFilter(filterInput)}
            />
            <IconButton size='small' onClick={() => addFilter(filterInput)}>
              <AddIcon />
            </IconButton>
          </>
        )}
      </InputContainer>
      <FiltersContainer>
        {filterValues.map(value => (
          <Row>
            <FilterValue>
              {value instanceof Date ? moment(value).format('MM/DD/YYYY') : value}
            </FilterValue>
            <DeleteFilterButton fontSize='small' onClick={() => removeFilter(value)} />
          </Row>
        ))}
      </FiltersContainer>
      {filterValues.length > 0 ? (
        <Row>
          <ClearButton onClick={clearFilters}>Clear</ClearButton>
        </Row>
      ) : null}
    </PopoverContainer>
  );

  return (
    <Container>
      <Popover
        trigger='click'
        placement='right'
        arrowPointAtCenter={false}
        content={popoverContent}>
        <StyledFilterIcon
          fontSize='small'
          $active={filterValues.length > 0}
          onClick={e => setAnchorEl(e.currentTarget)}
          className='material-icons'>
          filter_alt
        </StyledFilterIcon>
      </Popover>
    </Container>
  );
}

const Container = styled.div``;

const Row = styled.div`
  text-align: center;
  padding: 5px 0px;
  border-bottom: 1px dotted lightgrey;
  min-height: 33px;

  :last-child {
    border-bottom: none;
  }
`;

const StyledFilterIcon = styled(FilterIcon)`
  margin: auto 5px -4px 5px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#ff4d4d' : 'lightgray')};

  &:hover {
    color: #ff4d4d;
  }
`;

const FilterTitle = styled.div`
  text-align: center;
  font-size: 18px;
  padding: 0;
`;

const FilterValue = styled.span``;

const DeleteFilterButton = styled(DeleteIcon)`
  cursor: pointer;
  float: right;
  opacity: 0.1;

  ${Row}:hover & {
    opacity: 0.75;
  }
`;

const Hr = styled.hr`
  border: none;
  height: 1px;
  background-color: lightgrey;
  margin: 0 -10px 10px -10px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const PopoverContainer = styled.div`
  padding: 4px 10px 0 10px;
  overflow: hidden;
`;

const StyledInput = styled(Input)`
  margin-right: 10px;
`;

const StyledDatePicker = styled(DatePicker)`
  margin-right: 10px;
  width: 175px;
`;

const FiltersContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const ClearButton = styled.button`
  width: 100%;
  padding: 2px 0;
  text-align: center;
  font-size: 12px;
  background-color: #828282;
  border-radius: 6px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #737373;
  }
`;
