import React, { useState } from 'react';
// Styled components
import styled from 'styled-components';
// Material UI
import Spinner from '@material-ui/core/CircularProgress';
// GraphQL
import { useLogin } from '../../../graphQL/authentication/login';
import broadCastApolloError from '../../../reusable/helper functions/broadCastApolloError';
// Routing
import history from '../../../routing/history';
import { HistoryOutlined } from '@material-ui/icons';

export default function LoginPage({ loading, error }) {
  const { mutate: login } = useLogin();

  loading = false;

  const [state, setState] = useState({});

  const onChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onSignInClicked = async e => {
    try {
      const { token, user } = (await login({ variables: state }))?.data?.login;

      localStorage.setObject('user', user);
      localStorage.setItem('token', token);

      history.push('/app/dashboard');
    } catch (e) {
      broadCastApolloError(e);
    }
  };

  const onForgotPasswordClicked = () => {
    console.log('forgot password clicked');
  };

  const onCreateAccountClicked = () => {
    history.push('/register');
  };

  return (
    <LoginContainer>
      <SignInTitle>Sign In</SignInTitle>
      <Form onSubmit={e => e.preventDefault()}>
        <InputLabel>Username</InputLabel>
        <Input name='email' type='text' value={state.email} onChange={onChange}></Input>
        <InputLabel>Password</InputLabel>
        <Input
          name='password'
          type='password'
          value={state.password}
          onChange={onChange}></Input>
        <SignInButton type='submit' onClick={onSignInClicked}>
          {loading ? <StyledSpinner size='22px' thickness={4} /> : 'Sign in'}
        </SignInButton>
        <CreateAccount onClick={onCreateAccountClicked}>Create account</CreateAccount>
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      </Form>
    </LoginContainer>
  );
}

const SignInTitle = styled.div`
  color: white;
  font-size: 36px;
`;
const LoginContainer = styled.div`
  width: 600px;
  background-color: #173753;
  margin: 0 auto 35px auto;
  border-radius: 15px;
  box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.2);
  padding: 20px;

  display: flex;
  flex-direction: column;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
`;
const InputLabel = styled.div`
  margin-top: 25px;
  font-size: 11px;
  color: #ffffff;
`;
const Input = styled.input`
  border: none;
  width: 100%;
`;
const SignInButton = styled.button`
  cursor: pointer;
  margin: 40px 0 0 0;
  border: none;
  width: 100%;
  height: 33px;
  color: white;
  background-color: #ff6666;
  border-radius: 6px;

  &:hover {
    transition-duration: 0.3s;
    background-color: #ff4d4d;
  }
`;
const ForgotPassword = styled.button`
  margin: 5px 0 15px 0;
  font-size: 11px;
  color: white;
  border: none;
  background: none;
  text-align: left;
  display: inline-block;
  width: fit-content;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
const CreateAccount = styled.button`
  margin: 5px 0 15px 0;
  font-size: 11px;
  color: white;
  border: none;
  background: none;
  text-align: left;
  display: inline-block;
  width: fit-content;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
const StyledSpinner = styled(Spinner)`
  &.MuiCircularProgress-root {
    margin-top: 3px;
  }
  &.MuiCircularProgress-colorPrimary {
    color: white;
  }
`;
const ErrorMessage = styled.div`
  text-align: center;
  color: #ff6666;
  fontsize: 16px;
`;
