import React from 'react';
// Custom Components
import OperationCard from './OperationCard';

export default function Operations({ workOrder }) {
  const { operations } = workOrder;

  if (!operations) return null;

  const sortedOperations = Array.from(operations).sort((a, b) => {
    if (a.group !== b.group) return a.group - b.group;
    return a.groupIndex - b.groupIndex;
  });

  return sortedOperations.map(operation => (
    <OperationCard
      key={`${workOrder.workOrderNumber}_${operation.name}`}
      operation={operation}
      workOrder={workOrder}
    />
  ));
}
