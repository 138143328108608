// GraphQL
import { gql, useMutation } from '@apollo/client';

const UPDATE_USER = gql`
  mutation UpdateUser($user: JSON) {
    updateUser(user: $user) {
      company {
        _id
      }
    }
  }
`;

export const useUpdateUser = () => {
  const [mutate, { data, loading, error }] = useMutation(UPDATE_USER);
  return { mutate, data, loading, error };
};
