import React, { useState } from 'react';
// Styled components
import styled from 'styled-components';
// Custom components
import ManualShipModal from './ManualShipModal';
import Table from '../../../../reusable/tables/Table/Table';
// Ant Design
import { Steps, Input, Button } from 'antd';
import { ScanOutlined, LoadingOutlined, CheckOutlined } from '@ant-design/icons';
// Moment
import moment from 'moment';

const { Step } = Steps;
const { Search } = Input;

export default function ShippingScanProcess({
  onInput,
  scanProcessData,
  setScanProcessData,
  salesOrderItemsQuery,
  salesOrderItemsQueryParams,
}) {
  const { step, title, prompt, scanned, borderColor, currentSalesOrder, loading } =
    scanProcessData;

  const [scannerInput, setScannerInput] = useState(null);

  const reset = () => {
    setScanProcessData({
      step: 0,
      title: 'Scan Sales Order',
      prompt: 'Scan the pick label to get started',
      borderColor: 'white',
      loading: false,
      currentSalesOrder: {},
    });
  };

  const [handlingInput, setHandlingInput] = useState(false);

  const onChange = async e => {
    const { value } = e.target;

    if (RegExp(/^~.*~$/).test(value)) {
      if (handlingInput) return;

      setHandlingInput(true);
      await onInput(value.replaceAll('~', ''));
      setHandlingInput(false);

      setScannerInput(null);
    } else {
      setScannerInput(e.target.value);
    }
  };

  const onSearch = async () => {
    if (handlingInput) return;

    setHandlingInput(true);
    await onInput(scannerInput);
    setHandlingInput(false);

    setScannerInput(null);
  };

  return (
    <Container borderColor={borderColor} hasItems={currentSalesOrder?.items?.length}>
      <Steps current={step ?? 0}>
        <Step
          title='Scan Sales Order'
          icon={loading && step === 0 ? <LoadingOutlined /> : null}
        />
        <Step
          title='Scan Work Order'
          icon={loading && step === 1 ? <LoadingOutlined /> : null}
        />
        <Step
          title='Scan Quantity'
          icon={loading && step === 2 ? <LoadingOutlined /> : null}
        />
      </Steps>
      <Title>{title ?? ''}</Title>
      <ScannerInputContainer>
        <div>
          <Search
            value={scannerInput}
            style={{ textAlign: 'center' }}
            onSearch={onSearch}
            onChange={onChange}
            enterButton={<ScanOutlined />}
          />
          <Scanned>{scanned ? `Scanned: ${scanned}` : ''}</Scanned>
        </div>
        <Button style={{ marginLeft: '25px' }} onClick={reset}>
          Reset
        </Button>
      </ScannerInputContainer>

      <PromptToUser>{prompt ?? ''}</PromptToUser>
      {currentSalesOrder?.items?.length ? (
        <>
          <SectionBar>{`Current Sales Order: ${currentSalesOrder?.salesOrderNumber}`}</SectionBar>
          <Table
            columns={[
              {
                title: 'SKU',
                dataIndex: 'sku',
              },
              {
                title: 'Part Number',
                dataIndex: 'partNumber',
              },
              {
                title: 'Qty Ordered',
                dataIndex: 'qty',
              },
              {
                title: 'Ship By',
                dataIndex: 'plannedShipDate',
                render: ({ plannedShipDate }) =>
                  plannedShipDate ? moment(plannedShipDate).format('MM/DD/YYYY') : null,
              },
              { title: 'Notes', dataIndex: 'notes' },
              { title: 'WO Number', dataIndex: 'workOrderNumber', width: 70 },
              {
                title: 'Shipped',
                dataIndex: 'actualShipDate',
                render: ({ actualShipDate }) => {
                  return actualShipDate ? <CheckOutlined /> : null;
                },
              },
              {
                render: item => (
                  <ManualShipModal
                    item={item}
                    scanProcessData={scanProcessData}
                    setScanProcessData={setScanProcessData}
                    salesOrderItemsQuery={salesOrderItemsQuery}
                    salesOrderItemsQueryParams={salesOrderItemsQueryParams}
                  />
                ),
                width: 1,
              },
            ]}
            data={currentSalesOrder?.items ?? []}
          />
        </>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  width: 65%;
  height: ${({ hasItems }) => (hasItems ? '1500px' : '250px')};
  background-color: white;
  border: ${props =>
    props.borderColor ? `6px solid ${props.borderColor}` : '6px solid white'};
  border-radius: 15px;
  margin: 0 auto 0 auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.1);
`;
const ScannerInputContainer = styled.div`
  width: 350px;
  margin 10px auto;
  display: flex;
  flex-direction: row;
`;
const Title = styled.div`
  font-size: 30px;
  color: #636363;
  text-align: center;
  margin-top: 20px;
`;
const Scanned = styled.div`
  font-size: 13px;
  color: #636363;
  text-align: center;
  font-style: italic;
  margin: 4px auto 0 auto;
`;
const PromptToUser = styled.div`
  font-size: 20px;
  color: #636363;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
  max-width: 700px;
`;
const SectionBar = styled.div`
  font-size: 24px;
`;
