import React, { useEffect, useState } from 'react';
// Styled components
import styled from 'styled-components';
// Custom components
import SquareLogo from '../../reusable/branding/SquareLogo/SquareLogo';

export default function LandingPageTemplate({ children }) {
  const window = useWindowSize();
  const polygonPoints = `-300,${window.height} 
                        ${window.width / 3},-50 
                        ${window.width + 300},${window.height / 3}`;

  return (
    <>
      {/* background */}
      <svg width='100%' height='100%'>
        <polygon points={polygonPoints} fill='#ebebeb' />
      </svg>

      <Container>
        <SquareLogo
          style={{
            position: 'absolute',
            top: '25px',
            left: '25px',
            width: '100px',
            boxShadow: '0px 2px 8px 1px rgba(0, 0, 0, 0.25)',
            borderRadius: '4px',
          }}
        />
        {children}
      </Container>
    </>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  border-right: 1px dashed lightgrey;
  border-left: 1px dashed lightgrey;
  overflow: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: default; 
}
`;

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
      });
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
