export const handleDragAndDrop = ({ source, destination, operationGroups }) => {
  const sourceGroup = +source.droppableId;
  const destinationGroup = +destination.droppableId;

  const reordered = sourceGroup === destinationGroup;

  if (reordered) {
    // The item was reordered within its group
    const group = reorderOperation(
      operationGroups[+source.droppableId],
      source.index,
      destination.index
    );
    const newOperationGroups = [...operationGroups];
    newOperationGroups[sourceGroup] = group;
    return newOperationGroups;
  } else {
    // The item was moved between groups
    const result = moveOperation(
      operationGroups[sourceGroup],
      operationGroups[destinationGroup],
      source,
      destination
    );
    const newOperationGroups = [...operationGroups];
    newOperationGroups[sourceGroup] = result[sourceGroup];
    newOperationGroups[destinationGroup] = result[destinationGroup];
    return newOperationGroups;
  }
};

// Moves an item to a different spot on the same list
export const reorderOperation = (operationGroups, startGroupIndex, endGroupIndex) => {
  const newOperationGroups = Array.from(operationGroups);
  const [removed] = newOperationGroups.splice(startGroupIndex, 1);
  newOperationGroups.splice(endGroupIndex, 0, removed);

  return newOperationGroups;
};

// Moves an item from one list to another list.
export const moveOperation = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

// Returns an array of operations mapped to groups so they can be mapped to UI components
export const mapOperationsToGroups = operations => {
  const groups = [];
  operations.forEach(operation => {
    if (!typeof operation.group === 'number')
      throw {
        code: 'operations/no-group',
        message: `Incorrect value for "group" for operation ${operation.name}.`,
      };
    if (!typeof operation.groupIndex === 'number')
      throw {
        code: 'operations/no-groupIndex',
        message: `Incorrect value for "groupIndex" for operation ${operation.name}`,
      };
    if (!groups[operation.group]) groups[operation.group] = [];
    if (groups[operation.group][operation.groupIndex])
      throw {
        code: 'operations/bad-groupIndex',
        message: `Error mapping operation id ${operation.id}. An operation already exists in group ${operation.group} at groupIndex ${operation.groupIndex}`,
      };
    groups[operation.group][operation.groupIndex] = operation;
  });
  return groups;
};

// Given an initial array of operations, takes operationGroups and outputs
// any changes that will be required by the database
export const diffOperationGroups = ({ operationGroups, initialOperations }) => {
  // Get possibly modified operations as an array
  let newOperations = [];
  operationGroups.forEach((group, gIndex) => {
    group.forEach((operation, oIndex) => {
      newOperations.push({ ...operation, group: gIndex, groupIndex: oIndex });
    });
  });

  // Check initialOperations against newOperations
  let changes = [];
  initialOperations.forEach(initialOperation => {
    // Check if operation was deleted
    const newOperationIndex = newOperations.findIndex(
      newOperation => initialOperation.id === newOperation.id
    );
    if (newOperationIndex === -1) {
      changes.push({ type: 'delete', id: initialOperation.id });
      return;
    } else {
      // Get the matching operation
      const newOperation = { ...newOperations[newOperationIndex] };

      // Remove from newOperations, so we know which operations are actually new
      newOperations.splice(newOperationIndex, 1);

      // Check if the group changed
      if (initialOperation.group !== newOperation.group) {
        changes.push({
          type: 'update',
          id: newOperation.id,
          update: { group: newOperation.group },
        });
      }

      // Check if the groupIndex changed
      if (initialOperation.groupIndex !== newOperation.groupIndex) {
        changes.push({
          type: 'update',
          id: newOperation.id,
          update: { groupIndex: newOperation.groupIndex },
        });
      }
    }
  });

  // Any new operations left must be created
  newOperations.forEach(newOperation => {
    const { id, ...opNoId } = newOperation;
    changes.push({ type: 'create', id: newOperation.id, operation: opNoId });
  });

  return changes;
};

// Creates a flat array of operations from operationGroups. In the process,
// assigns new group and groupIndex values
export const flattenOperationGroups = operationGroups => {
  let operations = [];
  for (const [group, groupArray] of Object.entries(operationGroups)) {
    for (const [groupIndex, operation] of Object.entries(groupArray)) {
      operations.push({ ...operation, group: +group, groupIndex: +groupIndex });
    }
  }
  return operations;
};
