import React, { useState, useEffect } from 'react';
//Routing
import { Prompt } from 'react-router-dom';
// Styled components
import styled from 'styled-components';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
// Ant Design
import { Button } from 'antd';
// GraphQL
import { gql, useMutation } from '@apollo/client';
// Custom Components
import DetailsCard from './cards/DetailsCard';
import LineItemsCard from './cards/LineItemsCard/LineItemsCard';
// Deep Object Diff
import { diff } from 'deep-object-diff';
// Bar Loader
// import BarLoader from 'react-bar-loader';
// Helper Functions
import setNestedKeyOnClone from '../../../../reusable/helper functions/setNestedKeyOnClone';
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

export default function CreateSalesOrder({ goBack, salesOrdersPath }) {
  const onBack = () => {
    // TODO: Check for changes before we go back. If there
    // are changes, prompt the user to save the changes.
    goBack();
  };

  const BackButton = () => (
    <IconButton onClick={onBack}>
      <BackIcon size='small' />
    </IconButton>
  );

  // When the current sales order is fetched, set it in state
  const [salesOrder, setSalesOrder] = useState({});

  // Prompt the user about unsaved changes if they navigate away from the page
  const [promptSaveOnExit, setPromptSaveOnExit] = useState(false);
  useEffect(() => {
    if (Object.keys(salesOrder).length > 0) {
      window.onbeforeunload = () => true;
      setPromptSaveOnExit(true);
    }
  }, [salesOrder]);

  // Editing the sales order
  const onChange = ({ path, value }) => {
    const newEditedSalesOrder = setNestedKeyOnClone({ object: salesOrder, path, value });
    setSalesOrder(newEditedSalesOrder);
  };

  // Reset the sales order to original state
  const onCancel = () => {
    goBack();
  };

  // Mutation for saving the changes to the sales order
  const [createSalesOrder, { loading: createLoading }] = useMutation(
    gql`
      mutation CreateSalesOrder($salesOrder: JSON) {
        createSalesOrder(salesOrder: $salesOrder) {
          salesOrderNumber
        }
      }
    `
  );

  // Create the sales order
  const onCreate = async () => {
    try {
      await createSalesOrder({
        variables: {
          salesOrder,
        },
      });
      setPromptSaveOnExit(false);
      setSalesOrderCreated(true);
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  // Go back on successful creation
  const [salesOrderCreated, setSalesOrderCreated] = useState(false);
  useEffect(() => {
    if (salesOrderCreated && !promptSaveOnExit) goBack();
  }, [salesOrderCreated, promptSaveOnExit]);

  return (
    <>
      {/* 
          If there are unsaved changes, prompt the user before navigating 
          using any kind of routing within the app 
      */}
      <Prompt
        when={promptSaveOnExit}
        message='You have unsaved changes. Are you sure you want to leave the page?'
      />
      <Header>
        <HeaderContent>
          <BackButton />
          <Title>Create Sales Order</Title>
          {promptSaveOnExit && (
            <>
              <CancelButton
                size='large'
                onClick={onCancel}>
                Cancel
              </CancelButton>
              <SaveButton
                size='large'
                type='primary'
                onClick={onCreate}>
                Create Sales Order <ArrowBackRounded fontSize='small' />
              </SaveButton>
            </>
          )}
        </HeaderContent>
        {/* {createLoading ? <StyledBarLoader height={3} color='#8aa1b5' /> : null} */}
      </Header>
      <Body>
        <Col>
          <DetailsCard
            editedSalesOrder={salesOrder}
            onChange={onChange}
            creating
          />
        </Col>
        <div />
        <LineItemsCard
          editedSalesOrder={salesOrder}
          onChange={onChange}
        />
      </Body>
    </>
  );
}

const Header = styled.div`
  border-bottom: 2px solid #7e95a8;
  padding-bottom: 15px;
`;
const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  column-gap: 30px;
  overflow-y: auto;
  height: 100%;
  border-radius: 8px;
  padding-top: 15px;
`;
const Title = styled.div`
  font-size: 24px;
  margin-left: 35px;
`;
const CancelButton = styled(Button)`
  margin-left: auto;
`;
const SaveButton = styled(Button)`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
// const StyledBarLoader = styled(BarLoader)`
//   position: relative;
//   margin-top: -3px;
//   bottom: -14px;
// `;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
