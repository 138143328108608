import React from 'react';
// History
import history from '../../../../routing/history';
// Styled Components
import styled from 'styled-components';
// Feather icons
import { MessageSquare } from 'react-feather';
// Custom Components
import Avatar from './Avatar';
import SettingMenu from './SettingMenu';
import SmallNavButton from './SmallNavButton';

export default function BottomButtonGroup() {
  const onClick = ({ route }) => {
    history.push(route);
  };

  return (
    <Container>
      {smallNavButtons.map((smallNavButton, index) => (
        <SmallNavButton
          key={'SmallNavButton' + index}
          data={smallNavButton}
          onClick={() => onClick({ route: smallNavButton.route })}
        />
      ))}
      <SettingMenu />
      <Avatar />
    </Container>
  );
}

const smallNavButtons = [
  { title: 'System Messages', route: '/app/system-messages', icon: () => <MessageSquare /> },
];

const Container = styled.div`
  margin-top: auto;
`;
