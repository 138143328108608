// GraphQL
import { gql, useMutation } from '@apollo/client';

const REGISTER_COMPANY = gql`
  mutation RegisterCompany($company: JSON) {
    registerCompany(company: $company) {
      token
      user {
        givenName
        role
      }
    }
  }
`;

export const useRegisterCompany = () => {
  const [mutate, { data, loading, error }] = useMutation(REGISTER_COMPANY);
  return { mutate, data, loading, error };
};
