import React, { useState, useEffect } from 'react';
// GraphQL
import { gql, useQuery } from '@apollo/client';
// Styled components
import styled from 'styled-components';
// Ant Design
import { Select } from 'antd';

export default function PartNumberDropdown({ style, label, value, onChange }) {
  const [partNumbers, setPartNumbers] = useState([]);

  // GraphQL Query
  const query = gql`
    query GetProducts($sort: JSON, $filter: JSON, $pagination: JSON) {
      products(sort: $sort, filter: $filter, pagination: $pagination) {
        data {
          partNumber
        }
      }
    }
  `;
  const queryParams = {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      sort: { partNumber: 1 },
    },
  };
  const { loading, error, data: queryData } = useQuery(query, queryParams);

  // When the query finishes, set the part numbers array
  useEffect(() => {
    const products = queryData?.products?.data;
    if (products) setPartNumbers(products.map(product => product.partNumber));
  }, [queryData]);

  const handleChange = value => {
    onChange(value);
  };

  return (
    <Container>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        showSearch
        placeholder='Select part number'
        style={{ width: '100%' }}
        onChange={onChange ? handleChange : null}
        disabled={!onChange ? true : false}
        loading={loading}>
        {partNumbers.map(partNumber => (
          <Select.Option
            key={`PNDropdown_${partNumber}`}
            style={{ fontSize: '16px', padding: '4px 8px' }}
            value={partNumber}>
            {partNumber}
          </Select.Option>
        ))}
      </Select>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const InputLabel = styled.div`
  font-size: 12px;
  color: black;
`;
