// GraphQL
import { gql, useMutation } from '@apollo/client';

const MARK_PART = gql`
  mutation MarkPart(
    $forwardingUrl: String!
    $partNumber: String!
    $workOrderNumber: Int!
    $serialNumber: Int!
  ) {
    markPart(
      forwardingUrl: $forwardingUrl
      partNumber: $partNumber
      workOrderNumber: $workOrderNumber
      serialNumber: $serialNumber
    ) {
      message
    }
  }
`;

export const useMarkPart = () => {
  const [mutate, { data, loading, error }] = useMutation(MARK_PART);
  return { mutate, data, loading, error };
};
