import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import getNestedKey from '../../helper functions/getNestedKey';

export default function TableDataComponent({ column, rowData, rowIndex }) {
  if (!column) return;

  const { dataIndex, dataType, render, flex, style } = column;
  const value = dataIndex ? getNestedKey({ object: rowData, path: dataIndex }) : null;

  return render ? (
    <TD flex={flex} style={style}>
      {render({ ...rowData, rowData, rowIndex })}
    </TD>
  ) : !dataIndex ? (
    <TD flex={flex} style={style} />
  ) : dataType === 'date' ? (
    <TD flex={flex} style={style}>
      {value ? moment(value).format('MM/DD/YYYY') : null}
    </TD>
  ) : (
    <TD flex={flex} style={style}>
      {value}
    </TD>
  );
}

const TD = styled.div`
  padding: 8px 15px;
  text-align: center;
  border-left: thin dotted lightgrey;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: ${({ flex }) => flex ?? '1'};

  &:first-child {
    border-left: none;
  }
`;
