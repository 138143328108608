// GraphQL
import { gql, useMutation } from '@apollo/client';

const CREATE_USER = gql`
  mutation createUser($user: JSON) {
    createUser(user: $user) {
      user {
        givenName
        role
      }
    }
  }
`;

export const useCreateUser = () => {
  const [mutate, { data, loading, error }] = useMutation(CREATE_USER);
  return { mutate, data, loading, error };
};
