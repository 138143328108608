import React from 'react';
// Styled Components
import styled from 'styled-components';

export default function TableHeaderComponent({ column }) {
  if (!column) return;

  const { dataIndex, title, flex } = column;

  return (
    <TH key={dataIndex} flex={flex}>
      <HeaderText>{title}</HeaderText>
    </TH>
  );
}

const TH = styled.div`
  background-color: #173753;
  color: #ffffff;
  padding: 12px 0;
  font-size: 15px;
  font-weight: 300;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: ${({ flex }) => flex ?? '1'};

  &:first-child {
    border-radius: 8px 0 0 0;
  }

  &:last-child {
    border-radius: 0 8px 0 0;
  }
`;

const HeaderText = styled.div`
  white-space: pre;
  text-align: center;
  margin-bottom: auto;
`;
