// GraphQL
import { gql } from '@apollo/client';

export const GET_DASHBOARD_DATA_BY_DEPARTMENT = gql`
  query GetDashboardDataByDepartment($fromDate: String, $toDate: String) {
    getDashboardDataByDepartment(fromDate: $fromDate, toDate: $toDate) {
      result {
        name
        count
      }
    }
  }
`;
