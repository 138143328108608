import React from 'react';
// Styled components
import styled from 'styled-components';

export default function Beta({ state, setState }) {
  const onChange = e => {
    setState({ betaCode: e.target.value });
  };

  return (
    <Container>
      <Title>
        {`We are currently in beta.\n\nTo join as an early adopter please contact info@meshmrp.com`}
      </Title>
      <InputLabel>Beta code</InputLabel>
      <Input onChange={onChange} />
    </Container>
  );
}

const Container = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto;
`;
const Title = styled.span`
  margin-bottom: 30px;
  font-size: 18px;
  white-space: pre-wrap;
  text-align: center;
`;
const InputLabel = styled.div`
  font-size: 11px;
  color: #ffffff;
  width: 100%;
  text-align: left;
`;
const Input = styled.input`
  border: none;
  width: 100%;
  color: black;
  text-align: center;
  margin-bottom: 20px;
`;
