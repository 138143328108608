import React, { useState, useEffect } from 'react';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
// Ant Design
import { Checkbox } from 'antd';
// GraphQL
import { useUpdateSalesOrderItem } from '../../../../graphQL/salesOrders/mutations/updateSalesOrderItem';

export default function EditablShippedCheckbox({ item }) {
  const { _id, actualShipDate } = item ?? {};

  const [checked, setChecked] = useState(actualShipDate ? true : false);
  useEffect(() => {
    setChecked(actualShipDate ? true : false);
  }, [actualShipDate]);

  const onChange = e => {
    const checked = e.target.checked;
    setChecked(checked);
    updateSalesOrderItem({
      variables: { item: { _id, actualShipDate: checked ? new Date() : null } },
    });
  };

  const { mutate: updateSalesOrderItem, loading, data, error } = useUpdateSalesOrderItem();

  return loading ? (
    <CircularProgress size={28} />
  ) : (
    <Checkbox checked={checked} onChange={onChange} />
  );
}
