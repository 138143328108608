import React, { useState, useEffect } from 'react';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Print from '@material-ui/icons/PrintRounded';
// Ant Design
import { message, Modal, InputNumber } from 'antd';

export default function PrintIcon({ selectedItems, getLabel, labelTitle, promptForQty }) {
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [statusText, setStatusText] = useState('Checking printer connection...');
  const [printQty, setPrintQty] = useState(1);
  const [modal, setModal] = useState(false);

  // Check printer status every 10 seconds while mounted
  useEffect(() => {
    heartbeat();
    let heartbeatInterval = setInterval(() => heartbeat(), 15000);
    return () => clearInterval(heartbeatInterval);
  }, []);

  const heartbeat = async () => {
    // Attempt connection to Zebra Browser Print
    let device;
    try {
      device = await getDefaultDevice();
      // Ensure a default device is set up
      if (typeof device !== 'object' || Object.keys(device).length === 0) {
        setStatusText('No default printer is set.');
        setReady(false);
        return;
      }
      setSelectedPrinter(device);
    } catch (err) {
      setStatusText('Unable to connect to Zebra Browser Print Client.');
      setReady(false);
      return;
    }

    // Check the status of the printer
    try {
      const status = await getSelectedPrinterStatus({ device });
      if (status === undefined || status.includes('Unable')) {
        setStatusText('Unable to connect to printer.');
        setReady(false);
        return;
      } else {
        setReady(true);
        setStatusText(`Print ${labelTitle}`);
      }
    } catch (err) {
      setStatusText('Error connecting to printer.');
      setReady(false);
      return;
    }
  };

  // Get the default device configured within Zebra Browser Print
  const getDefaultDevice = () =>
    new Promise((resolve, reject) => {
      var req = new XMLHttpRequest();
      req.open('GET', 'http://127.0.0.1:9100/default?type=printer');
      req.onload = () => {
        resolve(JSON.parse(req.response));
      };
      req.onerror = () => {
        reject(req.statusText);
      };
      req.send();
    });

  // Send a ZPL string to the selected printer
  const writeToPrinter = ({ device, data }) =>
    new Promise((resolve, reject) => {
      var req = new XMLHttpRequest();
      req.open('POST', 'http://127.0.0.1:9100/write', true);
      req.onload = () => {
        resolve(req.response);
      };
      req.onerror = () => {
        message.error('Failed to print.');
        reject(req.statusText);
      };
      req.send(JSON.stringify({ device, data }));
    });

  // Read data from the selected printer
  const readFromPrinter = ({ device }) =>
    new Promise((resolve, reject) => {
      var req = new XMLHttpRequest();
      req.open('POST', 'http://127.0.0.1:9100/read', true);
      req.onload = () => {
        resolve(req.response);
      };
      req.onerror = () => {
        reject(req.statusText);
      };
      req.send(JSON.stringify({ device }));
    });

  // Get the status of the selected printer
  const getSelectedPrinterStatus = async ({ device }) => {
    await writeToPrinter({ device, data: '~hs' });
    return await readFromPrinter({ device });
  };

  const onClick = () => {
    if (!ready) {
      message.error(`Printer is not ready. Status: ${statusText}`);
      return;
    }

    if (loading) return;

    if (promptForQty) {
      setModal(true);
    } else {
      onPrint();
    }
  };

  // For each selected item, print a label
  const onPrint = async () => {
    setModal(false);

    setLoading(true);
    await Promise.all(
      selectedItems.map(item => {
        writeToPrinter({
          device: selectedPrinter,
          data: getLabel({ ...item, printQty }),
        });
      })
    );
    setLoading(false);
  };

  return (
    <>
      <Tooltip title={statusText}>
        <IconButton
          onClick={onClick}
          style={{ color: loading ? 'yellow' : ready ? 'green' : 'red' }}>
          <Print />
        </IconButton>
      </Tooltip>
      <Modal
        title='Print'
        visible={modal}
        onOk={onPrint}
        onCancel={() => {
          setModal(false);
        }}>
        <p>Enter qty to print:</p>
        <InputNumber
          defaultValue={1}
          min={1}
          max={50}
          onChange={value => setPrintQty(value)}
        />
      </Modal>
    </>
  );
}
