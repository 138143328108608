import React from 'react';
// Styled Components
import styled from 'styled-components';
// Bar Loader
// import BarLoader from 'react-bar-loader';

export default function TableBarLoader() {
  return (
    <th
      style={{ padding: 0 }}
      colSpan='100'>
      {/* <StyledLoader height={4} color='#FF6666' /> */}
    </th>
  );
}

// const StyledLoader = styled(BarLoader)`
//   margin-top: -2px;
// `;
