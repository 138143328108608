import React from 'react';
// GraphQL
import { GET_SALESORDER_ITEMS } from '../../../graphQL/salesOrders/queries/getSalesOrderItems';
// Styled components
import styled from 'styled-components';
// Routing
import history from '../../../routing/history';
import { useRouteMatch } from 'react-router-dom';
// Custom components
import DataTable from '../../../reusable/tables/DataTable/DataTable';
import BulkDeleteButton from './bulk action buttons/BulkDeleteButton';
import PrintIcon from '../../../reusable/label printing/PrintIcon';
import { getPickLabel } from '../../../reusable/label printing/labels';
import EditableNotes from './editable fields/EditableNotes';
import EditablePlannedShipDate from './editable fields/EditablePlannedShipDate';
import EditablePoNumber from './editable fields/EditablePoNumber';
import EditableShippedCheckbox from './editable fields/EditableShippedCheckbox';
import EditablePartNumber from './editable fields/EditablePartNumber';
//Material UI
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddRounded';
import Tooltip from '@material-ui/core/Tooltip';

export default function SalesOrderItemsTable() {
  const { path } = useRouteMatch();

  const bulkActionButtons = [
    // ({ selectedRowKeys, disabled }) => (
    //   <BulkDeleteButton selectedRowKeys={selectedRowKeys} disabled={disabled} />
    // ),
    ({ selectedItems }) => (
      <PrintIcon
        selectedItems={selectedItems}
        getLabel={getPickLabel}
        labelTitle='Pick Label(s)'
        promptForQty={true}
      />
    ),
  ];

  const actionButtons = [
    () => (
      <Tooltip title='Create new sales order' placement='top' enterDelay={1500}>
        <IconButton
          aria-label='add'
          style={{ color: '#2e2e2e' }}
          onClick={() => history.push(`${path}/create`)}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    ),
  ];

  const columns = [
    {
      title: 'Sales Order\nNumber',
      dataIndex: 'salesOrder.salesOrderNumber',
      flex: '0 1 105px',
      render: ({ salesOrder }) => (
        <SalesOrderLink
          onClick={() => history.push(`${path}/edit/${salesOrder.salesOrderNumber}`)}>
          {salesOrder.salesOrderNumber}
        </SalesOrderLink>
      ),
      filterable: true,
      sortable: true,
    },
    {
      title: 'Customer Last',
      dataIndex: 'salesOrder.billingInfo.customerLast',
      flex: '0 1 95px',
      style: { justifyContent: 'left' },
      filterable: true,
      sortable: true,
    },
    {
      title: 'Date Ordered',
      dataIndex: 'salesOrder.dateOrdered',
      dataType: 'date',
      flex: '0 1 120px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      dataType: 'number',
      flex: '0 1 65px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      flex: '0 0 150px',
      style: { justifyContent: 'left' },
      filterable: true,
      sortable: true,
    },
    {
      title: 'Part Number',
      dataIndex: 'partNumber',
      flex: '0 0 150px',
      style: { justifyContent: 'left' },
      render: ({ rowData }) => <EditablePartNumber item={rowData} />,
      filterable: true,
      sortable: true,
    },
    {
      title: 'PO #',
      dataIndex: 'custom.poNumber',
      flex: '0 1 150px',
      render: ({ rowData }) => <EditablePoNumber item={rowData} />,
      filterable: true,
      sortable: true,
    },
    {
      title: 'Price',
      dataIndex: 'salesPrice',
      dataType: 'number',
      flex: '0 1 95px',
      render: ({ salesPrice }) => (salesPrice ? `$${salesPrice.toFixed(2)}` : null),
      filterable: true,
      sortable: true,
    },
    {
      title: 'Weight',
      dataIndex: 'custom.weight',
      dataType: 'number',
      flex: '0 1 80px',
      render: ({ custom }) => (custom?.weight ? `${custom.weight.toFixed(0)} lbs` : null),
      filterable: true,
      sortable: true,
    },
    {
      title: 'Box',
      dataIndex: 'custom.boxPartNumber',
      flex: '0 1 80px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'Shipping Method',
      dataIndex: 'shippingDescription',
      flex: '0 0 120px',
      style: { justifyContent: 'left' },
      filterable: true,
      sortable: true,
    },
    {
      title: 'Customer ZIP',
      dataIndex: 'salesOrder.billingInfo.postalCode',
      flex: '0 1 90px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'Planned Ship Date',
      dataIndex: 'plannedShipDate',
      dataType: 'date',
      flex: '0 1 130px',
      render: ({ rowData }) => <EditablePlannedShipDate item={rowData} />,
      filterable: true,
      sortable: true,
    },
    {
      title: 'Shipped',
      flex: '0 1 60px',
      render: ({ rowData }) => <EditableShippedCheckbox item={rowData} />,
    },
    {
      title: 'Item Notes',
      render: ({ rowData }) => <EditableNotes item={rowData} />,
    },
  ];

  return (
    <DataTable
      query={GET_SALESORDER_ITEMS}
      rowKey={'_id'}
      columns={columns}
      actionButtons={actionButtons}
      bulkActionButtons={bulkActionButtons}
      tabs={[
        { title: 'Open', filter: { actualShipDate: null } },
        { title: 'Closed', filter: { actualShipDate: { $ne: null } } },
      ]}
      defaultSort={{ plannedShipDate: 1 }}
    />
  );
}

const SalesOrderLink = styled.a``;
