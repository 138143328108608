import React from 'react';
// Styled components
import styled from 'styled-components';
// Custom components
import CardTemplate from '../../../../../reusable/layout/CardTemplate';
import FormStringInput from '../../../../../reusable/forms/FormStringInput';
import FormDateInput from '../../../../../reusable/forms/FormDateInput';

function DetailsCard({ editedSalesOrder, onChange, creating }) {
  return (
    <CardTemplate title='Order Details'>
      <FormContainer>
        <FormStringInput
          label='Sales Order Number'
          value={editedSalesOrder?.salesOrderNumber ?? ''}
          onChange={creating ? value => onChange({ path: 'salesOrderNumber', value }) : null}
        />
        <FormDateInput
          label='Date Ordered'
          value={editedSalesOrder?.dateOrdered ?? ''}
          onChange={value => onChange({ path: 'dateOrdered', value })}
        />
        <FormStringInput
          label='Magento Entity Id'
          value={editedSalesOrder?.magentoInfo?.entityId ?? ''}
          onChange={value => onChange({ path: 'magentoInfo.entityId', value })}
        />
      </FormContainer>
    </CardTemplate>
  );
}
export default DetailsCard;

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
`;
