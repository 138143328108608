import React from 'react';
// Styled components
import styled from 'styled-components';

export default function AppPageTemplate({ title, children }) {
  return (
    <Container>
      <TitleBar>
        <Title>{title}</Title>
      </TitleBar>
      <PageContent>{children}</PageContent>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
`;
const TitleBar = styled.div`
  padding: 12px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Title = styled.div`
  font-size: 28px;
`;
const PageContent = styled.div`
  margin: 0 20px;
  height: calc(100vh - 87px);
  border-radius: 10px;
  background-color: #f3f3f3;
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
