import React from 'react';
// Styled components
import styled from 'styled-components';
// Custom components
import CardTemplate from '../../../../../reusable/layout/CardTemplate';

export default function GalleryCard() {
  return (
    <CardTemplate title='Product'>
      <ImageGalleryPlaceholder>PRODUCT IMAGE GALLERY</ImageGalleryPlaceholder>
      <FormContainer>
        Gallery view of product along with part number, sku, and BOM
      </FormContainer>
    </CardTemplate>
  );
}
const ImageGalleryPlaceholder = styled.div`
  width: auto;
  height: 350px;
  background-color: #fafafa;
  border-radius: 3px;
  margin: auto;
  text-align: center;
  line-height: 350px;
  margin-bottom: 15px;
`;
const FormContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 15px;
  row-gap: 15px;
`;
