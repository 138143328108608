import React, { useState, useEffect } from 'react';
// Styled components
import styled from 'styled-components';
// Ant Design
import { Select, Popover, DatePicker } from 'antd';
// Moment
import moment from 'moment';
// GraphQL
import { useUpdateWorkOrderOperation } from '../../../../../graphQL/workOrders/mutations/updateWorkOrderOperation';
// Helper Functions
import broadcastApolloError from '../../../../../reusable/helper functions/broadCastApolloError';
import parseJwt from '../../../../../reusable/helper functions/parseJwt';
// Custom components
import UpdateOperator from './UpdateOperator';

export default function OperationCard({ operation, workOrder }) {
  const [qtyComplete, setQtyComplete] = useState(operation?.qtyComplete ?? 0);
  const [dateCompleted, setDateCompleted] = useState(
    operation?.dateCompleted ? moment(operation?.dateCompleted) : null
  );

  useEffect(() => {
    setQtyComplete(operation?.qtyComplete ?? 0);
    setDateCompleted(operation?.dateCompleted ? moment(operation?.dateCompleted) : null);
  }, [operation.qtyComplete, operation.dateCompleted]);

  const completed =
    operation?.qtyComplete &&
    workOrder?.qtyToBuild &&
    operation?.qtyComplete >= workOrder?.qtyToBuild;

  const { mutate: updateWorkOrderOperation } = useUpdateWorkOrderOperation();

  const updateQtyComplete = async qtyComplete => {
    const operationUpdates = { _id: operation._id, qtyComplete };

    if (qtyComplete >= workOrder.qtyToBuild) {
      operationUpdates.dateCompleted = new Date();

      const token = localStorage.getItem('token');
      const { userId } = parseJwt(token);
      operationUpdates.completedById = userId;
    } else {
      operationUpdates.dateCompleted = null;
      operationUpdates.completedById = null;
    }

    try {
      await updateWorkOrderOperation({
        variables: {
          operation: operationUpdates,
        },
        refetchQueries: ['GetWorkOrders'],
      });
      closePopover();
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  const updateDateCompleted = async dateCompleted => {
    const operationUpdates = {
      _id: operation._id,
      dateCompleted: dateCompleted instanceof moment ? dateCompleted.toDate() : dateCompleted,
    };

    try {
      await updateWorkOrderOperation({
        variables: {
          operation: operationUpdates,
        },
      });
      closePopover();
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  const updateOperator = async completedById => {
    const operationUpdates = {
      _id: operation._id,
      completedById,
    };

    try {
      await updateWorkOrderOperation({
        variables: {
          operation: operationUpdates,
        },
      });
      closePopover();
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  const [visible, setVisible] = useState(false);
  const closePopover = () => {
    setVisible(false);
  };

  return (
    <Popover
      visible={visible}
      onVisibleChange={visible => setVisible(visible)}
      trigger='click'
      placement='right'
      content={
        <>
          <CustomMenuItem>
            <CompletionInput
              type='number'
              min={0}
              max={workOrder.qtyToBuild}
              step={1}
              value={qtyComplete}
              onChange={e => setQtyComplete(parseInt(e.target.value))}
            />
            <CompletionButton onClick={() => updateQtyComplete(qtyComplete)}>
              Set Qty Complete
            </CompletionButton>
          </CustomMenuItem>
          {completed ? (
            <CustomMenuItem>
              <DatePicker
                onChange={momentDate => setDateCompleted(momentDate)}
                value={dateCompleted}
                format={'MM-DD-YYYY'}
                style={{ borderRadius: '4px', width: '150px' }}
              />
              <CompletionButton onClick={() => updateDateCompleted(dateCompleted)}>
                Set Date Completed
              </CompletionButton>
            </CustomMenuItem>
          ) : null}
          {completed ? (
            <UpdateOperator
              operation={operation}
              updateOperator={updateOperator}
            />
          ) : null}
        </>
      }
      style={{ width: '357px' }}>
      <Container>
        <Card
          operation={operation}
          completed={completed}
          workOrder={workOrder}
          onClick={null}>
          {completed && operation?.dateCompleted
            ? moment(operation.dateCompleted).format('MM/DD')
            : null}
        </Card>
        <OperationName>{operation.name}</OperationName>
      </Container>
    </Popover>
  );
}

const Container = styled.div`
  width: 45px;
  margin: 3px;
`;
const OperationName = styled.div`
  font-size: 8px;
  margin: auto;
  text-align: center;
  height: 10px;
  white-space: nowrap;
`;
const Card = styled.button`
  cursor: pointer;
  padding: 1px 1px;
  border: 1px solid #fafafa;
  outline: none;
  position: relative;
  width: 100%;
  height: 22px;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  background-color: ${props => {
    // COMPLETE
    if (props.completed) {
      return '#06d6a0'; // green
    }
    // TODO: modify this to check late status against work order date required
    // LATE
    // if (moment(props.operation.dateRequired).isBefore(moment(), 'days')) {
    //   return '#ef476f'; // red
    // }
    // IN PROGRESS
    if (props.operation.qtyComplete > 0) {
      return '#ffd166';
    }
    // NOT STARTED
    return 'lightgrey';
  }}};

  &:hover {
    transition-duration: 0.3s;
    border: 1px solid darkgrey;
  }
`;
const CustomMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 10px;
  outline: none;
  border: none;
`;
const CompletionInput = styled.input`
  border: 1px solid lightgrey;
  border-radius: 4px;
  outline: none;
  width: 150px;
  text-align: center;
  height: 32px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const CompletionButton = styled.button`
  width: 150px;
  text-align: center;
  cursor: pointer;
  background-color: #173753;
  border-radius: 4px;
  text-align: center;
  outline: none;
  border: none;
  color: white;
  margin-left: 15px;
  user-select: none;
  &:hover {
    transition-duration: 0.3s;
    background-color: #1c5485;
  }
  &:disabled {
    background-color: lightgrey;
    color: darkgrey;
    cursor: not-allowed;
  }
`;
