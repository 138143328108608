import React, { useState, useEffect } from 'react';
// Styled components
import styled from 'styled-components';

export default function FormCurrencyInput({ style, label, value, onChange }) {
  const [displayValue, setDisplayValue] = useState(
    value && typeof value === 'number' ? value.toFixed(2) : ''
  );

  useEffect(() => {
    if (value && value != displayValue && typeof value === 'number')
      setDisplayValue(value.toFixed(2));
  }, value);

  // Handle data changes differently based on the type.
  const handleChange = e => {
    let newValue = parseFloat(e.target.value);
    if (!isNaN(newValue)) {
      onChange(newValue);
      setDisplayValue(newValue);
    } else {
      onChange(0);
      setDisplayValue('');
    }
  };

  // On blur, format as a proper currency
  const onBlur = () => {
    if (typeof displayValue === 'number')
      if (displayValue) {
        const newDisplayValue = displayValue.toFixed(2);
        const truncatedValue = parseFloat(newDisplayValue);
        setDisplayValue(newDisplayValue);
        onChange(truncatedValue);
      } else {
        setDisplayValue('0.00');
      }
  };

  return (
    <Container style={style}>
      {label && <InputLabel>{label}</InputLabel>}
      <InputGroup>
        <CurrencyLabel>lbs</CurrencyLabel>
        <Input
          type='number'
          min={0.01}
          step={0.01}
          value={displayValue}
          onChange={onChange ? handleChange : null}
          disabled={!onChange ? true : false}
          onBlur={onBlur}
        />
      </InputGroup>
    </Container>
  );
}
const Container = styled.div``;

const InputLabel = styled.div`
  font-size: 12px;
  color: black;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const CurrencyLabel = styled.div`
  background-color: #d0d7db;
  font-size: 16px;
  width: min-content;
  text-align: center;
  border-radius: 2px;
  border: none;
  outline: none;
  padding: 4px 8px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.05);
`;

const Input = styled.input`
  font-size: 16px;
  text-align: center;
  width: 100%;
  border-radius: 2px;
  border: none;
  outline: none;
  padding: 4px 8px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.05);

  &:disabled {
    background-color: #ededed;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
