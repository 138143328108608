import React, { useState, useEffect } from 'react';
// Styled Components
import styled from 'styled-components';
// Moment
import moment from 'moment';
// Ant Design
import { Input, DatePicker } from 'antd';

export default function FilterPopOver({ column, onFilterColumn, lastSearchedColumn }) {
  const [filterInput, setFilterInput] = useState(null);
  const [lastSearched, setLastSearched] = useState(null);

  useEffect(() => {
    if (lastSearchedColumn !== column.dataIndex) setFilterInput(null);
  }, [lastSearchedColumn]);

  const onPressEnter = value => {
    if (value === lastSearched) return;

    // Set the filterValues in the query
    switch (column.dataType) {
      case 'date':
        if (value) {
          const startDate = moment(value).startOf('day').toDate().toISOString();
          const endDate = moment(startDate).add(1, 'days').toDate().toISOString();
          onFilterColumn({
            field: column.dataIndex,
            value: { $gte: startDate, $lt: endDate },
          });
        } else onFilterColumn({ field: column.dataIndex, value: null });
        setLastSearched(value);
        break;
      case 'number':
        onFilterColumn({ field: column.dataIndex, value });
        setLastSearched(value);
        break;
      case 'string':
      default:
        onFilterColumn({ field: column.dataIndex, value: value ? value : null });
        setLastSearched(value);
        break;
    }
  };

  const getInput = () => {
    switch (column.dataType) {
      case 'date':
        return (
          <StyledDatePicker
            value={filterInput}
            format='MM/DD/YYYY'
            allowClear={true}
            onChange={momentDate => {
              setFilterInput(momentDate);
              onPressEnter(momentDate);
            }}
          />
        );
      case 'number':
        return (
          <StyledInput
            type='number'
            value={filterInput}
            allowClear={true}
            onChange={e => {
              const value = e?.target?.value;
              setFilterInput(value ? Number(value) : null);
              if (!value) onPressEnter(null);
            }}
            onPressEnter={e => onPressEnter(e.target.value)}
            onBlur={e => onPressEnter(e.target.value)}
          />
        );
      case 'string':
      default:
        return (
          <StyledInput
            value={filterInput}
            allowClear={true}
            onChange={e => {
              setFilterInput(e.target.value);
              if (!e.target.value) onPressEnter(null);
            }}
            onPressEnter={e => onPressEnter(e.target.value)}
            onBlur={e => onPressEnter(e.target.value)}
          />
        );
    }
  };

  return getInput();
}

const StyledInput = styled(Input)`
  font-size: 14px;
  padding: 2px 4px;

  input {
    text-align: center;
    z-index: 10000;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  font-size: 14px;
  padding: 2px 4px;
  text-align: center;
`;
