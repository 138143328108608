import React, { useState } from 'react';
// History
import history from '../../../../routing/history';
// Styled Components
import styled from 'styled-components';
import { Settings } from 'react-feather';

function SettingMenu({ onClick }) {
  return (
    <Button onClick={onClick}>
      <Settings onClick={() => history.push('/app/settings')} />
    </Button>
  );
}

export default SettingMenu;

const Button = styled.button`
  width: 100%;
  background: none;
  border: none;
  margin: 10px auto 20px auto;
  cursor: pointer;
  outline: none;
  color: white;

  display: flex;
  justify-content: center;

  &:hover {
    color: #ff6666;
  }
`;
