// GraphQL
import { gql } from '@apollo/client';

export const GET_PRODUCT = gql`
  query GetProduct($filter: JSON) {
    product(filter: $filter) {
      data {
        partNumber
        category
        mappedSkus
        qtyOnHand
        minOnHand
        productionNotes
        defaultOperations {
          default {
            name
            group
            groupIndex
          }
          rush {
            name
            group
            groupIndex
          }
        }
        custom
      }
    }
  }
`;
