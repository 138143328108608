import React from 'react';
// Styled components
import styled from 'styled-components';
// Custom components
import CardTemplate from '../../../../../reusable/layout/CardTemplate';
import FormStringInput from '../../../../../reusable/forms/FormStringInput';

export default function GeneralCard({ editedProduct, onChange, creating }) {
  return (
    <CardTemplate title='General'>
      <FormContainer>
        <ImageGalleryPlaceholder>Product Image Gallery</ImageGalleryPlaceholder>
        <FormStringInput
          label='Part Number'
          value={editedProduct?.partNumber}
          onChange={creating ? value => onChange({ path: 'partNumber', value }) : null}
        />
        <FormStringInput
          label='Category'
          value={editedProduct?.category}
          onChange={value => onChange({ path: 'category', value })}
        />
      </FormContainer>
    </CardTemplate>
  );
}

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
`;
const ImageGalleryPlaceholder = styled.div`
  width: 100%;
  height: 300px;
  background-color: #fafafa;
  border-radius: 3px;
  margin: auto;
  text-align: center;
  line-height: 300px;
  margin-bottom: 15px;
  grid-column: 1 / -1;
  color: lightgrey;
`;
