import React from 'react';
// Styled
import styled from 'styled-components';
// Custom Components
import FormDateInput from '../../../reusable/forms/FormDateInput';

const FilterPannel = ({ dashboardFilter, onChange }) => {
  return (
    <Container>
      <FormDateInput
        label='From Date'
        value={dashboardFilter?.fromDate}
        onChange={value => onChange({ path: 'fromDate', value })}
      />
      <FormDateInput
        label='To Date'
        value={dashboardFilter?.toDate}
        onChange={value => onChange({ path: 'toDate', value })}
      />
    </Container>
  );
};

export default FilterPannel;

const Container = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  border-radius: 8px;
  box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.1);
  background-color: #dedede;
  padding: 1rem;
`;
