import React from 'react';
// Styled components
import styled from 'styled-components';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import Back from '@material-ui/icons/ArrowBackRounded';

export default function TaskWorkstation({ goBack, title, children }) {
  return (
    <>
      <TitleBar>
        <Title>{title}</Title>
      </TitleBar>
      <PageContent>{children}</PageContent>
    </>
  );
}

const TitleBar = styled.div`
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Title = styled.div`
  font-size: 36px;
`;
const PageContent = styled.div`
  margin: 0 30px;
  height: calc(100vh - 30px - 56px - 30px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;
`;
