import React, { useState } from 'react';
// GraphQL
import { useQuery } from '@apollo/client';
// Styled Components
import styled from 'styled-components';
// Ant Design
import { Checkbox } from 'antd';
import 'antd/dist/antd.css';
// Moment
import moment from 'moment';
// Custom components
import TableBarLoader from './TableBarLoader';
import TableMessage from './TableMessage';
import TableDataComponent from './TableDataComponent';
import TableHeaderComponent from './TableHeaderComponent';

export default function Table({ columns, data, loading, error }) {
  return (
    <Container>
      <THead>
        <THR>
          {columns.map(column => (
            <TableHeaderComponent column={column} />
          ))}
        </THR>
        {loading && <TableBarLoader />}
      </THead>
      <TBody>
        {data.length > 0 ? (
          data.map((rowData, index) => (
            <TR>
              {columns.map(column => (
                <TableDataComponent column={column} rowData={rowData} rowIndex={index} />
              ))}
            </TR>
          ))
        ) : (
          <TableMessage loading={loading} error={error} dataLength={data.length} />
        )}
      </TBody>
    </Container>
  );
}

const TR = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;

  &:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  &:hover {
    background-color: #e6e6e6;
  }

  padding: 0;
`;

const THR = styled(TR)`
  &:first-child {
    border-radius: 8px 8px 0 0;
  }
`;

const Container = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 25px;
`;

const THead = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow-y: auto;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  background-color: #dddddd;
`;

const TH = styled.div`
  background-color: #173753;
  color: #ffffff;
  text-align: center;
  padding: 12px 15px;
  font-size: 15px;
  font-weight: 300;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-radius: 8px 0 0 0;
  }

  &:last-child {
    border-radius: 0 8px 0 0;
  }

  cursor: ${props => (props.sortable ? 'pointer' : 'auto')};
`;

const TD = styled.div`
  padding: 8px 15px;
  text-align: center;
  border-left: thin dotted lightgrey;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-left: none;
  }
`;
