import React from 'react';
// Styled components
import styled from 'styled-components';

export default function Company({ state, setState }) {
  const onChange = e => {
    setState({
      ...state,
      company: {
        ...state.company,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <Container>
      <ExplanationText>Configure your company.</ExplanationText>
      <Form onSubmit={e => e.preventDefault()}>
        <Row>
          <InputComponent
            title='Company name'
            name='name'
            type='text'
            value={state.company?.name ?? ''}
            onChange={onChange}
          />
        </Row>
      </Form>
    </Container>
  );
}

const InputComponent = ({ title, name, type, value, onChange, error }) => {
  return (
    <InputContainer>
      <InputLabel>{title}</InputLabel>
      <Input name={name} type={type} value={value} onChange={onChange}></Input>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </InputContainer>
  );
};

const ExplanationText = styled.div`
  text-align: left;
  font-size: 16px;
  padding-bottom: 20px;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`;
const InputContainer = styled.div`
  width: 100%;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
`;
const InputLabel = styled.div`
  font-size: 11px;
  color: #ffffff;
`;
const Input = styled.input`
  border: none;
  width: 100%;
  color: black;
`;
const ErrorMessage = styled.div`
  text-align: center;
  color: #ff6666;
  fontsize: 16px;
`;
