import React from 'react';
// Styled components
import styled from 'styled-components';

export default function ProductDetailsCard({ title, children, outerStyle, bodyStyle }) {
  return (
    <OuterBody style={outerStyle}>
      <CardTitle>{title}</CardTitle>
      <CardBody style={bodyStyle}>{children}</CardBody>
    </OuterBody>
  );
}

const OuterBody = styled.div`
  margin-bottom: 15px;
`;
const CardBody = styled.div`
  overflow: auto;
  padding: 10px;
  background-color: #d0d7db;
  border-radius: 6px;
  box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.1);
  font-size: 16px;
`;
const CardTitle = styled.div`
  font-size: 20px;
  padding-left: 5px;
`;
