// GraphQL
import { gql, useMutation } from '@apollo/client';

const REMOVE_WORKORDER = gql`
  mutation RemoveWorkOrder($filter: JSON) {
    removeWorkOrder(filter: $filter) {
      partNumber
    }
  }
`;

export const useRemoveWorkOrder = () => {
  const [mutate, { data, loading, error }] = useMutation(REMOVE_WORKORDER);
  return { mutate, data, loading, error };
};
