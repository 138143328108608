import React, { useState } from 'react';
// GraphQL
import { gql, useMutation } from '@apollo/client';
// Ant Design
import { Button, Modal, Radio } from 'antd';
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

export default function ManualShipModal({
  item,
  scanProcessData,
  setScanProcessData,
  salesOrderItemsQuery,
  salesOrderItemsQueryParams,
}) {
  const [visible, setVisible] = useState(false);
  const [radioValue, setRadioValue] = useState(null);
  const [loading, setLoading] = useState(false);

  // Mutation for saving the changes to the sales order item
  const [updateSalesOrderItem, {}] = useMutation(
    gql`
      mutation UpdateSalesOrderItem($item: JSON!) {
        updateSalesOrderItem(item: $item) {
          _id
          partNumber
          sku
          qty
          plannedShipDate
          actualShipDate
          notes
        }
      }
    `
  );

  const handleOk = async () => {
    setLoading(true);

    // Mark the item as "shipped"
    try {
      const updatedSalesOrderItem = (
        await updateSalesOrderItem({
          variables: {
            item: {
              _id: item._id,
              actualShipDate: new Date(),
              notes: `${item.notes ? item.notes + ' | ' : ''}Manually shipped - ${radioValue}`,
            },
          },
          refetchQueries: [
            {
              query: salesOrderItemsQuery,
              ...salesOrderItemsQueryParams,
            },
          ],
        })
      ).data.updateSalesOrderItem;

      // Determine how to update the items in scanProcessData
      const updatedIndex = scanProcessData.currentSalesOrder.items.findIndex(
        item => item._id === updatedSalesOrderItem._id
      );
      const newItems = [...scanProcessData.currentSalesOrder.items];
      newItems[updatedIndex] = updatedSalesOrderItem;

      // Check if it's the final item to ship out
      const finalWorkOrder =
        scanProcessData.currentSalesOrder.items.filter(
          _item => _item._id !== scanProcessData.currentItem._id && !_item.actualShipDate
        ).length === 0;

      // If not the final work order, alert the user there are more items to ship
      if (finalWorkOrder) {
        setScanProcessData({
          ...scanProcessData,
          currentSalesOrder: { ...scanProcessData.currentSalesOrder, items: newItems },
          step: 0,
          title: 'Scan Sales Order',
          prompt: 'All items are shipped. Scan a new sales order.',
          borderColor: '#173753',
          loading: false,
        });
      } else {
        setScanProcessData({
          ...scanProcessData,
          currentSalesOrder: { ...scanProcessData.currentSalesOrder, items: newItems },
          step: 1,
          title: 'Scan Work Order',
          prompt: 'Item manually marked shipped! Scan the work order for the next item.',
          borderColor: '#00b868',
          loading: false,
        });
      }

      setVisible(false);
      setLoading(false);
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onRadioChange = e => {
    setRadioValue(e.target.value);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>Ship Manually</Button>
      <Modal
        title={`Ship Manually - ${item.partNumber}`}
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            disabled={radioValue === null}
            key='submit'
            type='primary'
            loading={loading}
            onClick={handleOk}>
            Ship
          </Button>,
        ]}>
        <div style={{ color: 'maroon', paddingBottom: '15px' }}>
          WARNING: This bypasses the part number check, does not remove the item from
          inventory, and causes you to lose traceability to the work order.
        </div>
        <div>Select your reason for shipping manually:</div>
        <Radio.Group onChange={onRadioChange} value={radioValue}>
          <Radio style={styles.radioStyle} value={'No box label'}>
            No box label
          </Radio>
          <Radio style={styles.radioStyle} value={'No work order number'}>
            No work order number
          </Radio>
          <Radio style={styles.radioStyle} value={'Part not in system'}>
            Part not in system
          </Radio>
          <Radio style={styles.radioStyle} value={'Other'}>
            Other
          </Radio>
        </Radio.Group>
      </Modal>
    </>
  );
}

const styles = {
  radioStyle: {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  },
};
