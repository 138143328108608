import React, { useState, useEffect } from 'react';
// Ant Design
import { Button } from 'antd';
import { CloudDownloadOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
// Styled components
import styled from 'styled-components';
// GraphQL
import { gql, useQuery } from '@apollo/client';
import broadCastApolloError from '../helper functions/broadCastApolloError';

export default function SkuPartNumberLookup({ item, onProductFound }) {
  const { sku, partNumber } = item;

  const [value, setValue] = useState(partNumber ?? '');
  const [loading, setLoading] = useState(false);
  const [icon, setIcon] = useState(<CloudDownloadOutlined />);

  useEffect(() => {
    setValue(partNumber ?? '');

    if (partNumber) {
      setIcon(<CheckOutlined style={{ color: '#008542' }} />);
    } else {
      setIcon(<CloudDownloadOutlined />);
    }
  }, [partNumber]);

  const GET_PRODUCT = gql`
    query GetProduct($filter: JSON) {
      product(filter: $filter) {
        data {
          partNumber
          custom
        }
      }
    }
  `;

  const getProduct = useImperativeQuery(GET_PRODUCT);

  const onClick = async () => {
    setLoading(true);
    try {
      if (!sku) throw { message: 'SKU does not exist' };

      // Find the product using mappedSkus
      const product = (
        await getProduct({
          filter: { mappedSkus: sku.replace('MRC', '') },
        })
      )?.data?.product?.data?.[0];

      // Set the partNumber on the sales order item
      if (product) {
        await onProductFound({ item, product });
        setValue(product.partNumber ?? '');
        setIcon(<CheckOutlined style={{ color: '#008542' }} />);
      } else {
        setIcon(<CloseOutlined style={{ color: '#94140a' }} />);
      }
    } catch (e) {
      broadCastApolloError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {value}
      <StyledButton
        size='small'
        loading={loading}
        shape='circle'
        icon={icon}
        onClick={onClick}
      />
    </>
  );
}

const StyledButton = styled(Button)`
  margin-left: auto;
  opacity: ${({ loading }) => (loading ? '1' : '0.2')};

  &:hover {
    opacity: 1;
  }
`;

const useImperativeQuery = query => {
  const { refetch } = useQuery(query, { skip: true });

  const callQuery = variables => {
    return refetch(variables);
  };

  return callQuery;
};
