import React from 'react';
// Styled components
import styled from 'styled-components';

export default function User({ state, setState }) {
  const onChange = e => {
    setState({
      ...state,
      user: {
        ...state.user,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <Container>
      <ExplanationText>
        Configure your intial user. Your intial user will have the role of "admin", but this
        can be changed later. More users can be added once you log in.
      </ExplanationText>
      <Form onSubmit={e => e.preventDefault()}>
        <Row>
          <InputComponent
            title='First name'
            name='givenName'
            type='text'
            value={state.user?.givenName ?? ''}
            onChange={onChange}
          />
          <InputComponent
            title='Last name'
            name='familyName'
            type='text'
            value={state.user?.familyName ?? ''}
            onChange={onChange}
          />
        </Row>
        <Row>
          <InputComponent
            title='Email'
            name='email'
            type='email'
            value={state.user?.email ?? ''}
            onChange={onChange}
          />
          <InputComponent
            title='Password'
            name='password'
            type='password'
            value={state.user?.password ?? ''}
            onChange={onChange}
          />
        </Row>
      </Form>
    </Container>
  );
}

const InputComponent = ({ title, name, type, value, onChange, error }) => {
  return (
    <InputContainer>
      <InputLabel>{title}</InputLabel>
      <Input name={name} type={type} value={value} onChange={onChange}></Input>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </InputContainer>
  );
};

const ExplanationText = styled.div`
  text-align: left;
  font-size: 16px;
  padding-bottom: 20px;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`;
const InputContainer = styled.div`
  width: 100%;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
`;
const InputLabel = styled.div`
  font-size: 11px;
  color: #ffffff;
`;
const Input = styled.input`
  border: none;
  width: 100%;
  color: black;
`;
const ErrorMessage = styled.div`
  text-align: center;
  color: #ff6666;
  fontsize: 16px;
`;
