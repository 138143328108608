// Ant Design
import { Form, Input, Select } from 'antd';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    dataIndex === 'role' ? (
      <Select
        options={['operator' , 'admin'].map(role => ({
          label: role,
          value: role,
        }))}
      />
    ) : dataIndex === 'password' ? (
      <Input type='password' />
    ) : (
      <Input />
    );

  const isRequired = dataIndex === 'password' ? false : true;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: isRequired,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
