import { FormHelperText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// Styled components
import styled from 'styled-components';

export default function FormStringInput({
  style,
  inputStyle,
  label,
  value,
  onChange,
  onBlur,
  disabled,
  required,
}) {

  const [requiredErrorMessage, setRequiredErrorMessage] = useState(null);

  const handleChange = e => {
    let newValue = e.target.value;
    onChange(newValue);
  };

  useEffect(() => {
    if (required) {
      (value && value?.length) > 0
        ? setRequiredErrorMessage(null)
        : setRequiredErrorMessage('Required');
    }
  }, [value]);

  return (
    <Container style={style}>
      <InputLabel>{label}</InputLabel>
      <Input
        style={inputStyle}
        key={`input_${label}`}
        value={value || ''}
        onChange={onChange ? handleChange : null}
        onBlur={onBlur}
        disabled={!onChange || disabled ? true : false}
      />
      <FormHelperText
        error
        id='my-helper-text'>
        {requiredErrorMessage}
      </FormHelperText>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;

const InputLabel = styled.div`
  font-size: 12px;
  color: black;
`;

const Input = styled.input`
  font-size: 16px;
  width: 100%;
  max-width: 100%;
  border-radius: 2px;
  border: none;
  outline: none;
  padding: 4px 8px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.05);

  &:disabled {
    background-color: #ededed;
  }
`;
