import React from 'react';
// Styled Components
import styled from 'styled-components';
// Custom Components
import FilterPopOver from './FilterPopOver';
import FilterInput from './FilterInput';

export default function TableHeaderComponent({
  column,
  sort,
  onSortColumn,
  onFilterColumn,
  lastSearchedColumn,
}) {
  if (!column) return;
  const { dataIndex, title, sortable, filterable, flex } = column;
  const sortField = sort && Object.keys(sort)[0];
  const sortOrder = sortField && sort[sortField];

  const onSortClick = () => {
    if (sortable) onSortColumn({ field: dataIndex });
  };

  return (
    <TH sortable={sortable} key={dataIndex} flex={flex}>
      <Row>
        {sortable ? (
          <>
            {sortField === dataIndex ? (
              sortOrder === 1 ? (
                <CaretText sorted={true} onClick={onSortClick}>
                  ▲
                </CaretText>
              ) : (
                <CaretText sorted={true} onClick={onSortClick}>
                  ▼
                </CaretText>
              )
            ) : (
              <CaretText sorted={false} onClick={onSortClick}>
                ⇅
              </CaretText>
            )}{' '}
          </>
        ) : null}
        <HeaderText>{title}</HeaderText>
      </Row>
      <FilterSortContainer>
        {/* Filter icon */}
        {filterable && (
          <FilterInput
            column={column}
            onFilterColumn={onFilterColumn}
            lastSearchedColumn={lastSearchedColumn}
          />
        )}
      </FilterSortContainer>
    </TH>
  );
}

const Row = styled.div`
  width: 100%;
  text-align: center;
`;

const TH = styled.div`
  background-color: #173753;
  color: #ffffff;
  padding: 12px 6px;
  font-size: 15px;
  font-weight: 300;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: ${({ flex }) => flex ?? '1'};
  border-left: dotted 1px #757575;

  &:first-child {
    border-radius: 8px 0 0 0;
    border-left: none;
  }

  &:last-child {
    border-radius: 0 8px 0 0;
  }
`;

const HeaderText = styled.span`
  font-size: 14px;
  white-space: normal;
  width: 100%;
`;

const CaretText = styled.span`
  color: ${({ sorted }) => (sorted ? '#ff4d4d' : 'darkgrey')};
  font-size: 14px;
  margin: 0;
  cursor: pointer;

  &:hover {
    color: #ff4d4d;
  }
`;

const FilterSortContainer = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
