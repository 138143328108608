import React from 'react';
// Ant Design
import { Checkbox } from 'antd';
// Styled components
import styled from 'styled-components';

export default function FormBooleanInput({ style, label, checked, onChange }) {
  checked = Boolean(checked);

  // Handle data changes differently based on the type.
  const handleChange = e => {
    onChange(!checked);
  };

  return (
    <Container style={style}>
      {label && <InputLabel>{label}</InputLabel>}
      <Checkbox checked={checked} onClick={handleChange} />
    </Container>
  );
}
const Container = styled.div``;

const InputLabel = styled.div`
  font-size: 12px;
  color: black;
`;
