import React, { useState, useEffect } from 'react';
// Custom Components
import FormDateInput from '../../../../reusable/forms/FormDateInput';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
// GraphQL
import { useUpdateSalesOrderItem } from '../../../../graphQL/salesOrders/mutations/updateSalesOrderItem';

export default function EditableNotes({ item }) {
  const { _id, plannedShipDate } = item ?? {};

  const [value, setValue] = useState(plannedShipDate);
  useEffect(() => {
    setValue(plannedShipDate);
  }, [plannedShipDate]);

  const onChange = value => {
    setValue(value);

    updateSalesOrderItem({
      variables: { item: { _id, plannedShipDate: value } },
    });
  };

  const { mutate: updateSalesOrderItem, loading, data, error } = useUpdateSalesOrderItem();

  return loading ? (
    <CircularProgress size={28} />
  ) : (
    <FormDateInput value={value} onChange={onChange} />
  );
}
