import React, { useState, useEffect } from 'react';
// GraphQL
import { gql, useQuery } from '@apollo/client';
// Styled components
import styled from 'styled-components';
// Drag and Drop
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// Material UI
import { IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
// Ant Design
import { Menu, Dropdown, Select, Button } from 'antd';
// Custom components
import CardTemplate from '../../../../../../reusable/layout/CardTemplate';
// Helper functions
import {
  mapOperationsToGroups,
  flattenOperationGroups,
  handleDragAndDrop,
} from './helper_functions';

export default function EditOperationsCard({ workOrder, onChange }) {
  let { operations } = workOrder;
  if (!operations) operations = [];

  const [operationGroups, setOperationGroups] = useState(
    operations.length < 1 ? [[]] : mapOperationsToGroups(operations)
  );

  useEffect(() => {
    if (operations.length > 0) {
      const newOperationGroups = mapOperationsToGroups(operations);
      setOperationGroups(newOperationGroups);
    }
  }, [operations]);

  // Product config
  const [productConfig, setProductConfig] = useState(workOrder?.productConfig);
  useEffect(() => {
    setProductConfig(workOrder?.productConfig);
  }, [workOrder]);
  const productQuery = gql`
    query Product($filter: JSON) {
      product(filter: $filter) {
        data {
          partNumber
          defaultOperations {
            default {
              name
              group
              groupIndex
            }
            rush {
              name
              group
              groupIndex
            }
          }
        }
      }
    }
  `;
  const productQueryParams = {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: { partNumber: workOrder?.partNumber || '' },
    },
  };
  const { data: productData } = useQuery(productQuery, productQueryParams);
  const handleProductConfig = value => {
    onChange({ path: 'productConfig', value });
  };
  useEffect(() => {
    const config =
      productData?.product?.data?.[0]?.defaultOperations?.[workOrder.productConfig];
    if (config) {
      onChange({
        path: 'operations',
        value: config.map(operation => ({
          ...operation,
          qtyComplete: 0,
        })),
      });
    }
  }, [workOrder.productConfig]);

  // Base operations
  const baseOperations = [
    { id: '6041c4b8c65b1d0bcc538389', name: 'Braze' },
    { id: '6041c4bdc65b1d0bcc53838a', name: 'Build Bundle' },
    { id: '6041c4c8c65b1d0bcc53838c', name: 'Cut Shell' },
    { id: '6041c4cbc65b1d0bcc53838d', name: 'Cut Shell 2' },
    { id: '6041c4d3c65b1d0bcc53838e', name: 'Cut Tube' },
    { id: '6041c4d8c65b1d0bcc53838f', name: 'Kitting' },
    { id: '6041c4dfc65b1d0bcc538390', name: 'Machine Shell' },
    { id: '6041c4e5c65b1d0bcc538391', name: 'Machine Shell 2' },
    { id: '6041c4ecc65b1d0bcc538392v', name: 'Pack' },
    { id: '6041c4efc65b1d0bcc538393', name: 'Paint' },
    { id: '6041c4f2c65b1d0bcc538394', name: 'Test' },
  ];

  const addOperation = ({ operation, group }) => {
    // Modify within operationGroups structure
    const newOperationGroups = [...operationGroups];
    newOperationGroups[group] = [...operationGroups[group], { ...operation, qtyComplete: 0 }];

    // Flatten to operations
    const newOperations = flattenOperationGroups(newOperationGroups);

    // Set the operations
    onChange({ path: 'operations', value: newOperations });
  };

  const addGroup = () => {
    setOperationGroups([...operationGroups, []]);
  };

  const deleteGroup = groupIndex => {
    // Modify within operationGroups structure
    const newOperationGroups = [...operationGroups];
    newOperationGroups.splice(groupIndex, 1);
    setOperationGroups(newOperationGroups);

    // Flatten to operations
    const newOperations = flattenOperationGroups(newOperationGroups);

    // Set the operations
    onChange({ path: 'operations', value: newOperations });
  };

  const deleteOperation = ({ group, groupIndex }) => {
    // Modify within operationGroups structure
    const newOperationGroups = [...operationGroups];
    newOperationGroups[group].splice(groupIndex, 1);

    // Flatten to operations
    const newOperations = flattenOperationGroups(newOperationGroups);

    // Set the operations
    onChange({ path: 'operations', value: newOperations });
  };

  const onDragEnd = e => {
    // Modify within operationGroups structure
    const newOperationGroups = handleDragAndDrop({ ...e, operationGroups });

    // Flatten to operations
    const newOperations = flattenOperationGroups(newOperationGroups);

    // Set the operations
    onChange({ path: 'operations', value: newOperations });
  };

  const AddOperationMenu = ({ group }) => (
    <Menu style={{ maxHeight: '250px', overflowY: 'auto' }}>
      {baseOperations.map(operation => (
        <Menu.Item onClick={() => addOperation({ operation, group: group })}>
          {operation.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <CardTemplate title='Edit Operations' bodyStyle={{ padding: '25px' }}>
      <Header>
        <p>
          Configure how the operations for this work order will be scheduled. Operations are
          grouped together by day.
        </p>
        <p>
          Product config:
          <Select
            defaultValue='default'
            value={productConfig}
            style={{ width: 120, marginLeft: '20px' }}
            onChange={handleProductConfig}>
            <Select.Option value='default'>Default</Select.Option>
            <Select.Option value='rush'>Rush</Select.Option>
            <Select.Option value='custom'>Custom</Select.Option>
          </Select>
        </p>
      </Header>

      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          {operationGroups
            ? operationGroups.map((groupArray, group) => (
                <Droppable key={group} droppableId={`${group}`}>
                  {(provided, snapshot) => (
                    <>
                      <GroupContainer ref={provided.innerRef} {...provided.droppableProps}>
                        {group > 0 ? (
                          <DeleteGroupIconButton
                            size='small'
                            onClick={() => deleteGroup(group)}>
                            <Delete />
                          </DeleteGroupIconButton>
                        ) : null}
                        <GroupTitle>{`Day ${group + 1}`}</GroupTitle>
                        <Group style={getListStyle(snapshot.isDraggingOver, group)}>
                          {groupArray.map((operation, groupIndex) => (
                            <Draggable
                              key={`${operation.name}_${group}_${groupIndex}`}
                              draggableId={`${operation.name}_${group}_${groupIndex}`}
                              index={groupIndex}>
                              {(provided, snapshot) => (
                                <OpCardContainer>
                                  <OpCard
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}>
                                    {operation.name}
                                    <DeleteItemIconButton
                                      size='small'
                                      onClick={() => deleteOperation({ group, groupIndex })}>
                                      <Delete />
                                    </DeleteItemIconButton>
                                  </OpCard>
                                </OpCardContainer>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                          <Dropdown
                            trigger={['click']}
                            overlay={<AddOperationMenu group={group} />}>
                            <IconButton size='small'>
                              <AddItemIcon />
                            </IconButton>
                          </Dropdown>
                        </Group>
                      </GroupContainer>
                      {group === operationGroups.length - 1 ? (
                        <AddButtonContainer>
                          {' '}
                          <IconButton size='small' onClick={addGroup}>
                            <Add />
                          </IconButton>
                        </AddButtonContainer>
                      ) : null}
                    </>
                  )}
                </Droppable>
              ))
            : 'operations n/a'}
        </DragDropContext>
      </Container>
    </CardTemplate>
  );
}

const Container = styled.div`
  padding: 20px 0;
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #173753;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 9px;
    background-clip: content-box;
  }
`;
const Header = styled.div`
  margin-bottom: 10px;
`;
const OpCard = styled.div`
  height: 36px;
  border: 1px solid #f3f3f3;
  position: relative;
  border-radius: 6px;
  font-size: 12px;
  userselect: none;
  width: 100%;
  padding: 2px 2px 2px 8px;
  margin: 0 0 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const GroupContainer = styled.div`
  position: relative;
`;
const Group = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 32px);
  width: 140px;
  padding: 8px;
`;
const GroupTitle = styled.div`
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
`;
const AddButtonContainer = styled.div`
  padding: 7px 0 0 15px;
`;
const AddItemIcon = styled(Add)`
  opacity: 0.15;

  ${GroupContainer}:hover & {
    opacity: 1 !important;
  }
`;
const OpCardContainer = styled.div`
  position: relative;
  width: 100%;
`;
const DeleteItemIconButton = styled(IconButton)`
  display: block;
  z-index: 1;
  opacity: 0.15;
  margin-left: auto !important;

  ${OpCardContainer}:hover & {
    opacity: 1 !important;
  }
`;
const DeleteGroupIconButton = styled(IconButton)`
  position: absolute !important;
  top: -3px;
  left: calc(100% - 34px);
  margin: auto;
  z-index: 1 !important;
  opacity: 0.15;

  ${GroupContainer}:hover & {
    opacity: 1 !important;
  }
`;
const StyledButton = styled(Button)`
  height: 32px;
  margin-left: 15px;
  font-size: 16px;
  outline: none;
  border: none;
  border-radius: 2px;
`;

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? 'lightgrey' : 'white',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, group) => {
  const styles = {
    background: isDraggingOver ? 'lightblue' : 'rgba(0,0,0,0)',
  };
  const border = '1px dashed #7fa7c9';
  if (group === 0) {
    styles.borderLeft = border;
    styles.borderRight = border;
  } else {
    styles.borderRight = border;
  }
  return styles;
};
