import React, { useEffect, useState } from 'react';
// Ant Design
import { Button } from 'antd';
// Styled components
import styled from 'styled-components';
// Material UI
import { IconButton, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
// Moment
import moment from 'moment';
// GraphQL
import { gql, useQuery, useMutation } from '@apollo/client';
import { useGetState } from '../../../../graphQL/partMarking/mutations/getState';
import { useMarkPart } from '../../../../graphQL/partMarking/mutations/markPart';
import { useStopMarking } from '../../../../graphQL/partMarking/mutations/stopMarking';
// Custom Components
import FormStringInput from '../../../../reusable/forms/FormStringInput';
import DataTable from '../../../../reusable/tables/DataTable/DataTable';
// Helper Functions
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

export default function PartMarkingInterface({ partNumber, workOrderNumber }) {
  const { mutate: getState } = useGetState();
  const { mutate: markPart } = useMarkPart();
  const { mutate: stopMarking } = useStopMarking();

  const [forwardingUrl, setForwardingUrl] = useState('');

  const [getStateOutput, setGetStateOutput] = useState('');
  const [getPartMarkingStateLoading, setGetPartMarkingStateLoading] = useState(false);
  const onGetState = async () => {
    setGetPartMarkingStateLoading(true);
    setGetStateOutput('');
    try {
      const response = await getState({
        variables: { forwardingUrl },
      });
      const message = response?.data?.getState?.message ?? 'Unknown';
      setGetStateOutput(message);
    } catch (e) {
      broadcastApolloError(e);
    } finally {
      setGetPartMarkingStateLoading(false);
    }
  };

  const [markPartOutput, setMarkPartOutput] = useState('');
  const [markPartLoading, setMarkPartLoading] = useState(false);
  const onMarkPart = async () => {
    setMarkPartLoading(true);
    setMarkPartOutput('');
    try {
      if (!workOrderNumber)
        throw {
          message: 'No work order number. Load data from a work order to use part marking.',
        };
      if (!partNumber)
        throw { message: 'No part number. Load data from a work order to use part marking.' };
      if (!serialNumber)
        throw {
          message: 'No serial number.',
        };
      if (!forwardingUrl)
        throw {
          message: 'Please enter a forwarding URL.',
        };
      const response = await markPart({
        variables: {
          forwardingUrl,
          partNumber,
          workOrderNumber: Number(workOrderNumber),
          serialNumber,
        },
        refetchQueries: ['GetSerialRecords'],
      });
      const message = response?.data?.markPart?.message ?? 'Unknown';
      setMarkPartOutput(message);
    } catch (e) {
      broadcastApolloError(e);
    } finally {
      setMarkPartLoading(false);
    }
  };

  const [stopMarkingOutput, setStopMarkingOutput] = useState('');
  const [stopMarkingLoading, setStopMarkingLoading] = useState(false);
  const onStopMarking = async () => {
    setStopMarkingLoading(true);
    setStopMarkingOutput('');
    try {
      const response = await stopMarking({
        variables: { forwardingUrl },
      });
      const message = response?.data?.stopMarking?.message ?? 'Unknown';
      setStopMarkingOutput(message);
    } catch (e) {
      broadcastApolloError(e);
    } finally {
      setStopMarkingLoading(false);
    }
  };

  const columns = [
    {
      title: 'Serial\nNumber',
      dataIndex: 'serialNumber',
      flex: '0 0 100px',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      flex: '0 0 100px',
      render: ({ date }) => moment(date).format('MM/DD/YYYY'),
    },
    {
      title: 'Work Order\nNumber',
      dataIndex: 'workOrderNumber',
      flex: '0 0 125px',
    },
    {
      title: 'Part Number',
      dataIndex: 'partNumber',
      flex: '0 0 175px',
    },
    {
      title: '',
      render: ({ serialNumber, rowIndex }) =>
        rowIndex === 0 && (
          <Tooltip title='Delete record' placement='top' enterDelay={1500}>
            <IconButton onClick={() => onRemoveSerialRecord(serialNumber)} size='small'>
              <Delete fontSize='small' />
            </IconButton>
          </Tooltip>
        ),
    },
  ];

  // Query for latest serial numbers
  const query = gql`
    query GetSerialRecords($sort: JSON, $filter: JSON, $pagination: JSON) {
      serialRecords(sort: $sort, filter: $filter, pagination: $pagination) {
        meta {
          skip
          limit
          totalRecords
        }
        data {
          serialNumber
          partNumber
          workOrderNumber
          date
        }
      }
    }
  `;
  const queryParams = {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      sort: { serialNumber: -1 },
      pagination: { limit: 20 },
    },
  };
  const { data: queryData } = useQuery(query, queryParams);

  const serialRecords = queryData?.serialRecords?.data ?? [];

  const [serialNumber, setSerialNumber] = useState(null);
  useEffect(() => {
    if (serialRecords?.length) setSerialNumber(+serialRecords[0].serialNumber + 1);
  }, [serialRecords]);

  // Mutation for removing a serial record
  const [removeSerialRecord, { loading: removeLoading, error: removeError }] = useMutation(
    gql`
      mutation RemoveSerialRecord($filter: JSON) {
        removeSerialRecord(filter: $filter) {
          serialNumber
        }
      }
    `
  );

  const onRemoveSerialRecord = async serialNumber => {
    try {
      await removeSerialRecord({
        variables: {
          filter: { serialNumber },
        },
        refetchQueries: ['GetSerialRecords'],
      });
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  return (
    <Container>
      <Col style={{ flex: '1', justifyContent: 'space-between' }}>
        <Row>
          <DisplayTitle>Work Order Number:</DisplayTitle>
          <DisplayValue>{workOrderNumber ?? ''}</DisplayValue>
        </Row>
        <Row>
          <DisplayTitle>Part Number:</DisplayTitle>
          <DisplayValue>{partNumber ?? ''}</DisplayValue>
        </Row>
        <Row>
          <DisplayTitle>Serial Number:</DisplayTitle>
          <DisplayValue>{serialNumber ?? ''}</DisplayValue>
        </Row>
        <StyledHr />
        <FormStringInput
          label='Forwarding URL'
          value={forwardingUrl}
          onChange={value => setForwardingUrl(value)}
          inputStyle={{ border: '1px solid lightgrey', borderRadius: '4px' }}
        />
        <Row>
          <StyledButton onClick={onGetState} loading={getPartMarkingStateLoading}>
            Check Status
          </StyledButton>
          <Status>{getStateOutput}</Status>
        </Row>
        <Row>
          <StyledButton onClick={onStopMarking} loading={stopMarkingLoading}>
            Stop Marking
          </StyledButton>
          <Status>{stopMarkingOutput}</Status>
        </Row>
        <Row>
          <StyledButton onClick={onMarkPart} loading={markPartLoading} type='primary'>
            Mark Part
          </StyledButton>
          <Status>{markPartOutput}</Status>
        </Row>
      </Col>
      <Col style={{ flex: '2', paddingLeft: '15px' }}>
        <DataTable query={query} columns={columns} defaultSort={{ serialNumber: -1 }} />
      </Col>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  height: 300px;
  max-width: 900px;
  top: 20px;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  border: 2px solid rgb(23, 55, 83);
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 8px 1px;
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: row;
`;
const StyledHr = styled.hr`
  width: 100%;
  border: 1px solid #ebebeb;
  background-color: #ebebeb;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
const Status = styled.span`
  margin: 0 15px;
`;
const DisplayTitle = styled.span`
  width: 150px;
`;
const DisplayValue = styled.span``;
const StyledButton = styled(Button)`
  width: 50%;
`;
