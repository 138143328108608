import React from 'react';
// GraphQL
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import dotenv from 'dotenv';

// load env vars from .env file
dotenv.config();

console.log(process.env.REACT_APP_SERVER_URL);

// Logging
// const logLink = new ApolloLink((operation, forward) => {
//   console.info(`request | ${operation.operationName}`, operation, operation.getContext());
//   return forward(operation).map(result => {
//     console.info(
//       `response | ${operation.operationName}`,
//       operation,
//       operation.getContext().response
//     );
//     return result;
//   });
// });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Code: ${extensions?.code}, Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
});

// Handles merging of paginated data
const mergeAggregation = (existing, incoming, { args, fieldName }) => {
  let { meta: existingMeta, data: existingData } = existing ?? {};
  let { meta: incomingMeta, data: incomingData } = incoming ?? {};
  if (!existingData) existingData = [];
  if (!incomingData) incomingData = [];

  // Merge data from fetchMore pagination
  if (incomingMeta?.skip > 0 && existingMeta?.skip !== incomingMeta?.skip) {
    return { meta: incomingMeta, data: [...existingData, ...incomingData] };
  }

  // Any other query results replace existing
  return { meta: incomingMeta, data: [...incomingData] };
};

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          product: {
            keyFields: ['partNumber'],
          },
          products: {
            keyFields: ['partNumber'],
            keyArgs: false,
            merge: mergeAggregation,
          },
          salesOrder: {
            keyFields: ['salesOrderNumber'],
          },
          items: {
            keyFields: ['_id'],
            keyArgs: false,
            merge: mergeAggregation,
          },
          workOrder: {
            keyFields: ['workOrderNumber'],
          },
          workOrders: {
            keyFields: ['workOrderNumber'],
            keyArgs: false,
            merge: mergeAggregation,
          },
          operations: {
            keyFields: ['_id'],
            keyArgs: false,
            merge: mergeAggregation,
          },
          serialRecords: {
            keyFields: ['serialNumber'],
            keyArgs: false,
            merge: mergeAggregation,
          },
        },
      },
    },
  }),
});

export default function _ApolloProvider({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
