import { gql } from '@apollo/client';

export const GET_SALESORDER_ITEMS = gql`
  query GetSalesOrderItems($sort: JSON, $filter: JSON, $pagination: JSON) {
    items(sort: $sort, filter: $filter, pagination: $pagination) {
      meta {
        skip
        limit
        totalRecords
      }
      data {
        _id
        qty
        sku
        partNumber
        salesPrice
        plannedShipDate
        actualShipDate
        shippingDescription
        notes
        custom
        salesOrder {
          salesOrderNumber
          dateOrdered
          grandTotal
          shippingAmount
          billingInfo {
            country
            customerFirst
            customerLast
            state
            street
            postalCode
          }
          shippingInfo {
            country
            customerFirst
            customerLast
            state
            street
            postalCode
          }
          magentoInfo {
            entityId
            remoteIp
          }
        }
      }
    }
  }
`;
