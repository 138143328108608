import React from 'react';
// Styled
import styled from 'styled-components';
// GraphQL
import { useQuery } from '@apollo/client';
// Chartjs
import { Bar } from 'react-chartjs-2';
// Helper Functions
import combineAndRemoveOldData from '../../../reusable/helper functions/combineAndRemoveOldData';
// Custom Components
import { GET_DASHBOARD_DATA_BY_DEPARTMENT } from '../../../graphQL/dashboard/queries/getDashboardDataByDepartment';

const DashboardDataByDepartment = ({ dashboardFilter }) => {
  // QUERY
  const variables = {
    fromDate: dashboardFilter.fromDate,
    toDate: dashboardFilter.toDate,
  };
  const queryParams = {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables,
  };

  const { data: queryData } = useQuery(GET_DASHBOARD_DATA_BY_DEPARTMENT, queryParams);
  let dashboardData = queryData?.getDashboardDataByDepartment?.result ?? [];

  // Combining Cut Shell, Cut Shell 2 as Cut Shell
  dashboardData = combineAndRemoveOldData(dashboardData, ['Cut Shell', 'Cut Shell 2'], 'Cut Shell');

  // Combining Machine Shell, Machine Shell 2 as Machine Shell
  dashboardData = combineAndRemoveOldData(
    dashboardData,
    ['Machine Shell', 'Machine Shell 2'],
    'Machine Shell'
  );

  const rgbColors = [
    [249, 65, 68],
    [243, 114, 44],
    [248, 150, 30],
    [249, 132, 74],
    [249, 199, 79],
    [144, 190, 109],
    [67, 170, 139],
    [77, 144, 142],
    [87, 117, 144],
    [39, 125, 161],
  ];

  const labels = dashboardData.map(({ name }) => name);
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Operations',
        data: dashboardData.map(({ name: x, count: y }) => ({ x, y })),
        backgroundColor: dashboardData.map((_, index) => `rgba(${rgbColors[index % 10]}, 0.2)`),
        borderColor: dashboardData.map((_, index) => `rgba(${rgbColors[index % 10]}, 1)`),
        borderWidth: 1,
      },
    ],
  };

  return (
    <Container>
      <Bar
        data={chartData}
        height={400}
        width={600}
        options={{
          title: {
            display: true,
            text: 'Work Completed by Operations',
            fontSize: 25,
          },
          layout: {
            padding: 16,
          },
          legend: {
            display: true,
            position: 'bottom',
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                    if (Number.isInteger(value)) {
                      return value;
                    }
                  },
                },
              },
            ],
          },
        }}
      />
    </Container>
  );
};

export default DashboardDataByDepartment;

const Container = styled.div`
  width: calc(50% - 15px);
  max-height: 400px;
  border-radius: 8px;
  box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.1);
  background-color: #dedede;
`;
