import React from 'react';
// Styled components
import styled from 'styled-components';
// Router
import { Switch, Route, Redirect } from 'react-router-dom';
// Custom Components
import LandingPageTemplate from './LandingPageTemplate';
import Login from './login/Login';
import Register from './register/Register';

function LandingPageRouter() {
  return (
    <>
      <Container>
        <Switch>
          <Route path={'/login'}>
            <LandingPageTemplate>
              <Login />
            </LandingPageTemplate>
          </Route>
          <Route path={'/register'}>
            <LandingPageTemplate>
              <Register />
            </LandingPageTemplate>
          </Route>
          {/* Redirect unknown pages to login */}
          <Route path={'/'} component={() => <Redirect to={'/login'}></Redirect>} />
        </Switch>
      </Container>
    </>
  );
}

export default LandingPageRouter;

const Container = styled.div`
  height: calc(100vh);
`;
