import React from 'react';
// Styled Components
import styled from 'styled-components';
// Helper Functions
import getUserFriendlyApolloErrorMessage from '../../../reusable/helper functions/getUserFriendlyApolloErrorMessage';

export default function TableErrorMessage({ loading, error, dataLength }) {
  if (loading) return <EmptyTableRow>Loading...</EmptyTableRow>;

  if (error) {
    const errorMessage = getUserFriendlyApolloErrorMessage(error);
    return <EmptyTableRow error={error}>{errorMessage}</EmptyTableRow>;
  }

  if (dataLength < 1) return <EmptyTableRow>No data</EmptyTableRow>;

  return null;
}

const EmptyTableRow = styled.div`
  background-color: #dddddd;
  color: ${props => (props.error ? '#d17d7d' : 'darkgrey')};
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  border-radius: 0 0 8px 8px;
`;
