import React, { useState, useRef, useEffect } from 'react';
// GraphQL
import { NetworkStatus, useQuery } from '@apollo/client';
// Styled Components
import styled from 'styled-components';
// Ant Design
import { Checkbox } from 'antd';
import 'antd/dist/antd.css';
// Moment
import moment from 'moment';
// Custom components
import TableBarLoader from './TableBarLoader';
import TableMessage from './TableMessage';
import TableDataComponent from './TableDataComponent';
import TableHeaderComponent from './TableHeaderComponent';
import BulkActions from './BulkActions';
import Actions from './Actions';
import Tabs from './Tabs';

export default function DataTable({
  query,
  rowKey,
  columns,
  bulkActionButtons,
  actionButtons,
  tabs,
  defaultSort,
  additionalSort,
  defaultPagination,
  defaultFilter,
}) {
  const tableBodyRef = useRef(null);
  const scrollTop = () => {
    tableBodyRef.current.scroll({
      top: 0,
    });
  };

  // FILTERING
  const [filters, setFilters] = useState(defaultFilter ?? {});
  const [lastSearchedColumn, setLastSearchedColumn] = useState(null);
  const onFilterColumn = ({ field, value }) => {
    const isNumberString = /^\d+$/;
    setSelectedRowKeys([]);
    setFilters(
      value ? { [field]: isNumberString.test(value) ? Number(value) : `/${value}/` } : null
    );
    setPagination(defaultPagination ?? { skip: 0, limit: 50 });
    setLastSearchedColumn(field);
    scrollTop();
  };

  // TABS
  const [tabFilter, setTabFilter] = useState(tabs ? tabs[0].filter : {});
  const onTabChange = title => {
    const newTabFilter = tabs.find(tab => tab.title === title).filter;
    setTabFilter(newTabFilter);
    setPagination(defaultPagination ?? { skip: 0, limit: 50 });
    scrollTop();
  };

  // SORTING
  const [sort, setSort] = useState(defaultSort ?? {});
  const onSortColumn = ({ field }) => {
    // Reset selected row keys
    setSelectedRowKeys([]);

    // Cycle through sort orders for a given field
    let order = sort && sort[field];
    switch (order) {
      case 1:
        setSort({ [field]: -1 });
        break;
      case -1:
        setSort(null);
        break;
      default:
        setSort({ [field]: 1 });
        break;
    }
    setPagination(defaultPagination ?? { skip: 0, limit: 50 });
    scrollTop();
  };

  // PAGINATION
  const [pagination, setPagination] = useState(defaultPagination ?? { skip: 0, limit: 50 });
  const onUserScroll = e => {
    if (!pagination) return;

    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    const maxScroll = scrollHeight - offsetHeight;

    // User has scrolled to bottom
    if (skip + limit < totalRecords && scrollTop === maxScroll) {
      setPagination({
        skip: pagination.skip + pagination.limit,
        limit: 30,
      });
      return;
    }
  };

  // ROW SELECTION
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const setRowKeysAndItems = selectedRowKeys => {
    const newSelectedItems = data.filter(item => selectedRowKeys.includes(item[rowKey]));
    setSelectedRowKeys(selectedRowKeys);
    setSelectedItems(newSelectedItems);
  };

  const onHeaderCheckboxClick = checked => {
    let newSelectedRowKeys;
    if (checked) {
      newSelectedRowKeys = data.map(row => row[rowKey]);
      setRowKeysAndItems(newSelectedRowKeys);
    } else {
      newSelectedRowKeys = [];
      setRowKeysAndItems(newSelectedRowKeys);
    }
  };

  const onRowCheckboxClick = ({ checked, selectedRowKey }) => {
    let newSelectedRowKeys;
    if (checked) {
      newSelectedRowKeys = [...selectedRowKeys, selectedRowKey];
      setRowKeysAndItems(newSelectedRowKeys);
    } else {
      newSelectedRowKeys = selectedRowKeys.filter(k => k !== selectedRowKey);
      setRowKeysAndItems(newSelectedRowKeys);
    }
  };

  // QUERY
  const variables = {
    sort: { ...sort, ...additionalSort },
    filter: { ...tabFilter, ...filters },
    pagination,
  };

  const queryParams = {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables,
  };

  const { loading, error, data: queryData, refetch, networkStatus } = useQuery(query, queryParams);
  const collectionName = query.definitions[0].selectionSet.selections[0].name.value;
  let { meta, data } = queryData?.[collectionName] ?? {};
  if (!data) data = [];
  const { skip, limit, totalRecords } = meta ?? {};

  const isRefetching = networkStatus === NetworkStatus.refetch;
  useEffect(() => {
    // Check if data is undefined or null
    if (!data?.length) {
      // If data is undefined or null, refetch it and return null to prevent rendering the component
      refetch();
    }
  }, []);

  return (
    <Container>
      <ActionsContainer>
        {bulkActionButtons && (
          <BulkActions
            bulkActionButtons={bulkActionButtons}
            selectedRowKeys={selectedRowKeys}
            selectedItems={selectedItems}
            disabled={selectedRowKeys.length === 0}
            currentTab={Object.values(tabFilter)[0]}
          />
        )}
        {actionButtons?.length > 0 && (
          <Actions actionButtons={actionButtons} setFilters={setFilters} />
        )}
      </ActionsContainer>

      {tabs && (
        <Tabs tabs={tabs} onTabChange={onTabChange} setSelectedRowKeys={setSelectedRowKeys} />
      )}

      <THead tabs={tabs}>
        <TableMeta tabs={tabs}>
          {loading
            ? 'Loading...'
            : data.length === 0
            ? 'No data'
            : `Showing 1-${Math.min(skip + limit, totalRecords)} of ${totalRecords}`}
        </TableMeta>
        <THR>
          {bulkActionButtons && (
            <TH>
              <Checkbox
                checked={selectedRowKeys.length === data.length}
                indeterminate={
                  selectedRowKeys.length !== 0 && selectedRowKeys.length < data.length
                }
                onChange={e => onHeaderCheckboxClick(e.target.checked)}
              />
            </TH>
          )}
          {columns.map(column => (
            <TableHeaderComponent
              key={`TableHeaderComponent_${column.title}`}
              column={column}
              sort={sort}
              onSortColumn={onSortColumn}
              onFilterColumn={onFilterColumn}
              lastSearchedColumn={lastSearchedColumn}
            />
          ))}
        </THR>
        {loading && <TableBarLoader />}
      </THead>
      <TBody onScroll={onUserScroll} ref={tableBodyRef}>
        {!isRefetching && data.length > 0 ? (
          data.map((rowData, index) => (
            <TR key={`TR_${rowData[rowKey]}`}>
              {bulkActionButtons && (
                <TD>
                  <Checkbox
                    checked={selectedRowKeys.includes(rowData[rowKey])}
                    onChange={e =>
                      onRowCheckboxClick({
                        checked: e.target.checked,
                        selectedRowKey: rowData[rowKey],
                      })
                    }
                  />
                </TD>
              )}
              {columns.map(column => (
                <TableDataComponent
                  key={`TableDataComponent_${rowData[rowKey]}_${column.title}`}
                  column={column}
                  rowData={rowData}
                  rowIndex={index}
                />
              ))}
            </TR>
          ))
        ) : (
          <TableMessage loading={loading} error={error} dataLength={data.length} />
        )}
      </TBody>
    </Container>
  );
}

const TableMeta = styled.div`
  position: absolute;
  top: -25px;
  left: ${({ tabs }) => (tabs ? `${45 + tabs.length * 90}px` : '25px')};
  width: fit-content;
  text-align: right;
  font-style: italic;
  color: grey;
`;
const TR = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;

  &:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  &:hover {
    background-color: #e6e6e6;
  }

  padding: 0;
`;

const THR = styled(TR)`
  &:first-child {
    border-radius: 8px 8px 0 0;
  }
`;

const Container = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 25px;
`;

const THead = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ tabs }) => (tabs ? '0' : '25px')};
`;

const TBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow-y: auto;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  background-color: #dddddd;
`;

const TH = styled.div`
  background-color: #173753;
  color: #ffffff;
  text-align: center;
  padding: 12px 15px;
  font-size: 15px;
  font-weight: 300;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-radius: 8px 0 0 0;
  }

  &:last-child {
    border-radius: 0 8px 0 0;
  }

  cursor: ${props => (props.sortable ? 'pointer' : 'auto')};
`;

const TD = styled.div`
  padding: 8px 15px;
  text-align: center;
  border-left: thin dotted lightgrey;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-left: none;
  }
`;
