import React from 'react';
// Router
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({ path, children }) {
  let authenticated = false;

  const token = localStorage.getItem('token');
  const user = localStorage.getObject('user');

  if (token) authenticated = true;

  if (authenticated) {
    return <Route path={path}>{children}</Route>;
  } else {
    return <Redirect to='/login' />;
  }
}
