// GraphQL
import { gql, useMutation } from '@apollo/client';

const STOP_MARKING = gql`
  mutation StopMarking($forwardingUrl: String!) {
    stopMarking(forwardingUrl: $forwardingUrl) {
      message
    }
  }
`;

export const useStopMarking = () => {
  const [mutate, { data, loading, error }] = useMutation(STOP_MARKING);
  return { mutate, data, loading, error };
};
