import React, { useState } from 'react';
// GraphQL
import { GET_PRODUCTS } from '../../../graphQL/products/queries/getProducts';
// Styled components
import styled from 'styled-components';
// Routing
import history from '../../../routing/history';
import { useRouteMatch } from 'react-router-dom';
// Custom components
import DataTable from '../../../reusable/tables/DataTable/DataTable';
import BulkDeleteButton from './bulk action buttons/BulkDeleteButton';
//Material UI
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddRounded';
import Tooltip from '@material-ui/core/Tooltip';

export default function ProductsTable() {
  const { path } = useRouteMatch();

  const bulkActionButtons = [
    ({ selectedRowKeys, disabled }) => (
      <BulkDeleteButton selectedRowKeys={selectedRowKeys} disabled={disabled} />
    ),
  ];

  const actionButtons = [
    () => (
      <Tooltip title='Create new product' placement='top' enterDelay={1500}>
        <IconButton
          aria-label='add'
          style={{ color: '#2e2e2e' }}
          onClick={() => history.push(`${path}/create`)}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    ),
  ];

  const columns = [
    {
      title: 'Part\nNumber',
      dataIndex: 'partNumber',
      flex: '0 175px',
      render: ({ partNumber }) => (
        <ProductLink onClick={() => history.push(`${path}/edit/${partNumber}`)}>
          {partNumber}
        </ProductLink>
      ),
      filterable: true,
      sortable: true,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      flex: '0 1 150px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'Qty\non Hand\n(+)',
      dataIndex: 'qtyOnHand',
      dataType: 'number',
      flex: '0 1 100px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'Qty\nPromised\n(-)',
      dataIndex: 'qtyPromised',
      dataType: 'number',
      flex: '0 1 100px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'Qty\nAvailable\n(=)',
      dataIndex: 'qtyAvailable',
      dataType: 'number',
      flex: '0 1 100px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'Min\nAvailable',
      dataIndex: 'minOnHand',
      dataType: 'number',
      flex: '0 1 100px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'Qty\non Order',
      dataIndex: 'qtyOnOrder',
      dataType: 'number',
      flex: '0 1 100px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'Make\nor Buy',
      dataIndex: 'custom.makeOrBuy',
      flex: '0 1 100px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'Supplier',
      dataIndex: 'custom.supplier',
      flex: '0 1 100px',
      filterable: true,
      sortable: true,
    },
    {
      title: 'Production\nNotes',
      dataIndex: 'productionNotes',
    },
  ];

  return (
    <DataTable
      query={GET_PRODUCTS}
      rowKey={'partNumber'}
      columns={columns}
      actionButtons={actionButtons}
      bulkActionButtons={bulkActionButtons}
      defaultSort={{ partNumber: 1 }}
    />
  );
}

const ProductLink = styled.a``;
