import React from 'react';
// Router
import history from '../../../routing/history';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
// Custom Components
import ProductsTable from './ProductsTable';
import EditProduct from './Edit&Create/EditProduct';
import CreateProduct from './Edit&Create/CreateProduct';

export default function ProductsRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ProductsTable />
      </Route>
      <Route path={`${path}/edit/:partNumber`}>
        <EditProduct goBack={() => history.push(path)} />
      </Route>
      <Route path={`${path}/create`}>
        <CreateProduct goBack={() => history.push(path)} productsPath={path} />
      </Route>
      <Route path={path}>Oops! - 404</Route>
    </Switch>
  );
}
