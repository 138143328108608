import { useState } from 'react';
import styled from 'styled-components';
// Ant Design
import 'antd/dist/antd.css';
import { Button, Form, Popconfirm, Table, Typography } from 'antd';
// GraphQL
import { gql, useMutation, useQuery } from '@apollo/client';
import { useRemoveUser } from '../../../../graphQL/users/mutations/removeUser';
import { useUpdateUser } from '../../../../graphQL/users/mutations/updateUser';
import { GET_USERS } from '../../../../graphQL/users/queries/getUsers';
import { useCreateUser } from '../../../../graphQL/users/mutations/createUser';
// Custom
import broadCastApolloError from '../../../../reusable/helper functions/broadCastApolloError';
import EditableCell from './EditableCell';

const UsersTable = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = record => record._id === editingKey;

  const edit = record => {
    form.setFieldsValue({
      familyName: '',
      givenName: '',
      email: '',
      role: '',
      password: '',
      ...record,
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  // Query For Get Users
  const variables = {
    sort: {
      role: 1,
      familyName: 1,
      givenName: 1,
    },
    pagination: {},
  };

  const queryParams = {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables,
  };

  const { loading, error, data: queryData } = useQuery(GET_USERS, queryParams);
  const dataSource = queryData?.users?.data || [];

  // Save the changes to the user
  const { mutate: updateUser, loading: saveLoading } = useUpdateUser();
  const handleUpdateUser = async key => {
    try {
      const row = await form.validateFields();
      let user = {
        ...row,
        _id: editingKey,
      };
      try {
        await updateUser({
          variables: {
            user: user,
          },
          refetchQueries: ['GetUsers'],
        });
        setEditingKey('');
      } catch (e) {
        broadCastApolloError(e);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  // Mutation for saving the changes to the user
  const { mutate: createUser } = useCreateUser();
  const handleAddUser = async () => {
    const newData = {
      familyName: `Family Name`,
      givenName: `Given Name`,
      email: `example@mail.com`,
      role: `operator`,
      password: `12345678`,
    };
    try {
      await createUser({
        variables: {
          user: newData,
        },
        refetchQueries: ['GetUsers'],
      });
    } catch (e) {
      broadCastApolloError(e);
    }
  };

  // Mutation for removing user from company table
  const { mutate: removeUser } = useRemoveUser();
  const handleDelete = async key => {
    try {
      await removeUser({
        variables: { filter: { _id: key } },
        refetchQueries: ['GetUsers'],
      });
    } catch (e) {
      broadCastApolloError(e);
    }
  };

  const columns = [
    {
      title: 'Last Name',
      dataIndex: 'familyName',
      editable: true,
      width: 200,
    },
    {
      title: 'First Name',
      dataIndex: 'givenName',
      editable: true,
      width: 200,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      editable: true,
      width: 200,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      editable: true,
      width: 100,
    },
    {
      title: 'Password',
      dataIndex: 'password',
      editable: true,
      width: 200,
      render: (text, record) => {
        if (editingKey !== record._id) return '********';
      },
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => handleUpdateUser(record._id)}
              style={{
                marginRight: 8,
              }}>
              Save
            </Typography.Link>
            <Popconfirm
              title='Sure to cancel?'
              onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ''}
              style={{ marginRight: '10px' }}
              onClick={() => edit(record)}>
              Edit
            </Typography.Link>
            <Typography.Link disabled={editingKey !== ''}>
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleDelete(record._id)}>
                Delete
              </Popconfirm>
            </Typography.Link>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <PageHeading>
        <h2>Manage Users</h2>
        <Button
          onClick={handleAddUser}
          type='primary'
          style={{ marginBottom: 16 }}>
          Add user
        </Button>
      </PageHeading>
      <Form
        form={form}
        component={false}>
        <TableContainer>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={dataSource}
            columns={mergedColumns}
            rowClassName='editable-row'
            pagination={false}
          />
        </TableContainer>
      </Form>
    </div>
  );
};

export default UsersTable;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: 'wrap';
`;
const TableContainer = styled.div`
  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
`;
