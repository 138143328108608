import React, { useState } from 'react';
// Styled components
import styled from 'styled-components';
// Ant Design
import { Select, Checkbox } from 'antd';
// Custom components
import CardTemplate from '../../../../../reusable/layout/CardTemplate';
import FormStringInput from '../../../../../reusable/forms/FormStringInput';

export default function CustomerCard({ editedSalesOrder, onChange }) {
  const [selectedInfo, setSelectedInfo] = useState('billing');

  return (
    <CardTemplate title='Customer'>
      <FormContainer>
        <div>
          <Select
            value={selectedInfo}
            style={{ width: 120 }}
            onChange={value => setSelectedInfo(value)}>
            <Select.Option value='billing'>Billing</Select.Option>
            <Select.Option value='shipping'>Shipping</Select.Option>
          </Select>
        </div>
        <div />
        <FormStringInput
          label='First Name'
          value={editedSalesOrder?.[`${selectedInfo}Info`]?.customerFirst ?? ''}
          onChange={value => onChange({ path: `${selectedInfo}Info.customerFirst`, value })}
        />
        <FormStringInput
          label='Last Name'
          value={editedSalesOrder?.[`${selectedInfo}Info`]?.customerLast ?? ''}
          onChange={value => onChange({ path: `${selectedInfo}Info.customerLast`, value })}
        />
        <FormStringInput
          label='Address'
          value={editedSalesOrder?.[`${selectedInfo}Info`]?.street ?? ''}
          onChange={value => onChange({ path: `${selectedInfo}Info.street`, value })}
        />
        <FormStringInput
          label='State'
          value={editedSalesOrder?.[`${selectedInfo}Info`]?.state ?? ''}
          onChange={value => onChange({ path: `${selectedInfo}Info.state`, value })}
        />
        <FormStringInput
          label='Country'
          value={editedSalesOrder?.[`${selectedInfo}Info`]?.country ?? ''}
          onChange={value => onChange({ path: `${selectedInfo}Info.country`, value })}
        />
        <FormStringInput
          label='Postal Code'
          value={editedSalesOrder?.[`${selectedInfo}Info`]?.postalCode ?? ''}
          onChange={value => onChange({ path: `${selectedInfo}Info.postalCode`, value })}
        />
      </FormContainer>
    </CardTemplate>
  );
}

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
`;
