// Apollo
import _ApolloProvider from './components/_ApolloProvider';
// Router
import history from './components/routing/history';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
// Custom Components
import AppRouter from './components/pages/app/AppRouter';
import LandingPageRouter from './components/pages/landing/LandingPageRouter';
import PrivateRoute from './components/routing/PrivateRoute';
import EnvBanner from './components/pages/EnvBanner';

function App() {
  return (
    <>
      <EnvBanner />
      <_ApolloProvider>
        <Router history={history}>
          <Switch>
            <PrivateRoute path='/app'>
              <AppRouter />
            </PrivateRoute>
            <Route path='/'>
              <LandingPageRouter />
            </Route>
          </Switch>
        </Router>
      </_ApolloProvider>
    </>
  );
}

export default App;
