// GraphQL
import { gql, useQuery, NetworkStatus } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query GetProducts($sort: JSON, $filter: JSON, $pagination: JSON) {
    products(sort: $sort, filter: $filter, pagination: $pagination) {
      meta {
        skip
        limit
        totalRecords
      }
      data {
        partNumber
        category
        qtyOnHand
        minOnHand
        productionNotes
        custom
      }
    }
  }
`;
