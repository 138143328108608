import React, { useState, useEffect } from 'react';
//Routing
import { withRouter, Prompt } from 'react-router-dom';
// Styled components
import styled from 'styled-components';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
// Ant Design
import { Button } from 'antd';
// GraphQL
import { gql, useMutation, useQuery } from '@apollo/client';
// Custom Components
import DetailsCard from './cards/DetailsCard';
import GalleryCard from './cards/GalleryCard';
import AccountingCard from './cards/AccountingCard';
import EditOperationsCard from './cards/EditOperationsCard/EditOperationsCard';
// Bar Loader
// import BarLoader from 'react-bar-loader';
// Helper Functions
import setNestedKeyOnClone from '../../../../reusable/helper functions/setNestedKeyOnClone';
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

function CreateWorkOrder({ goBack, workOrdersPath }) {
  const [workOrder, setWorkOrder] = useState({});

  const BackButton = () => (
    <IconButton onClick={onBack}>
      <BackIcon size='small' />
    </IconButton>
  );

  const onBack = () => {
    // TODO: Check for changes before we go back. If there
    // are changes, prompt the user to save the changes.
    goBack();
  };

  // Query for the latest priority
  const latestPriorityQuery = gql`
    query LatestPriority($sort: JSON) {
      workOrder(sort: $sort) {
        data {
          priority
        }
      }
    }
  `;
  const { data: latestPriorityQueryData, refetch } = useQuery(latestPriorityQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      sort: { priority: -1 },
    },
  });

  // When we get the latest work order, set the latest priority
  useEffect(() => {
    if (latestPriorityQueryData?.workOrder) {
      const latestPriority = latestPriorityQueryData?.workOrder?.data?.[0]?.priority + 1 ?? 1;
      setWorkOrder({ ...workOrder, priority: latestPriority, productConfig: "default" });
    } else {
      setWorkOrder({ ...workOrder, priority: 1, productConfig: "default" });
    }
  }, [latestPriorityQueryData]);

  // Query for the latest work order number
  const latestWorkOrderNumberQuery = gql`
    query LatestWorkOrderNumber($filter: JSON, $sort: JSON) {
      workOrder(filter: $filter, sort: $sort) {
        data {
          workOrderNumber
        }
      }
    }
  `;

  const { data: latestWorkOrderNumberQueryData } = useQuery(latestWorkOrderNumberQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
        filter: {
          workOrderNumber: { $type: 'number' },
        },
      sort: { workOrderNumber: -1 },
    },
  });

  // When we get the latest work order, set the latest work order number
  useEffect(() => {
    if (latestWorkOrderNumberQueryData?.workOrder?.data?.[0]) {
      const latestWorkOrderNumber =
        latestWorkOrderNumberQueryData?.workOrder?.data?.[0]?.workOrderNumber + 1 ?? 1;
      setWorkOrder({ ...workOrder, workOrderNumber: latestWorkOrderNumber });
    } else {
      setWorkOrder({ ...workOrder, workOrderNumber: 1 });
    }
  }, [latestWorkOrderNumberQueryData]);

  // Prompt the user about unsaved changes if they navigate away from the page
  const [promptSaveOnExit, setPromptSaveOnExit] = useState(false);
  useEffect(() => {
    if (Object.keys(workOrder).length > 0) {
      window.onbeforeunload = () => true;
      setPromptSaveOnExit(true);
    }
  }, [workOrder]);

  // Editing the work order
  const onChange = ({ path, value }) => {
    const newEditedWorkOrder = setNestedKeyOnClone({ object: workOrder, path, value });
    setWorkOrder(newEditedWorkOrder);
  };

  // Mutation for saving the changes to the work order
  const [createWorkOrder, { loading: createLoading, error: createError }] = useMutation(
    gql`
      mutation CreateWorkOrder($workOrder: JSON) {
        createWorkOrder(workOrder: $workOrder) {
          workOrderNumber
        }
      }
    `
  );

  // Create the work order
  const onCreate = async (buttonType) => {
    try {
      await createWorkOrder({
        variables: {
          workOrder: { ...workOrder, status: 'open', dateCreated: new Date() },
        },
      });
      setPromptSaveOnExit(false);
      if (buttonType === 'Create& Exit') setWorkOrderCreated(true);
      else if (buttonType === 'Create& New') {
        setWorkOrder({});
        refetch();
      }
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  // Go back on successful creation
  const [workOrderCreated, setWorkOrderCreated] = useState(false);
  useEffect(() => {
    if (workOrderCreated && !promptSaveOnExit) goBack();
  }, [workOrderCreated, promptSaveOnExit]);

  return (
    <>
      {/* 
          If there are unsaved changes, prompt the user before navigating 
          using any kind of routing within the app 
      */}
      <Prompt
        when={promptSaveOnExit}
        message='You have unsaved changes. Are you sure you want to leave the page?'
      />
      <Header>
        <HeaderContent>
          <BackButton />
          <Title>Create Work Order</Title>
          <CancelButton
            size='large'
            onClick={goBack}>
            Cancel
          </CancelButton>
          <SaveButton
            size='large'
            type='primary'
            onClick={() => onCreate("Create& New")}>
            Create & New
          </SaveButton>
          <SaveButton
            size='large'
            type='primary'
            onClick={() => onCreate("Create& Exit")}>
            Create & Exit <ArrowBackRounded fontSize='small' />
          </SaveButton>
        </HeaderContent>
        {/* {createLoading ? <StyledBarLoader height={3} color='#8aa1b5' /> : null} */}
      </Header>
      <Body>
        <Col>
          <DetailsCard
            editedWorkOrder={workOrder}
            onChange={onChange}
            creating
          />
          <AccountingCard
            editedWorkOrder={workOrder}
            onChange={onChange}
          />
        </Col>
        <Col>
          <GalleryCard
            editedWorkOrder={workOrder}
            onChange={onChange}
          />
          <EditOperationsCard
            workOrder={workOrder}
            onChange={onChange}
          />
        </Col>
      </Body>
    </>
  );
}
export default withRouter(CreateWorkOrder);

const Header = styled.div`
  border-bottom: 2px solid #7e95a8;
  padding-bottom: 15px;
`;
const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  overflow-y: auto;
  height: 100%;
  border-radius: 8px;
  padding-top: 15px;
`;
const Title = styled.div`
  font-size: 24px;
  margin-left: 35px;
`;
const CancelButton = styled(Button)`
  margin-left: auto;
`;
const SaveButton = styled(Button)`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
// const StyledBarLoader = styled(BarLoader)`
//   position: relative;
//   margin-top: -3px;
//   bottom: -14px;
// `;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
