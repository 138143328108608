import React from 'react';
// Styled components
import styled from 'styled-components';
// Routing
import history from '../../../../routing/history';
import { useRouteMatch } from 'react-router-dom';
// Custom components
import DataTable from '../../../../reusable/tables/DataTable/DataTable';
import BulkDeleteButton from '../bulk action buttons/BulkDeleteButton';
import BulkMoveButton from '../bulk action buttons/BulkMoveButton';
import Operations from './Operations/Operations';
import RecalculateButton from '../action buttons/RecalculateButton';
import RenumberOpenPriorityButton from '../action buttons/RenumberOpenPriorityButton';
import PriorityInput from './PriorityInput';
import RushIcon from '../../../../reusable/icons/RushIcon';
import combineWorkOrderNumberWithType from '../../../../reusable/helper functions/combineWorkOrderNumberWithType';
//Material UI
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddRounded';
import Tooltip from '@material-ui/core/Tooltip';
// GraphQL
import { GET_WORKORDERS } from '../../../../graphQL/workOrders/queries/getWorkOrders';

export default function WorkOrdersTable() {
  const { path } = useRouteMatch();

  const bulkActionButtons = [
    ({ selectedRowKeys, disabled }) => (
      <BulkDeleteButton disabled={disabled} selectedRowKeys={selectedRowKeys} />
    ),
    ({ selectedRowKeys, disabled, currentTab }) => (
      <BulkMoveButton
        disabled={disabled}
        selectedRowKeys={selectedRowKeys}
        currentTab={currentTab}
      />
    ),
  ];

  const actionButtons = [
    () => (
      <Tooltip title='Create new work order' placement='top' enterDelay={1500}>
        <IconButton
          aria-label='add'
          style={{ color: '#2e2e2e' }}
          onClick={() => history.push(`${path}/create`)}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    ),
    ,
    () => (
      <RenumberOpenPriorityButton/>
    ),
  ];

  const columns = [
    {
      title: 'Priority',
      dataIndex: 'priority',
      dataType: 'number',
      flex: '0 1 70px',
      style: { padding: '3px 12px' },
      render: ({ priority }) => <PriorityInput priority={priority} />,
      sortable: true,
    },
    {
      title: 'Work OrderType',
      dataIndex: 'type',
      filterable: true,
      flex: '0 1 90px',
    },
    {
      title: 'Work Order\nNumber',
      dataIndex: 'workOrderNumber',
      filterable: true,
      flex: '0 1 90px',
      render: ({ workOrderNumber, type, productConfig }) => (
        <WorkOrderLinkContainer>
          <WorkOrderLink onClick={() => history.push(`${path}/edit/${workOrderNumber}`)}>
            {workOrderNumber}
          </WorkOrderLink>
          {productConfig === 'rush' && (
            <RushIcon
              style={{
                marginLeft: 'auto',
              }}
            />
          )}
        </WorkOrderLinkContainer>
      ),
    },
    {
      title: 'Part Number',
      dataIndex: 'partNumber',
      filterable: true,
      flex: '0 1 130px',
      style: { justifyContent: 'left' },
    },
    {
      title: 'Total\nWeb Value',
      dataIndex: 'custom.workOrderValue',
      dataType: 'number',
      render: ({ custom, qtyToBuild }) =>
        custom?.assyPrice
          ? `$${(custom?.assyPrice * qtyToBuild ?? 0)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
          : null,
      sortable: true,
      filterable: true,
      flex: '0 1 115px',
    },
    {
      title: 'Qty to\nBuild',
      dataIndex: 'qtyToBuild',
      dataType: 'number',
      sortable: true,
      filterable: true,
      flex: '0 1 115px',
    },
    {
      title: 'Operations',
      dataIndex: 'operations',
      flex: '0 1 600px',
      style: { justifyContent: 'left' },
      render: ({ rowData }) => <Operations workOrder={rowData} />,
    },
    {
      title: 'Date Required',
      dataIndex: 'dateRequired',
      dataType: 'date',
      sortable: true,
      filterable: true,
      flex: '0 1 150px',
    },
    {
      title: 'Production Notes',
      dataIndex: 'productionNotes',
      style: { justifyContent: 'left' },
    },
  ];

  return (
    <DataTable
      query={GET_WORKORDERS}
      rowKey={'workOrderNumber'}
      columns={columns}
      actionButtons={actionButtons}
      bulkActionButtons={bulkActionButtons}
      tabs={[
        { title: 'Open', filter: { status: 'open' } },
        { title: 'Closed', filter: { status: 'closed' } },
        { title: 'Archived', filter: { status: 'archived' } },
      ]}
      defaultSort={{ priority: 1 }}
      defaultPagination={{ skip: 0, limit: 200 }}
    />
  );
}

const WorkOrderLinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const WorkOrderLink = styled.a``;
