/**
 * Clones an object and sets an attribute in on that object
 * based on the given path to that attribute.
 * @param {Object} object
 * @param {String} path
 */
export default function getNestedKey({ object, path }) {
  let value;

  // Store a pointer to the current location in the object
  let current = object;

  // Convert the path to an array
  path = path.split('.');

  // Navigate to the place in the object
  for (const [index, key] of path.entries()) {
    // If we are at the end of the path, assign the value
    if (index >= path.length - 1) {
      value = current[key];
      break;
    }

    // If this part of the path doesn't exist, return null
    if (current[key] === null || current[key] === undefined) {
      value = null;
      break;
    }

    current = current[key];
  }

  return value;
}
