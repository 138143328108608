import React, { useState, useEffect } from 'react';
// Styled components
import styled from 'styled-components';
// Ant Design
import { Steps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// Routing
import history from '../../../routing/history';
// Custom Components
import Beta from './Beta';
import User from './User';
import Company from './Company';
// GraphQL
import broadCastApolloError from '../../../reusable/helper functions/broadCastApolloError';
import { useVerifyBetaCode } from '../../../graphQL/authentication/verifyBetaCode';
import { useRegisterCompany } from '../../../graphQL/authentication/registerCompany';

const { Step } = Steps;

export default function RegisterForm() {
  const [state, setState] = useState({ betaCode: '', user: {}, company: {} });
  const [steps, setSteps] = useState({ beta: 'process', user: 'wait', company: 'wait' });
  const [stepContent, setStepContent] = useState();

  const { mutate: verifyBetaCode } = useVerifyBetaCode();
  const { mutate: registerCompany } = useRegisterCompany();

  useEffect(() => {
    const currentStep = Object.entries(steps).find(step => step[1] === 'process')?.[0];

    switch (currentStep) {
      case 'beta':
        setButtons({
          back: false,
          next: {
            onClick: async () => {
              setSteps({ ...steps, beta: 'loading' });
              try {
                const betaIsVerified = (
                  await verifyBetaCode({
                    variables: { betaCode: state.betaCode ?? '' },
                  })
                )?.data?.verifyBetaCode;

                if (betaIsVerified) {
                  setSteps({ ...steps, beta: 'finish', user: 'process' });
                } else {
                  setSteps({ ...steps, beta: 'process' });
                  broadCastApolloError({ message: 'Beta code is invalid' });
                }
              } catch (e) {
                broadCastApolloError(e);
                setSteps({ ...steps, beta: 'process' });
              }
            },
          },
          submit: false,
        });
        setStepContent(<Beta state={state} setState={setState} />);
        break;
      case 'user':
        setButtons({
          back: {
            onClick: () => {
              setSteps({ ...steps, beta: 'process', user: 'wait' });
            },
          },
          next: {
            onClick: () => {
              setSteps({ ...steps, user: 'finish', company: 'process' });
            },
          },
          submit: false,
        });
        setStepContent(<User state={state} setState={setState} />);
        break;
      case 'company':
        setButtons({
          back: {
            onClick: () => {
              setSteps({ ...steps, user: 'process', company: 'wait' });
            },
          },
          next: false,
          submit: {
            onClick: async () => {
              setSteps({ ...steps, company: 'loading' });
              try {
                const { token, user } =
                  (
                    await registerCompany({
                      variables: {
                        company: {
                          ...state.company,
                          users: [{ ...state.user, role: 'admin' }],
                        },
                      },
                    })
                  )?.data?.registerCompany ?? {};

                if (token) localStorage.setItem('token', token);
                if (user) localStorage.setObject('user', user);
                setSteps({ ...steps, company: 'process' });

                history.push('/app/dashboard');
              } catch (e) {
                console.log(e);
                broadCastApolloError(e);
                setSteps({ ...steps, company: 'process' });
              }
            },
          },
        });
        setStepContent(<Company state={state} setState={setState} />);
        break;
      default:
        break;
    }
  }, [steps, state]);

  const [buttons, setButtons] = useState({ back: false, next: true });

  const onLoginClicked = () => {
    history.push('/login');
  };

  return (
    <RegisterContainer>
      <Steps>
        <Step
          status={steps.beta}
          title='Beta Code'
          icon={steps.beta === 'loading' ? <LoadingOutlined /> : null}
        />
        <Step
          status={steps.user}
          title='User'
          icon={steps.user === 'loading' ? <LoadingOutlined /> : null}
        />
        <Step
          status={steps.company}
          title='Company'
          icon={steps.company === 'loading' ? <LoadingOutlined /> : null}
        />
      </Steps>
      <ContentContainer>{stepContent}</ContentContainer>
      <Footer>
        <LoginButton onClick={onLoginClicked}>Already registered? Log in</LoginButton>
        <ButtonContainer>
          {buttons.back && (
            <BackButton onClick={buttons?.back.onClick ?? null}>Back</BackButton>
          )}
          {buttons.next && (
            <NextButton onClick={buttons?.next.onClick ?? null}>Next</NextButton>
          )}
          {buttons.submit && (
            <SubmitButton onClick={buttons?.submit.onClick ?? null}>Submit</SubmitButton>
          )}
        </ButtonContainer>
      </Footer>
    </RegisterContainer>
  );
}

const RegisterContainer = styled.div`
  width: 800px;
  height: 500px;
  background-color: #173753;
  margin: 0 auto 35px auto;
  border-radius: 15px;
  box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.2);
  padding: 20px 20px 0 20px;
  color: white;

  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: 20px 0;
  height: 100%;
`;

const LoginButton = styled.button`
  margin-top: auto;
  font-size: 11px;
  color: white;
  border: none;
  background: none;
  text-align: left;
  display: inline-block;
  width: fit-content;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: auto;
`;

const NextButton = styled.button`
  cursor: pointer;
  border: none;
  width: 100px;
  height: 33px;
  color: white;
  background-color: #ff6666;
  border-radius: 6px;

  &:hover {
    transition-duration: 0.3s;
    background-color: #ff4d4d;
  }
`;

const BackButton = styled.button`
  cursor: pointer;
  border: none;
  width: 100px;
  height: 33px;
  color: white;
  background-color: #999999;
  border-radius: 6px;

  &:hover {
    transition-duration: 0.3s;
    background-color: #8a8a8a;
  }
`;

const SubmitButton = styled.button`
  cursor: pointer;
  border: none;
  width: 100px;
  height: 33px;
  color: white;
  background-color: #1ac77f;
  border-radius: 6px;

  &:hover {
    transition-duration: 0.3s;
    background-color: #00ba6d;
  }
`;
