import React from 'react';
// Styled components
import styled from 'styled-components';
// Material UI
import Tooltip from '@material-ui/core/Tooltip';
import PriorityIcon from '@material-ui/icons/PriorityHighRounded';

export default function RushIcon({ style }) {
  return (
    <RushIconContainer style={style}>
      <Tooltip title='Rush' placement='top'>
        <PriorityIcon style={{ color: '#deae00' }} fontSize='small' />
      </Tooltip>
    </RushIconContainer>
  );
}

const RushIconContainer = styled.div`
  border: 2px solid #deae00;
  height: 24px;
  width: 24px;
  border-radius: 12px;
`;
