import React from 'react';
// Styled components
import styled from 'styled-components';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import Back from '@material-ui/icons/ArrowBackRounded';

export default function TaskWorkstation({ goBack, title, children }) {
  return (
    <>
      <TitleBar>
        <IconButton onClick={goBack} style={{ marginRight: '15px' }}>
          <Back size='small' />
        </IconButton>
        <Title>{title}</Title>
      </TitleBar>
      <PageContent>{children}</PageContent>
    </>
  );
}

const TitleBar = styled.div`
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Title = styled.div`
  font-size: 36px;
`;
const PageContent = styled.div`
  position: relative;
  margin: 0 30px;
  height: calc(100vh - 30px - 56px - 30px);
  border-radius: 10px;
  background-color: #f3f3f3;
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
