import React, { useState } from 'react';
// Styled Components
import styled from 'styled-components';

export default function Tabs({ tabs, onTabChange, setSelectedRowKeys }) {
  const [selectedTab, setSelectedTab] = useState(0);

  const onTabClick = index => {
    setSelectedTab(index);
    onTabChange(tabs[index].title);
    setSelectedRowKeys([]);
  };

  return (
    <TabsContainer>
      {tabs.map((tab, index) => (
        <Tab
          key={`Tab_${tab.title}`}
          selected={index === selectedTab}
          onClick={() => onTabClick(index)}>
          {tab.title}
        </Tab>
      ))}
    </TabsContainer>
  );
}

const TabsContainer = styled.div`
  width: fit-content;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 10px;
  margin-left: 25px;
`;

const Tab = styled.div`
  border-radius: 8px 8px 0 0;
  padding: 2px 8px;
  font-size: 14px;
  background-color: ${props => (props.selected ? '#173753' : '#335c7d')};
  color: white;
  width: 80px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;

  &:hover {
    border-top: 2px solid darkgrey;
    border-right: 2px solid darkgrey;
    border-left: 2px solid darkgrey;
  }
`;
