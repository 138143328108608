import React, { useState } from 'react';
// Styled components
import styled from 'styled-components';
// Ant Design
import { Select } from 'antd';

export default function FormDropdown({ label, options = [], value, placeholder, onChange }) {
  const handleChange = value => {
    onChange(value);
  };

  return (
    <Container>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        placeholder={placeholder}
        style={{ width: '100%' }}
        onChange={onChange ? handleChange : null}
        disabled={!onChange ? true : false}>
        {options.map(option => (
          <Select.Option
            key={`PNDropdown_${option}`}
            style={{ fontSize: '16px', padding: '4px 8px' }}
            value={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const InputLabel = styled.div`
  font-size: 12px;
  color: black;
`;
