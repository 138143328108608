import React, { useState, useEffect } from 'react';
//Routing
import { Prompt } from 'react-router-dom';
// Styled components
import styled from 'styled-components';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
// Ant Design
import { Button } from 'antd';
// GraphQL
import { gql, useMutation } from '@apollo/client';
// Custom Components
import GeneralCard from './cards/GeneralCard';
import ProductionCard from './cards/ProductionCard';
import AssemblyDetailsCard from './cards/AssemblyDetailsCard';
import PackagingCard from './cards/PackagingCard';
import MappedSkusCard from './cards/MappedSkusCard';
// Bar Loader
// import BarLoader from 'react-bar-loader';
// Helper Functions
import setNestedKeyOnClone from '../../../../reusable/helper functions/setNestedKeyOnClone';
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

export default function CreateProduct({ goBack, productsPath }) {
  const onBack = () => {
    // TODO: Check for changes before we go back. If there
    // are changes, prompt the user to save the changes.
    goBack();
  };

  const BackButton = () => (
    <IconButton onClick={onBack}>
      <BackIcon size='small' />
    </IconButton>
  );

  // When the current product is fetched, set it in state
  const [product, setProduct] = useState({});

  // Prompt the user about unsaved changes if they navigate away from the page
  const [promptSaveOnExit, setPromptSaveOnExit] = useState(false);
  useEffect(() => {
    if (Object.keys(product).length > 0) {
      window.onbeforeunload = () => true;
      setPromptSaveOnExit(true);
    }
  }, [product]);

  // Editing the product
  const onChange = ({ path, value }) => {
    const newEditedProduct = setNestedKeyOnClone({ object: product, path, value });
    setProduct(newEditedProduct);
  };

  // Reset the product to original state
  const onCancel = () => {
    goBack();
  };

  // Mutation for saving the changes to the product
  const [createProduct, { loading: createLoading }] = useMutation(
    gql`
      mutation CreateProduct($product: JSON!) {
        createProduct(product: $product) {
          partNumber
        }
      }
    `
  );

  // Create the product
  const onCreate = async () => {
    try {
      await createProduct({
        variables: {
          product,
        },
      });
      setPromptSaveOnExit(false);
      setProductCreated(true);
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  // Go back on successful creation
  const [productCreated, setProductCreated] = useState(false);
  useEffect(() => {
    if (productCreated && !promptSaveOnExit) goBack();
  }, [productCreated, promptSaveOnExit]);

  const addMappedSku = () => {
    setProduct({ ...product, mappedSkus: [...(product?.mappedSkus ?? []), ''] });
  };

  const deleteMappedSku = index => {
    let mappedSkus = [...(product?.mappedSkus ?? [])];
    mappedSkus.splice(index, 1);
    setProduct({ ...product, mappedSkus });
  };

  const editMappedSku = ({ value, index }) => {
    let mappedSkus = [...(product?.mappedSkus ?? [])];
    mappedSkus[index] = value;
    setProduct({ ...product, mappedSkus });
  };

  return (
    <>
      {/* 
          If there are unsaved changes, prompt the user before navigating 
          using any kind of routing within the app 
      */}
      <Prompt
        when={promptSaveOnExit}
        message='You have unsaved changes. Are you sure you want to leave the page?'
      />
      <Header>
        <HeaderContent>
          <BackButton />
          <Title>Create Product</Title>
          {promptSaveOnExit && (
            <>
              <CancelButton
                size='large'
                onClick={onCancel}>
                Cancel
              </CancelButton>
              <SaveButton
                size='large'
                type='primary'
                onClick={onCreate}>
                Create Product <ArrowBackRounded fontSize='small' />
              </SaveButton>
            </>
          )}
        </HeaderContent>
        {/* {createLoading ? <StyledBarLoader height={3} color='#8aa1b5' /> : null} */}
      </Header>
      <Body>
        <Col>
          <GeneralCard
            editedProduct={product}
            onChange={onChange}
            creating
          />
          <AssemblyDetailsCard
            editedProduct={product}
            onChange={onChange}
          />
          <MappedSkusCard
            editedProduct={product}
            addMappedSku={addMappedSku}
            deleteMappedSku={deleteMappedSku}
            editMappedSku={editMappedSku}
          />
        </Col>
        <Col>
          <ProductionCard
            editedProduct={product}
            onChange={onChange}
          />
          <PackagingCard
            editedProduct={product}
            onChange={onChange}
          />
        </Col>
      </Body>
    </>
  );
}

const Header = styled.div`
  border-bottom: 2px solid #7e95a8;
  padding-bottom: 15px;
`;
const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  column-gap: 30px;
  overflow-y: auto;
  height: 100%;
  border-radius: 8px;
  padding-top: 15px;
`;
const Title = styled.div`
  font-size: 24px;
  margin-left: 35px;
`;
const CancelButton = styled(Button)`
  margin-left: auto;
`;
const SaveButton = styled(Button)`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
// const StyledBarLoader = styled(BarLoader)`
//   position: relative;
//   margin-top: -3px;
//   bottom: -14px;
// `;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
