import React from 'react';
// Router
import history from '../../../routing/history';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
// Custom Components
import WorkOrdersTable from './WorkOrdersTable/WorkOrdersTable';
import EditWorkOrder from './Edit&Create/EditWorkOrder';
import CreateWorkOrder from './Edit&Create/CreateWorkOrder';

export default function WorkOrdersRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <WorkOrdersTable />
      </Route>
      <Route path={`${path}/edit/:workOrderId`}>
        <EditWorkOrder goBack={() => history.push(path)} />
      </Route>
      <Route path={`${path}/create`}>
        <CreateWorkOrder goBack={() => history.push(path)} workOrdersPath={path} />
      </Route>
      <Route path={path}>Oops! - 404</Route>
    </Switch>
  );
}
