import React, { useState } from 'react';
// Styled components
import styled from 'styled-components';
// GraphQL
import { GET_WORKORDER_OPERATIONS } from '../../../../graphQL/workOrders/queries/getWorkOrderOperations';
// Moment
import moment from 'moment';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Create from '@material-ui/icons/CreateRounded';
// Custom Components
import WorkstationPageTemplate from '../WorkstationPageTemplate';
import DateFilter from '../TaskWorkstation/DateFilter';
import DataTable from '../../../../reusable/tables/DataTable/DataTable';
import RushIcon from '../../../../reusable/icons/RushIcon';
import TaskCompletionPopover from '../TaskWorkstation/TaskCompletionPopover';
import PartMarkingInterface from './PartMarkingInterface';
import PrintIcon from '../../../../reusable/label printing/PrintIcon';
import { getBinLabel } from '../../../../reusable/label printing/labels';

export default function CNCStation({ goBack }) {
  const config = {
    title: 'CNC Station',
    operationNames: ['Cut Shell', 'Machine Shell', 'Cut Shell 2', 'Machine Shell 2'],
  };

  const bulkActionButtons = [
    ({ selectedItems }) => (
      <PrintIcon
        getLabel={getBinLabel}
        selectedItems={selectedItems}
        labelTitle='Bin Label(s)'
      />
    ),
  ];

  const initialFilter = {
    'workOrder.status': 'open',
    name: { $in: config.operationNames },
    $expr: { $lt: ['$qtyComplete', '$workOrder.qtyToBuild'] },
    onlyDisplayReadyOperations: true
  };

  const [dateFilter, setDateFilter] = useState(moment());
  const onFilter = ({ value, setFilters }) => {
    setDateFilter(value);
    setFilters({
      ...initialFilter,
      dateRequired: {
        $lt: value ? moment(value).add(1, 'days').startOf('day').toDate() : null,
      },
    });
  };

  const [selectedWorkOrderNumber, setSelectedWorkOrderNumber] = useState(null);
  const [selectedPartNumber, setSelectedPartNumber] = useState(null);

  const columns = [
    {
      title: 'Priority',
      dataIndex: 'workOrder.priority',
      flex: '0 1 90px',
    },
    {
      title: 'WO Due Date',
      dataIndex: 'workOrder.dateRequired',
      render: ({ workOrder }) =>
        workOrder.dateRequired ? moment(workOrder.dateRequired).format('MM/DD/YYYY') : null,
      flex: '0 1 150px',
    },
    {
      title: 'WO Type',
      dataIndex: 'workOrder.type',
      flex: '0 1 90px',
      render: ({ workOrder }) => workOrder.type,
    },
    {
      title: 'WO #',
      dataIndex: 'workOrder.workOrderNumber',
      flex: '0 1 90px',
      render: ({ workOrder }) => (
        <WorkOrderLinkContainer>
          <WorkOrderLink>{workOrder.workOrderNumber}</WorkOrderLink>
          {workOrder?.productConfig === 'rush' && (
            <RushIcon
              style={{
                marginLeft: 'auto',
              }}
            />
          )}
        </WorkOrderLinkContainer>
      ),
    },
    {
      title: 'Part Number',
      dataIndex: 'workOrder.partNumber',
      flex: '0 1 150px',
    },
    {
      title: 'Operation',
      dataIndex: 'name',
      flex: '0 1 150px',
    },
    {
      title: 'Shell 1\nMaterial',
      dataIndex: 'product.custom.shellMaterial',
      flex: '0 1 80px',
    },
    {
      title: 'Shell 1\nDia',
      dataIndex: 'product.custom.shellDiameter',
      flex: '0 1 80px',
    },
    {
      title: 'Shell 1\nLength',
      dataIndex: 'product.custom.shellLength',
      flex: '0 1 80px',
    },
    {
      title: 'Shell 2\nMaterial',
      dataIndex: 'product.custom.shell2Material',
      flex: '0 1 80px',
    },
    {
      title: 'Shell 2\nDia',
      dataIndex: 'product.custom.shell2Diameter',
      flex: '0 1 80px',
    },
    {
      title: 'Shell 2\nLength',
      dataIndex: 'product.custom.shell2Length',
      flex: '0 1 80px',
    },
    {
      title: 'Production Notes',
      dataIndex: 'workOrder.productionNotes',
      style: { justifyContent: 'left' },
    },

    {
      title: 'Part Marking',
      flex: '0 0 90px',
      render: ({ workOrder, name }) =>
        (name === 'Machine Shell' || name === 'Machine Shell 2') && (
          <Tooltip title='Part Marking'>
            <IconButton
              onClick={() => {
                setSelectedWorkOrderNumber(workOrder.workOrderNumber);
                setSelectedPartNumber(`MRC${workOrder.partNumber}`);
              }}>
              <Create size='small' />
            </IconButton>
          </Tooltip>
        ),
    },
    {
      title: 'Completion',
      dataIndex: 'rowDate',
      flex: '0 1 180px',
      render: ({ rowData }) => <TaskCompletionPopover operation={rowData} />,
    },
  ];

  return (
    <WorkstationPageTemplate title={config.title} goBack={goBack}>
      <PartMarkingInterface
        workOrderNumber={selectedWorkOrderNumber}
        partNumber={selectedPartNumber}
      />
      <Title>Task List</Title>
      <DataTable
        query={GET_WORKORDER_OPERATIONS}
        rowKey={'_id'}
        columns={columns}
        bulkActionButtons={bulkActionButtons}
        actionButtons={[]}
        defaultFilter={initialFilter}
        defaultSort={{ 'workOrder.priority': 1 }}
        additionalSort={{ name: 1 }}
      />
    </WorkstationPageTemplate>
  );
}

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 195px;
`;

const WorkOrderLinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const WorkOrderLink = styled.span``;
