import React, { useState, useEffect } from 'react';
// Styled components
import styled from 'styled-components';

export default function FormCurrencyInput({ style, label, value, onChange }) {
  const [initialValue, setInitialValue] = useState('init');
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    if (value === initialValue || (!displayValue && typeof value === 'number')) {
      setInitialValue(value);
      setDisplayValue(value.toFixed(2));
    }
  }, [value]);

  // Handle data changes differently based on the type.
  const handleChange = e => {
    const value = parseFloat(e.target.value);
    if (isNaN(value)) {
      setDisplayValue('');
      onChange(null);
    } else {
      setDisplayValue(value);
      onChange(value);
    }
  };

  // On blur, format as a proper currency
  const onBlur = () => {
    if (typeof value === 'number') {
      setDisplayValue(value.toFixed(2));
    } else {
      setDisplayValue('');
      onChange(null);
    }
  };

  return (
    <Container style={style}>
      {label && <InputLabel>{label}</InputLabel>}
      <InputGroup>
        <CurrencyLabel>$</CurrencyLabel>
        <Input
          type='number'
          min={0.01}
          step={0.01}
          value={displayValue}
          onChange={onChange ? handleChange : null}
          onBlur={onBlur}
          disabled={!onChange ? true : false}
        />
      </InputGroup>
    </Container>
  );
}
const Container = styled.div``;

const InputLabel = styled.div`
  font-size: 12px;
  color: black;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const CurrencyLabel = styled.div`
  background-color: #d0d7db;
  font-size: 16px;
  width: min-content;
  text-align: center;
  border-radius: 2px;
  border: none;
  outline: none;
  padding: 4px 8px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.05);
`;

const Input = styled.input`
  font-size: 16px;
  width: 100%;
  border-radius: 2px;
  border: none;
  outline: none;
  padding: 4px 8px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.05);

  &:disabled {
    background-color: #ededed;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
