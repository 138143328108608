// GraphQL
import { gql } from '@apollo/client';

export const GET_SALESORDER = gql`
  query GetSalesOrder($filter: JSON) {
    salesOrder(filter: $filter) {
      data {
        salesOrderNumber
        dateOrdered
        grandTotal
        shippingAmount
        items {
          qty
          sku
          partNumber
          workOrderNumber
          salesPrice
          plannedShipDate
          actualShipDate
          shippingDescription
          notes
          custom
          magentoInfo {
            entityId
          }
        }
        billingInfo {
          country
          customerFirst
          customerLast
          state
          street
          postalCode
        }
        shippingInfo {
          country
          customerFirst
          customerLast
          state
          street
          postalCode
        }
        magentoInfo {
          entityId
          remoteIp
        }
      }
    }
  }
`;
