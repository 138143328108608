import React from 'react';
// Styled components
import styled from 'styled-components';
// Routing
import history from '../../../../routing/history';

function WorkstationIcon({ title, path }) {
  return <Container onClick={() => history.push(path)}>{title}</Container>;
}
export default WorkstationIcon;
const Container = styled.div`
  flex: 0 0 175px;
  height: 175px;
  line-height: 175px;
  font-size: 20px;
  text-align: center;
  background-color: #d0d7db;
  margin: 15px 0 15px 15px;
  border-radius: 8px;
  box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    transition-duration: 0.3s;
    background-color: #b7c3c9;
  }
`;
