import { cloneDeep } from 'lodash';

/**
 * Clones an object and sets an attribute in on that object
 * based on the given path to that attribute.
 * @param {Object} object
 * @param {String} path
 * @param {Any}    value
 */
export default function setNestedKeyOnClone({ object, path, value }) {
  // Create the clone to work with
  let clone = cloneDeep(object);

  // Store a pointer to the current location in the object
  let current = clone;

  // Convert the path to an array
  path = path.split('.');

  // Navigate to the place in the object
  for (const [index, key] of path.entries()) {
    // If we are at the end of the path, assign the value
    if (index >= path.length - 1) {
      current[key] = value;
      break;
    }

    // If this part of the path doesn't exist, create it
    if (current[key] === null || current[key] === undefined) {
      current[key] = {};
    }

    current = current[key];
  }

  return clone;
}
