import React, { useState } from 'react';
//Material UI
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/FolderRounded';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// Ant Design
import { Button } from 'antd';
// GraphQL
import { gql, useMutation } from '@apollo/client';
// Helper Functions
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

export default function BulkMoveButton({ selectedRowKeys, disabled, currentTab }) {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    if (!disabled) setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  // Mutation for saving the changes to the work order
  const [moveWorkOrder, { loading: deleteLoading, error: deleteError }] = useMutation(
    gql`
      mutation MoveWorkOrder($workOrderNumber: JSON, $newStatus: String!) {
        moveWorkOrder(workOrderNumber: $workOrderNumber, newStatus: $newStatus) {
          workOrderNumber
        }
      }
    `
  );

  const onMove = async newStatus => {
    for (const workOrderNumber of selectedRowKeys) {
      try {
        await moveWorkOrder({
          variables: {
            workOrderNumber,
            newStatus,
          },
          refetchQueries: ['GetWorkOrders'],
        });
      } catch (e) {
        broadcastApolloError(e);
      }
    }
    close();
  };

  return (
    <>
      <Tooltip title='Move selected' placement='top' enterDelay={1500}>
        <IconButton
          onClick={onClick}
          style={{ color: disabled ? '#bababa' : '#2e2e2e' }}
          disableFocusRipple>
          <FolderIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onBackdropClick={close} onEscapeKeyDown={close}>
        <DialogTitle>Move</DialogTitle>
        <DialogContent style={{ width: '600px' }}>
          Where do you want to move these work orders?
        </DialogContent>
        <DialogActions>
          <Button onClick={close} style={{ marginRight: 'auto' }}>
            Cancel
          </Button>
          {currentTab !== 'open' && (
            <Button
              onClick={() => onMove('open')}
              type='primary'
              style={{ marginLeft: '15px', width: '90px' }}>
              Open
            </Button>
          )}
          {currentTab !== 'closed' && (
            <Button
              onClick={() => onMove('closed')}
              type='primary'
              style={{ marginLeft: '15px', width: '90px' }}>
              Closed
            </Button>
          )}
          {currentTab !== 'archived' && (
            <Button
              onClick={() => onMove('archived')}
              type='primary'
              style={{ marginLeft: '15px', width: '90px' }}>
              Archived
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
