import React from 'react';
// Styled components
import styled from 'styled-components';
// Custom components
import CardTemplate from '../../../../../reusable/layout/CardTemplate';
// Ant Design
import { List, Input, Button } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
//Material UI
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import Tooltip from '@material-ui/core/Tooltip';

export default function MappedSkusCard({
  product,
  addMappedSku,
  deleteMappedSku,
  editMappedSku,
}) {
  const AddButton = ({ onClick }) => (
    <Tooltip title='Add mapped SKU' placement='top' enterDelay={1500}>
      <IconButton onClick={onClick} style={{ marginTop: '6px' }}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );

  const DeleteButton = ({ onClick }) => (
    <Tooltip title='Delete mapped SKU' placement='top' enterDelay={1500}>
      <IconButton
        onClick={onClick}
        size='small'
        style={{ marginLeft: '15px', height: '32px', width: '32px' }}>
        <DeleteIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  );

  return (
    <CardTemplate title='Mapped SKUs'>
      <ListContainer>
        <List
          dataSource={product?.mappedSkus ?? []}
          renderItem={(value, index) => (
            <StyledListItem>
              <Input
                value={value}
                onChange={e => editMappedSku({ index, value: e.target.value })}
              />
              <DeleteButton onClick={() => deleteMappedSku(index)} />
            </StyledListItem>
          )}
        />
        <AddButton onClick={addMappedSku} />
      </ListContainer>
    </CardTemplate>
  );
}

const ListContainer = styled.div`
  padding: 15px 20px;
  background-color: white;
  border-radius: 8px;
`;

const StyledListItem = styled.div`
  background-color: white;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
`;
