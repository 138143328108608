import React, { useState } from 'react';
// Styled components
import styled from 'styled-components';
// GraphQL
import { GET_WORKORDER_OPERATIONS } from '../../../../graphQL/workOrders/queries/getWorkOrderOperations';
// Moment
import moment from 'moment';
// Custom Components
import WorkstationPageTemplate from '../WorkstationPageTemplate';
import DateFilter from './DateFilter';
import DataTable from '../../../../reusable/tables/DataTable/DataTable';
import RushIcon from '../../../../reusable/icons/RushIcon';
import TaskCompletionPopover from './TaskCompletionPopover';

export default function TaskWorkstation({ goBack, config, productDataColumns, printIcon }) {
  if (!productDataColumns) productDataColumns = [];

  const initialFilter = {
    'workOrder.status': 'open',
    name: { $in: config.operationNames },
    $expr: { $lt: ['$qtyComplete', '$workOrder.qtyToBuild'] },
    onlyDisplayReadyOperations: true
  };

  const [dateFilter, setDateFilter] = useState(moment());
  const onFilter = ({ value, setFilters }) => {
    setDateFilter(value);
    setFilters({
      ...initialFilter,
      dateRequired: {
        $lt: value ? moment(value).add(1, 'days').startOf('day').toDate() : null,
      },
    });
  };

  const columns = [
    {
      title: 'Priority',
      dataIndex: 'workOrder.priority',
      flex: '0 1 90px',
    },
    {
      title: 'WO Due Date',
      dataIndex: 'workOrder.dateRequired',
      render: ({ workOrder }) =>
        workOrder.dateRequired ? moment(workOrder.dateRequired).format('MM/DD/YYYY') : null,
      flex: '0 1 150px',
    },
    {
      title: 'WO Type',
      dataIndex: 'workOrder.type',
      flex: '0 1 90px',
      render: ({ workOrder }) => workOrder.type,
    },
    {
      title: 'WO #',
      dataIndex: 'workOrder.workOrderNumber',
      flex: '0 1 90px',
      render: ({ workOrder }) => {
        return (
          <WorkOrderLinkContainer>
            <WorkOrderLink>{workOrder.workOrderNumber}</WorkOrderLink>
            {workOrder?.productConfig === 'rush' && (
              <RushIcon
                style={{
                  marginLeft: 'auto',
                }}
              />
            )}
          </WorkOrderLinkContainer>
        );
      },
    },
    {
      title: 'Part Number',
      dataIndex: 'workOrder.partNumber',
      flex: '0 1 150px',
    },
    {
      title: 'Operation',
      dataIndex: 'name',
      flex: '0 1 150px',
    },
    ...productDataColumns.map(column => ({
      title: column.title,
      dataIndex: 'product',
      render: ({ product }) => {
        return product?.custom?.[column.dataIndex] ?? null;
      },
      flex: '0 1 80px',
    })),
    {
      title: 'Production Notes',
      dataIndex: 'workOrder.productionNotes',
      style: { justifyContent: 'left' },
    },
    {
      title: 'Completion',
      dataIndex: 'rowDate',
      flex: '0 1 180px',
      render: ({ rowData }) => <TaskCompletionPopover operation={rowData} />,
    },
  ];

  return (
    <WorkstationPageTemplate title={config.title} goBack={goBack}>
      <Title>Task List</Title>
      <DataTable
        query={GET_WORKORDER_OPERATIONS}
        rowKey={'_id'}
        columns={columns}
        bulkActionButtons={printIcon ? [printIcon] : null}
        actionButtons={[]}
        defaultFilter={initialFilter}
        defaultSort={{ 'workOrder.priority': 1 }}
        additionalSort={{ name: -1 }}
      />
    </WorkstationPageTemplate>
  );
}

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
`;

const WorkOrderLinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const WorkOrderLink = styled.span``;
