// GraphQL
import { gql } from '@apollo/client';

export const GET_DASHBOARD_DATA_BY_OPERATOR = gql`
  query GetDashboardDataByOperator($fromDate: String, $toDate: String) {
    getDashboardDataCompletedByOperator(fromDate: $fromDate, toDate: $toDate) {
      result {
        name
        count
      }
    }
  }
`;
