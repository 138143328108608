import React, { useState } from 'react';
import styled from 'styled-components';
// Custom Components
import DashboardDataByDepartment from './DashboardDataByDepartment';
import DashboardDataByOperator from './DashboardDataByOperator';
import FilterPannel from './FilterPannel';
// Helper Functions
import getDateBefore7Days from '../../../reusable/helper functions/getDateBefore7Days';
import setNestedKeyOnClone from '../../../reusable/helper functions/setNestedKeyOnClone';

export default function Reports() {
  const [dashboardFilter, setDashboardFilter] = useState({
    toDate: new Date(),
    fromDate: getDateBefore7Days(),
  });

  // Editing the dashboardFilter
  const onChange = ({ path, value }) => {
    const newEditedDashboardFilter = setNestedKeyOnClone({ object: dashboardFilter, path, value });
    setDashboardFilter(newEditedDashboardFilter);
  };

  return (
    <Container>
      <FilterPannel
        dashboardFilter={dashboardFilter}
        onChange={onChange}
      />
      <ReportsContainer>
        <DashboardDataByDepartment dashboardFilter={dashboardFilter} />
        <DashboardDataByOperator dashboardFilter={dashboardFilter} />
      </ReportsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  row-gap: 1rem;
`;

const ReportsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  gap: 30px;
`;
