import React, { useState, useEffect } from 'react';
// GraphQL
import { gql, useQuery, useMutation, NetworkStatus } from '@apollo/client';
// Styled components
import styled from 'styled-components';
// Moment
import moment from 'moment';
// Custom components
import ShippingScanProcess from './ShippingScanProcess';
import WorkstationPageTemplate from '../WorkstationPageTemplate';
import DataTable from '../../../../reusable/tables/DataTable/DataTable';
// Helper functions
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

export default function ShippingStation({ goBack }) {
  const [scanProcessData, setScanProcessData] = useState({
    step: 0,
    title: 'Scan Sales Order',
    prompt: 'Scan the pick label to get started',
    borderColor: 'white',
    loading: false,
    currentSalesOrder: {},
    currentWorkOrder: {},
    currentItem: {},
  });

  // Query for sales orders that need to ship
  const salesOrderItemsQuery = gql`
    query GetSalesOrderItemsToShip($sort: JSON, $filter: JSON, $pagination: JSON) {
      items(sort: $sort, filter: $filter, pagination: $pagination) {
        meta {
          skip
          limit
          totalRecords
        }
        data {
          _id
          qty
          sku
          partNumber
          notes
          salesOrder {
            salesOrderNumber
          }
        }
      }
    }
  `;
  const salesOrderItemsQueryParams = {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        plannedShipDate: { $lte: moment().add(1, 'days').startOf('day').toDate() },
        actualShipDate: null,
      },
    },
  };

  // Query for a single sales order
  const salesOrderQuery = gql`
    query GetSalesOrder($filter: JSON) {
      salesOrder(filter: $filter) {
        data {
          salesOrderNumber
          items {
            _id
            partNumber
            sku
            qty
            plannedShipDate
            actualShipDate
            notes
          }
        }
      }
    }
  `;

  const getSalesOrder = useImperativeQuery(salesOrderQuery);

  // Query for a single work order
  const workOrderQuery = gql`
    query GetWorkOrder($filter: JSON) {
      workOrder(filter: $filter) {
        data {
          workOrderNumber
          partNumber
        }
      }
    }
  `;

  const getWorkOrder = useImperativeQuery(workOrderQuery);

  const [updateSalesOrderItem] = useMutation(
    gql`
      mutation UpdateSalesOrderItem($item: JSON!) {
        updateItem(item: $item) {
          _id
          partNumber
          sku
          qty
          plannedShipDate
          actualShipDate
          notes
        }
      }
    `
  );

  const [incrementProductInventory] = useMutation(
    gql`
      mutation IncrementProductInventory($filter: JSON, $product: JSON) {
        updateProduct(filter: $filter, product: $product) {
          partNumber
        }
      }
    `
  );

  const shipCurrentItem = async () => {
    let updatedSalesOrderItem;
    const { currentItem, currentWorkOrder } = scanProcessData;

    // Store the actual ship date and work order number
    try {
      updatedSalesOrderItem = (
        await updateSalesOrderItem({
          variables: {
            item: {
              _id: currentItem._id,
              actualShipDate: new Date(),
              workOrderNumber: currentWorkOrder.workOrderNumber,
            },
          },
          refetchQueries: ['GetSalesOrderItemsToShip'],
        })
      ).data.updateItem;
    } catch (e) {
      broadcastApolloError(e);
    }

    // Subtract from inventory
    try {
      await incrementProductInventory({
        variables: {
          filter: {
            partNumber: currentItem.partNumber,
          },
          product: {
            partNumber: currentItem.partNumber,
            $inc: { qtyOnHand: -currentItem.qty },
          },
        },
      });
    } catch (e) {
      broadcastApolloError(e);
    }

    return updatedSalesOrderItem;
  };

  // Called by onChange if we have ~~, or by enter button
  const handleInput = async input => {
    switch (scanProcessData.step) {
      case 0:
        // Loading state...
        setScanProcessData({
          ...scanProcessData,
          scanned: input,
          title: 'Scan Sales Order',
          prompt: 'Getting items...',
          borderColor: '#f7d80c',
          loading: true,
        });

        // Get the sales order
        const salesOrder = (
          await getSalesOrder({
            filter: { salesOrderNumber: input },
          })
        )?.data?.salesOrder?.data?.[0];

        // If we didn't find the sales order, alert the user
        if (!salesOrder) {
          setScanProcessData({
            ...scanProcessData,
            scanned: input,
            step: 0,
            title: 'Scan Sales Order',
            prompt: 'Sales order not found. Try again.',
            borderColor: '#db0231',
            currentSalesOrder: {},
            loading: false,
          });
          return;
        }

        // Tell the user we found the sales order
        setScanProcessData({
          ...scanProcessData,
          scanned: input,
          step: 1,
          title: 'Scan Work Order',
          prompt: 'Sales order found! Scan the work order on the box.',
          borderColor: '#00b868',
          currentSalesOrder: salesOrder,
          loading: false,
        });

        return;

      case 1:
        // Loading state...
        setScanProcessData({
          ...scanProcessData,
          scanned: input,
          title: 'Scan Work Order',
          prompt: 'Checking work order...',
          borderColor: '#f7d80c',
          loading: true,
        });

        // Get the work order
        const isNumberString = /^\d+$/;
        const workOrder = (
          await getWorkOrder({
            filter: { workOrderNumber: isNumberString.test(input) ? Number(input) : String(input) },
          })
        )?.data?.workOrder?.data?.[0];

        // If there's not a work order, alert the user
        if (!workOrder) {
          setScanProcessData({
            ...scanProcessData,
            scanned: input,
            step: 1,
            title: 'Work Order Not Found',
            prompt: 'Work order not found. Please scan again.',
            borderColor: '#db0231',
            loading: false,
          });
          return;
        }

        // Find the matching item on the work order
        const newItem = scanProcessData.currentSalesOrder.items.find(
          item => item.partNumber === workOrder.partNumber
        );

        // If the item is not found on the work order, alert the user
        if (!newItem) {
          setScanProcessData({
            ...scanProcessData,
            scanned: input,
            step: 1,
            title: 'Invalid Work Order',
            prompt: `The part number on the work order (${workOrder.partNumber}) does not match any part number on the sales order. Please scan again.`,
            borderColor: '#db0231',
            loading: false,
          });
          return;
        }

        // Check that the matching item has not already been shipped
        if (newItem.actualShipDate) {
          setScanProcessData({
            ...scanProcessData,
            scanned: input,
            step: 1,
            title: 'Already Shipped',
            prompt: `Part ${workOrder.partNumber} has already been shipped. Please scan another work order.`,
            borderColor: '#db0231',
            loading: false,
          });
          return;
        }

        // Alert the user we found the work order and start tracking information
        // about the current sales order item
        setScanProcessData({
          ...scanProcessData,
          scanned: input,
          currentItem: {
            ...newItem,
            qtyRemaining: newItem.qty,
          },
          currentWorkOrder: workOrder,
          step: 2,
          title: 'Scan Quantity',
          prompt: `Work order "${workOrder.workOrderNumber}" found. Part number "${workOrder.partNumber}" is on sales order. Scan the quantity of the item being shipped.`,
          borderColor: '#00b868',
          loading: false,
        });

        return;

      case 2:
        const { currentItem } = scanProcessData;

        // Check that the quantity input is a number
        input = parseInt(input);
        if (isNaN(input) || input <= 0) {
          setScanProcessData({
            ...scanProcessData,
            scanned: input,
            step: 2,
            title: 'Invalid Quantity',
            prompt: 'Not a valid quantity. Please scan again.',
            borderColor: '#db0231',
            loading: false,
          });
          return;
        }

        // If the qty scanned is less than what is remaining, prompt to scan again
        if (input < currentItem.qtyRemaining) {
          const newQtyRemaining = currentItem.qtyRemaining - input;
          setScanProcessData({
            ...scanProcessData,
            scanned: input,
            currentItem: { ...scanProcessData.currentItem, qtyRemaining: newQtyRemaining },
            step: 2,
            title: 'Scan Quantity',
            prompt: `Qty (${newQtyRemaining}) more needed. Scan quantities until order is fulfilled.`,
            borderColor: '#db0231',
            loading: false,
          });
          return;
        }

        // If the qty is greater than what is remaining, don't allow the input
        if (input > currentItem.qtyRemaining) {
          setScanProcessData({
            ...scanProcessData,
            scanned: input,
            step: 2,
            title: 'Invalid Quantity',
            prompt: `Qty is greater than quantity ordered. Please scan again.`,
            borderColor: '#db0231',
            loading: false,
          });
          return;
        }

        // Mark the item shipped and subtract from inventory
        const updatedSalesOrderItem = await shipCurrentItem();

        // Determine how to update the items in scanProcessData
        const updatedIndex = scanProcessData.currentSalesOrder.items.findIndex(
          item => item._id === updatedSalesOrderItem._id
        );
        const newItems = [...scanProcessData.currentSalesOrder.items];
        newItems[updatedIndex] = updatedSalesOrderItem;

        // Check if it's the final item to ship out
        const finalWorkOrder =
          scanProcessData.currentSalesOrder.items.filter(
            _item => _item._id !== currentItem._id && !_item.actualShipDate
          ).length === 0;

        // If not the final work order, alert the user there are more items to ship
        if (!finalWorkOrder) {
          setScanProcessData({
            ...scanProcessData,
            scanned: input,
            currentSalesOrder: { ...scanProcessData.currentSalesOrder, items: newItems },
            step: 1,
            title: 'Scan Work Order',
            prompt: 'Item marked shipped! Scan the work order for the next item.',
            borderColor: '#00b868',
            loading: false,
          });
          return;
        }

        // Done!
        setScanProcessData({
          ...scanProcessData,
          scanned: input,
          currentSalesOrder: { ...scanProcessData.currentSalesOrder, items: newItems },
          step: 0,
          title: 'Scan Sales Order',
          prompt: 'All items are shipped. Scan a new sales order.',
          borderColor: '#173753',
          loading: false,
        });

        return;

      default:
        setScanProcessData({
          ...scanProcessData,
          scanned: input,
          step: 0,
          title: 'Error',
          prompt: 'Invalid step',
          borderColor: '#db0231',
          currentSalesOrder: {},
          loading: false,
        });
    }
  };

  return (
    <WorkstationPageTemplate title='Shipping Station' goBack={goBack}>
      <ShippingScanProcess
        onInput={handleInput}
        scanProcessData={scanProcessData}
        setScanProcessData={setScanProcessData}
        salesOrderItemsQuery={salesOrderItemsQuery}
        salesOrderItemsQueryParams={salesOrderItemsQueryParams}
      />
      <SectionBar>Items to ship today:</SectionBar>
      <DataTable
        rowKey={'_id'}
        columns={[
          {
            title: 'Sales Order Number',
            dataIndex: 'salesOrder.salesOrderNumber',
          },
          {
            title: 'Quantity',
            dataIndex: 'qty',
          },
          { title: 'SKU', dataIndex: 'sku', width: 150 },
          {
            title: 'Part Number',
            dataIndex: 'partNumber',
          },
          {
            title: 'Ship By',
            dataIndex: 'plannedShipDate',
            render: plannedShipDate => moment(plannedShipDate).format('MM/DD/YYYY'),
          },
          {
            title: 'Notes',
            dataIndex: 'notes',
          },
        ]}
        query={salesOrderItemsQuery}
        defaultFilter={{
          plannedShipDate: { $lte: moment().add(1, 'days').startOf('day').toDate() },
          actualShipDate: null,
        }}
      />
    </WorkstationPageTemplate>
  );
}

const SectionBar = styled.div`
  font-size: 24px;
`;

const useImperativeQuery = query => {
  const { refetch } = useQuery(query, { skip: true });

  const callQuery = variables => {
    return refetch(variables);
  };

  return callQuery;
};
