import React from 'react';
import { useState, useEffect } from 'react';
// History
import history from '../../../../routing/history';
// Styled Components
import styled from 'styled-components';
// Material UI
import MuiAvatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import AccountBox from '@material-ui/icons/AccountBoxRounded';
import Logout from '@material-ui/icons/PowerSettingsNewRounded';

// function Avatar({ onClick, logout, profile }) {
function Avatar({ onClick, user }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [initials, setInitials] = useState(null);

  useEffect(() => {
    if (user) {
      user.get().then(snapshot => {
        const userData = snapshot.data();
        if (userData) {
          setInitials(userData.initials);
        }
      });
    }
  }, [user]);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClicked = () => {
    handleMenuClose();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    history.push('/');
  };

  const onMyAccountClicked = () => {
    history.push('/app/account');
    handleMenuClose();
  };

  return (
    <Button onClick={onClick}>
      <MuiAvatar aria-controls='avatar-menu' aria-haspopup='true' onClick={handleMenuClick}>
        {initials}
      </MuiAvatar>
      <Menu
        id='avatar-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
        <MenuItem onClick={onMyAccountClicked}>
          <ListItemIcon>
            <AccountBox fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>My Account</Typography>
        </MenuItem>
        <MenuItem onClick={onLogoutClicked}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>Logout</Typography>
        </MenuItem>
      </Menu>
    </Button>
  );
}

export default Avatar;

const Button = styled.button`
  width: 100%;
  background: none;
  border: none;
  margin: 10px auto 20px auto;
  cursor: pointer;
  outline: none;
  color: white;

  display: flex;
  justify-content: center;

  &:hover {
    color: #ff6666;
  }
`;
