import React from 'react';
import styled from 'styled-components';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import history from '../../../../routing/history';
import WorkstationIcon from './WorkstationIcon';
import TaskWorkstation from '../TaskWorkstation/TaskWorkstation';
import CNCStation from '../CNCStation/CNCStation';
import ShippingStation from '../ShippingStation/ShippingStation';
import LabelPrinting from '../LabelPrinting/LabelPrinting';
import PrintIcon from '../../../../reusable/label printing/PrintIcon';
import { getBoxLabel, getBinLabel } from '../../../../reusable/label printing/labels';

export default function WorkstationsRouter({ companyId, operations }) {
  const { path } = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route exact path={path}>
          <TitleBar>
            <Title>Workstations</Title>
          </TitleBar>
          <MainPageContent>
            <FlexBreak>Functional Areas</FlexBreak>
            <WorkstationIcon title={'Bundle Area'} path={path + '/bundle-area'} />
            <WorkstationIcon title={'CNC Area'} path={path + '/cnc-area'} />
            <WorkstationIcon title={'Kitting Area'} path={path + '/kitting-area'} />
            <WorkstationIcon title={'Braze Area'} path={path + '/braze-area'} />
            <WorkstationIcon title={'Test Area'} path={path + '/test-area'} />
            <WorkstationIcon title={'Paint Area'} path={path + '/paint-area'} />
            <WorkstationIcon title={'Pack Area'} path={path + '/pack-area'} />
            <FlexBreak>Inventory</FlexBreak>
            <WorkstationIcon title={'Shipping Station'} path={path + '/shipping-station'} />
            <FlexBreak>Utilities</FlexBreak>
            <WorkstationIcon title={'Label Printing'} path={path + '/label-printing'} />
          </MainPageContent>
        </Route>
        <Route path={`${path}/bundle-area`}>
          <TaskWorkstation
            goBack={() => history.push(path)}
            config={{
              title: 'Bundle Area',
              operationNames: ['Cut Tube', 'Build Bundle'],
            }}
            printIcon={({ selectedItems }) => (
              <PrintIcon
                getLabel={getBinLabel}
                selectedItems={selectedItems}
                labelTitle='Bin Label(s)'
              />
            )}
            productDataColumns={[
              { title: 'Bundle 1\nPN', dataIndex: 'bundle1PartNumber' },
              { title: 'Bundle 2\nPN', dataIndex: 'bundle2PartNumber' },
            ]}
          />
        </Route>
        <Route path={`${path}/cnc-area`}>
          <CNCStation goBack={() => history.push(path)} />
        </Route>
        <Route path={`${path}/kitting-area`}>
          <TaskWorkstation
            goBack={() => history.push(path)}
            config={{ title: 'Kitting Area', operationNames: ['Kitting'] }}
            printIcon={({ selectedItems }) => (
              <PrintIcon
                getLabel={getBinLabel}
                selectedItems={selectedItems}
                labelTitle='Bin Label(s)'
              />
            )}
          />
        </Route>
        <Route path={`${path}/braze-area`}>
          <TaskWorkstation
            goBack={() => history.push(path)}
            config={{ title: 'Braze Area', operationNames: ['Braze'] }}
          />
        </Route>
        <Route path={`${path}/test-area`}>
          <TaskWorkstation
            goBack={() => history.push(path)}
            config={{ title: 'Test Area', operationNames: ['Test'] }}
          />
        </Route>
        <Route path={`${path}/paint-area`}>
          <TaskWorkstation
            goBack={() => history.push(path)}
            config={{ title: 'Paint Area', operationNames: ['Paint'] }}
            productDataColumns={[{ title: 'Color', dataIndex: 'color' }]}
          />
        </Route>
        <Route path={`${path}/pack-area`}>
          <TaskWorkstation
            goBack={() => history.push(path)}
            config={{ title: 'Pack Area', operationNames: ['Pack'] }}
            printIcon={({ selectedItems }) => (
              <PrintIcon
                getLabel={getBoxLabel}
                selectedItems={selectedItems}
                labelTitle='Box Label(s)'
                promptForQty={true}
              />
            )}
            productDataColumns={[{ title: 'Box', dataIndex: 'boxPartNumber' }]}
          />
        </Route>
        <Route path={`${path}/shipping-station`}>
          <ShippingStation goBack={() => history.push(path)} />
        </Route>
        <Route path={`${path}/label-printing`}>
          <LabelPrinting goBack={() => history.push(path)} />
        </Route>
        <Route path={path}>Oops! - 404</Route>
      </Switch>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
`;
const TitleBar = styled.div`
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Title = styled.div`
  font-size: 36px;
`;
const MainPageContent = styled.div`
  margin: 0 30px;
  height: calc(100vh - 30px - 56px - 30px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;
`;
const FlexBreak = styled.div`
  flex: 1 1 100%;
  font-size: 24px;
  margin-left: 15px;
`;
