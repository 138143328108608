import React, { useState } from 'react';
//Material UI
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// Ant Design
import { Button } from 'antd';
// GraphQL
import { useRemoveProduct } from '../../../../graphQL/products/mutations/removeProduct';
// Helper Functions
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

export default function BulkDeleteButton({ selectedRowKeys, disabled }) {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    if (!disabled) setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  // Mutation for saving the changes to the sales order
  const { mutate: removeProduct } = useRemoveProduct();

  const onDelete = async () => {
    for (const partNumber of selectedRowKeys) {
      try {
        await removeProduct({
          variables: { filter: { partNumber } },
          refetchQueries: ['GetProducts'],
        });
      } catch (e) {
        broadcastApolloError(e);
      }
    }
    close();
  };

  return (
    <>
      <Tooltip title='Delete selected' placement='top' enterDelay={1500}>
        <IconButton
          onClick={onClick}
          style={{ color: disabled ? '#bababa' : '#2e2e2e' }}
          disableFocusRipple>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onBackdropClick={close} onEscapeKeyDown={close}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete the selected products?</DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={onDelete} type='primary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
