// GraphQL
import { gql, useMutation } from '@apollo/client';

const UPDATE_SALESORDER = gql`
  mutation UpdateSalesOrder($filter: JSON, $salesOrder: JSON) {
    updateSalesOrder(filter: $filter, salesOrder: $salesOrder) {
      dateOrdered
      grandTotal
      items {
        qty
        sku
        partNumber
        workOrderNumber
        salesPrice
        plannedShipDate
        actualShipDate
        shippingDescription
        notes
        custom
        magentoInfo {
          entityId
        }
      }
      billingInfo {
        country
        customerFirst
        customerLast
        state
        street
        postalCode
      }
      shippingInfo {
        country
        customerFirst
        customerLast
        state
        street
        postalCode
      }
      magentoInfo {
        entityId
        remoteIp
      }
    }
  }
`;

export const useUpdateSalesOrder = () => {
  const [mutate, { data, loading, error }] = useMutation(UPDATE_SALESORDER);
  return { mutate, data, loading, error };
};
