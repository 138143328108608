// GraphQL
import { gql, useQuery, NetworkStatus } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers($sort: JSON, $filter: JSON, $pagination: JSON) {
    users(sort: $sort, filter: $filter, pagination: $pagination) {
      meta {
        skip
        limit
        totalRecords
      }
      data {
        _id
        givenName
        familyName
        email
        role
      }
    }
  }
`;
