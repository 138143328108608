import React from 'react';
// Styled components
import styled from 'styled-components';
// Custom components
import CardTemplate from '../../../../../reusable/layout/CardTemplate';
import FormStringInput from '../../../../../reusable/forms/FormStringInput';
import FormWeightInput from '../../../../../reusable/forms/FormWeightInput';

export default function PackagingCard({ editedProduct, onChange }) {
  return (
    <CardTemplate title='Packaging'>
      <FormContainer>
        <FormStringInput
          label='Pcs Per Box'
          value={editedProduct?.custom?.pcsPerBox}
          onChange={value => onChange({ path: 'custom.pcsPerBox', value })}
        />
        <FormWeightInput
          label='Weight'
          value={editedProduct?.custom?.weight}
          onChange={value => onChange({ path: 'custom.weight', value })}
        />
        <FormStringInput
          label='Box'
          value={editedProduct?.custom?.boxPartNumber}
          onChange={value => onChange({ path: 'custom.boxPartNumber', value })}
        />
      </FormContainer>
    </CardTemplate>
  );
}

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
`;
