// GraphQL
import { gql } from '@apollo/client';

export const GET_WORKORDERS = gql`
  query GetWorkOrders($sort: JSON, $filter: JSON, $pagination: JSON) {
    workOrders(sort: $sort, filter: $filter, pagination: $pagination) {
      meta {
        skip
        limit
        totalRecords
      }
      data {
        priority
        workOrderNumber
        type
        partNumber
        productConfig
        qtyToBuild
        dateRequired
        operations {
          _id
          name
          completedById
          group
          groupIndex
          qtyComplete
          dateRequired
          dateCompleted
        }
        custom
        productionNotes
      }
    }
  }
`;
