import React, { useState, useEffect } from 'react';
//Routing
import { withRouter, Prompt } from 'react-router-dom';
// Styled components
import styled from 'styled-components';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
// Ant Design
import { Button } from 'antd';
// GraphQL
import { useQuery } from '@apollo/client';
import { GET_PRODUCT } from '../../../../graphQL/products/queries/getProduct';
import { useUpdateProduct } from '../../../../graphQL/products/mutations/updateProduct';
// Custom Components
import GeneralCard from './cards/GeneralCard';
import ProductionCard from './cards/ProductionCard';
import AssemblyDetailsCard from './cards/AssemblyDetailsCard';
import PackagingCard from './cards/PackagingCard';
import MappedSkusCard from './cards/MappedSkusCard';
import DefaultOperationsCard from './cards/DefaultOperationsCard/DefaultOperationsCard';
// Deep Object Diff
import { diff } from 'deep-object-diff';
// Bar Loader
// import BarLoader from 'react-bar-loader';
// Helper Functions
import setNestedKeyOnClone from '../../../../reusable/helper functions/setNestedKeyOnClone';
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

function EditProduct({ match, goBack }) {
  const { partNumber } = match.params ?? {};

  const { loading: queryLoading, data: queryData } = useQuery(GET_PRODUCT, {
    fetchPolicy: 'network-only',
    notifyOnNetsalesStatusChange: true,
    variables: {
      filter: { partNumber },
    },
  });

  // When the current product is fetched, set it in state
  const [originalProduct, setOriginalProduct] = useState({});
  const [editedProduct, setEditedProduct] = useState({});
  useEffect(() => {
    const product = queryData?.product?.data?.[0];
    if (product) {
      setEditedProduct(product);
      setOriginalProduct(product);
    }
  }, [queryData]);

  // Editing the product
  const onChange = ({ path, value }) => {
    const newEditedProduct = setNestedKeyOnClone({ object: editedProduct, path, value });
    setEditedProduct(newEditedProduct);
  };

  // Track when the edited product is different than the original product
  const [productIsEdited, setProductIsEdited] = useState(false);
  useEffect(() => {
    const changes = diff(originalProduct, editedProduct);
    if (Object.keys(changes).length > 0) {
      setProductIsEdited(true);
    } else {
      setProductIsEdited(false);
    }
  }, [originalProduct, editedProduct]);

  const onBack = () => {
    goBack();
  };

  // When the user has saved, automatically go back. We must wait for "productIsEdited" to be false
  const [goBackFlag, setGoBackFlag] = useState(false);
  useEffect(() => {
    if (goBackFlag && !productIsEdited) goBack();
  }, [goBackFlag, productIsEdited]);

  const BackButton = () => (
    <IconButton onClick={onBack}>
      <BackIcon size='small' />
    </IconButton>
  );

  // Reset the product to original state
  const onCancel = () => {
    setEditedProduct(originalProduct);
  };

  // Save the changes to the product
  const { mutate: updateProduct, loading: saveLoading } = useUpdateProduct();
  const onSave = async () => {
    try {
      await updateProduct({
        variables: {
          filter: { partNumber: editedProduct.partNumber },
          product: editedProduct,
        },
        refetchQueries: ['GetProduct'],
      });

      setGoBackFlag(true);
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  const addMappedSku = () => {
    setEditedProduct({ ...editedProduct, mappedSkus: [...editedProduct.mappedSkus, ''] });
  };

  const deleteMappedSku = index => {
    let mappedSkus = [...editedProduct.mappedSkus];
    mappedSkus.splice(index, 1);
    setEditedProduct({ ...editedProduct, mappedSkus });
  };

  const editMappedSku = ({ value, index }) => {
    let mappedSkus = [...editedProduct.mappedSkus];
    mappedSkus[index] = value;
    setEditedProduct({ ...editedProduct, mappedSkus });
  };

  return (
    <>
      {/* 
          If there are unsaved changes, prompt the user before navigating 
          using any kind of routing within the app 
      */}
      <Prompt
        when={productIsEdited}
        message='You have unsaved changes. Are you sure you want to leave the page?'
      />
      <Header>
        <HeaderContent>
          <BackButton />
          <Title>Edit Product | {partNumber}</Title>
          {productIsEdited ? (
            <>
              <CancelButton
                size='large'
                onClick={onCancel}>
                Cancel
              </CancelButton>
              <SaveButton
                size='large'
                type='primary'
                onClick={onSave}>
                Save <ArrowBackRounded fontSize='small' />
              </SaveButton>
            </>
          ) : null}
        </HeaderContent>
        {/* {queryLoading || saveLoading ? <StyledBarLoader height={3} color='#8aa1b5' /> : null} */}
      </Header>
      <Body>
        <Col>
          <GeneralCard
            editedProduct={editedProduct}
            onChange={onChange}
            creating
          />
          <AssemblyDetailsCard
            editedProduct={editedProduct}
            onChange={onChange}
          />
          <MappedSkusCard
            product={editedProduct}
            addMappedSku={addMappedSku}
            deleteMappedSku={deleteMappedSku}
            editMappedSku={editMappedSku}
          />
        </Col>
        <Col>
          <ProductionCard
            editedProduct={editedProduct}
            onChange={onChange}
          />
          <PackagingCard
            editedProduct={editedProduct}
            onChange={onChange}
          />
          <DefaultOperationsCard
            editedProduct={editedProduct}
            onChange={onChange}
          />
        </Col>
      </Body>
    </>
  );
}
export default withRouter(EditProduct);

const Header = styled.div`
  border-bottom: 2px solid #7e95a8;
  padding-bottom: 15px;
`;
const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  column-gap: 30px;
  overflow-y: auto;
  height: 100%;
  border-radius: 8px;
  padding-top: 15px;
`;
const Title = styled.div`
  font-size: 24px;
  margin-left: 35px;
`;
const CancelButton = styled(Button)`
  margin-left: auto;
`;
const SaveButton = styled(Button)`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
// const StyledBarLoader = styled(BarLoader)`
//   position: relative;
//   margin-top: -3px;
//   bottom: -14px;
// `;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
