import React, { useState, useEffect } from 'react';
// Ant Design
import { DatePicker } from 'antd';
// Styled components
import styled from 'styled-components';
// Moment
import moment from 'moment';

export default function DateFilter({ dateFilter, onChange }) {
  return (
    <Container>
      <Title>Date filter:</Title>
      <DatePicker
        allowClear={false}
        format='MM/DD/YYYY'
        value={dateFilter}
        onChange={onChange}
      />
    </Container>
  );
}

const Title = styled.div`
  font-size: 16px;
  margin-right: 10px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 10px;
  align-items: center;
`;
