import React from 'react';
// Styled components
import styled from 'styled-components';
//Material UI
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import Tooltip from '@material-ui/core/Tooltip';
// Custom components
import CardTemplate from '../../../../../../reusable/layout/CardTemplate';
import Table from '../../../../../../reusable/tables/Table/Table';
import FormBooleanInput from '../../../../../../reusable/forms/FormBooleanInput';
import FormCurrencyInput from '../../../../../../reusable/forms/FormCurrencyInput';
import FormWeightInput from '../../../../../../reusable/forms/FormWeightInput';
import FormStringInput from '../../../../../../reusable/forms/FormStringInput';
import FormIntegerInput from '../../../../../../reusable/forms/FormIntegerInput';
import FormDateInput from '../../../../../../reusable/forms/FormDateInput';
import EditablePartNumber from './EditablePartNumber';
// Helper functions
import setNestedKeyOnClone from '../../../../../../reusable/helper functions/setNestedKeyOnClone';

function LineItemsCard({ editedSalesOrder, onChange, creating }) {
  let { items } = editedSalesOrder;
  if (!items?.length) items = [];

  const addLineItem = () => {
    const newItems = [...items, {}];
    onChange({ path: 'items', value: newItems });
  };

  const removeLineItem = rowIndex => {
    let newItems = [...items];
    newItems.splice(rowIndex, 1);
    onChange({ path: 'items', value: newItems });
  };

  const editLineItem = ({ rowIndex, path, value }) => {
    let newItems = [...items];
    newItems[rowIndex] = setNestedKeyOnClone({ object: newItems[rowIndex], path, value });
    onChange({ path: 'items', value: newItems });
  };

  const DeleteButton = ({ rowIndex }) => (
    <Tooltip title='Delete line item' placement='top' enterDelay={1500}>
      <IconButton onClick={() => removeLineItem(rowIndex)}>
        <DeleteIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  );

  const AddButton = () => (
    <Tooltip title='Add line item' placement='top' enterDelay={1500}>
      <IconButtonContainer>
        <IconButton onClick={addLineItem}>
          <AddIcon />
        </IconButton>
      </IconButtonContainer>
    </Tooltip>
  );
  return (
    <CardTemplate outerStyle={{ gridColumn: '1 / -1' }} title='Line Items'>
      <TableContainer>
        <Table
          columns={[
            {
              title: '',
              render: ({ rowIndex }) => rowIndex + 1,
              flex: '0 0 40px',
            },
            {
              title: 'Qty',
              dataIndex: 'qty',
              flex: '0 1 80px',
              render: ({ qty, rowIndex }) => (
                <FormIntegerInput
                  inputStyle={{ textAlign: 'center' }}
                  value={qty}
                  onChange={value => editLineItem({ rowIndex, path: 'qty', value })}
                />
              ),
            },
            {
              title: 'SKU',
              dataIndex: 'sku',
              flex: '0 1 180px',
              render: ({ sku, rowIndex }) => (
                <FormStringInput
                  value={sku}
                  onChange={value => editLineItem({ rowIndex, path: 'sku', value })}
                />
              ),
            },
            {
              title: 'Part Number',
              dataIndex: 'partNumber',
              flex: '0 1 180px',
              render: ({ rowData, rowIndex }) => (
                <EditablePartNumber
                  item={rowData}
                  rowIndex={rowIndex}
                  editLineItem={editLineItem}
                />
              ),
            },
            {
              title: 'Price',
              dataIndex: 'salesPrice',
              flex: '0 1 140px',
              render: ({ salesPrice, rowIndex }) => {
                return (
                  <FormCurrencyInput
                    value={salesPrice}
                    onChange={value => editLineItem({ rowIndex, path: 'salesPrice', value })}
                  />
                );
              },
            },
            {
              title: 'Weight',
              dataIndex: 'custom',
              flex: '0 1 130px',
              render: ({ custom, rowIndex }) => (
                <FormWeightInput
                  value={custom?.weight ?? ''}
                  onChange={value => editLineItem({ rowIndex, path: 'custom.weight', value })}
                />
              ),
            },
            {
              title: 'Box',
              dataIndex: 'custom',
              flex: '0 1 120px',
              render: ({ custom, rowIndex }) => (
                <FormStringInput
                  inputStyle={{ textAlign: 'center' }}
                  value={custom?.boxPartNumber ?? ''}
                  onChange={value =>
                    editLineItem({ rowIndex, path: 'custom.boxPartNumber', value })
                  }
                />
              ),
            },
            {
              title: 'Planned\nShip Date',
              dataIndex: 'plannedShipDate',
              dataType: 'date',
              flex: '0 0 160px',
              render: ({ plannedShipDate, rowIndex }) => (
                <FormDateInput
                  value={plannedShipDate}
                  onChange={value =>
                    editLineItem({ rowIndex, path: 'plannedShipDate', value })
                  }
                />
              ),
            },
            {
              title: 'Actual\nShip Date',
              dataIndex: 'actualShipDate',
              dataType: 'date',
              flex: '0 0 160px',
              render: ({ actualShipDate, rowIndex }) => (
                <FormDateInput
                  value={actualShipDate}
                  onChange={value => editLineItem({ rowIndex, path: 'actualShipDate', value })}
                />
              ),
            },
            {
              title: 'Shipped',
              dataIndex: 'actualShipDate',
              flex: '0 1 55px',
              render: ({ actualShipDate, rowIndex }) => (
                <FormBooleanInput
                  checked={actualShipDate}
                  onChange={() => {
                    editLineItem({
                      rowIndex,
                      path: 'actualShipDate',
                      value: actualShipDate ? null : new Date(),
                    });
                  }}
                />
              ),
            },
            {
              title: 'PO\nNumber',
              dataIndex: 'custom.poNumber',
              dataType: 'date',
              flex: '0 0 160px',
              render: ({ custom, rowIndex }) => (
                <FormStringInput
                  value={custom?.poNumber ?? ''}
                  onChange={value =>
                    editLineItem({ rowIndex, path: 'custom.poNumber', value })
                  }
                />
              ),
            },
            {
              title: 'Item Notes',
              dataIndex: 'notes',
              render: ({ notes, rowIndex }) => (
                <FormStringInput
                  value={notes}
                  onChange={value => editLineItem({ rowIndex, path: 'notes', value })}
                />
              ),
            },
            {
              title: 'Actions',
              flex: '0 1 70px',
              style: { padding: 0 },
              render: ({ rowIndex }) => <DeleteButton rowIndex={rowIndex} />,
            },
          ]}
          data={items}
          loading={false}
          error={false}
        />
      </TableContainer>
      <AddButton />
    </CardTemplate>
  );
}
export default LineItemsCard;

const TableContainer = styled.div`
  height: 350px;
  margin-bottom: 15px;
`;
const IconButtonContainer = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: white;
`;
