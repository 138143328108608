import React from 'react';
// Styled components
import styled from 'styled-components';
// Moment
import moment from 'moment';
// Datepicker
import { DatePicker } from 'antd';

export default function FormDateInput({ style, label, value, onChange, onBlur }) {
  const dateValue = value ? moment(value) : null;

  // Handle data changes differently based on the type.
  const handleChange = momentDate => {
    if (momentDate) {
      onChange(momentDate.toDate());
    } else {
      onChange(null);
    }
  };

  return (
    <Container style={style}>
      <InputLabel>{label}</InputLabel>
      <StyledDatePicker
        value={dateValue}
        format='MM/DD/YYYY'
        onChange={onChange ? handleChange : null}
        onBlur={onBlur}
        disabled={!onChange ? true : false}
      />
    </Container>
  );
}
const Container = styled.div``;

const InputLabel = styled.div`
  font-size: 12px;
  color: black;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  border-radius: 2px;
  border: none;
  outline: none;
  padding: 4px 8px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.05);

  &:disabled {
    background-color: #ededed;
  }
`;
