import React from 'react';
// GraphQL
import { useUpdateSalesOrderItem } from '../../../../graphQL/salesOrders/mutations/updateSalesOrderItem';
// Custom components
import SkuPartNumberLookup from '../../../../reusable/forms/SkuPartNumberLookup';

export default function EditablePartNumber({ item }) {
  const { mutate: updateSalesOrderItem } = useUpdateSalesOrderItem();

  const onProductFound = async ({ item, product }) => {
    await updateSalesOrderItem({
      variables: {
        item: {
          _id: item._id,
          partNumber: product.partNumber,
          'custom.boxPartNumber': product.custom.boxPartNumber,
        },
      },
      refetchQueries: ['GetSalesOrderItems'],
    });
  };

  return <SkuPartNumberLookup item={item} onProductFound={onProductFound} />;
}
