// GraphQL
import { gql, useMutation } from '@apollo/client';

const UPDATE_SALESORDER_ITEM = gql`
  mutation UpdateSalesOrder($item: JSON) {
    updateItem(item: $item) {
      partNumber
    }
  }
`;

export const useUpdateSalesOrderItem = () => {
  const [mutate, { data, loading, error }] = useMutation(UPDATE_SALESORDER_ITEM);
  return { mutate, data, loading, error };
};
