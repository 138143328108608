import React from 'react';
// Styled components
import styled from 'styled-components';
// Router
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
// Custom Components
import AppNavbar from './AppNavbar/AppNavbar';
import AppPageTemplate from './AppPageTemplate';
import WorkOrdersRouter from './work-orders/WorkOrdersRouter';
import SalesOrdersRouter from './sales-orders/SalesOrdersRouter';
import ProductsRouter from './products/ProductsRouter';
import WorkstationsRouter from './workstations/WorkstationsRouter/WorkstationsRouter';
import SettingsRoute from './settings/SettingsRoute';
import DashboardPageTemplate from './dashboard/DashboardPageTemplate';
import Reports from './dashboard/Reports';

function AppRouter() {
  const { path } = useRouteMatch();
  const user = localStorage.getObject('user');

  return (
    <>
      <AppNavbar />
      <Container>
        <Switch>
          <Route path={`${path}/dashboard`}>
            <DashboardPageTemplate title='Dashboard'>
              <Reports />
            </DashboardPageTemplate>
          </Route>
          <Route path={`${path}/products`}>
            <AppPageTemplate title='Products'>
              <ProductsRouter />
            </AppPageTemplate>
          </Route>
          <Route path={`${path}/sales-orders`}>
            <AppPageTemplate title='Sales Order Items'>
              <SalesOrdersRouter />
            </AppPageTemplate>
          </Route>
          <Route path={`${path}/work-orders`}>
            <AppPageTemplate title='Production Schedule'>
              <WorkOrdersRouter />
            </AppPageTemplate>
          </Route>
          <Route path={`${path}/workstations`}>
            <WorkstationsRouter />
          </Route>
          {/* Expose the route only if role is admin */}
          {user.role === 'admin' && (
            <Route path={`${path}/settings`}>
              <AppPageTemplate title='Settings'>
                <SettingsRoute />
              </AppPageTemplate>
            </Route>
          )}
          {/* Redirect to the dashboard if the user navigates to a page that does not exist */}
          <Route
            path={path}
            component={() => <Redirect to={`${path}/dashboard`}></Redirect>}
          />
        </Switch>
      </Container>
    </>
  );
}

export default AppRouter;

const Container = styled.div`
  height: calc(100vh);
  margin-left: 80px;
`;
