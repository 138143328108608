import React from 'react';
// Styled components
import styled from 'styled-components';
// Custom components
import CardTemplate from '../../../../../reusable/layout/CardTemplate';
import FormStringInput from '../../../../../reusable/forms/FormStringInput';
import FormIntegerInput from '../../../../../reusable/forms/FormIntegerInput';

export default function ProductionCard({ editedProduct, onChange }) {
  return (
    <CardTemplate title='Production'>
      <FormContainer>
        <FormStringInput
          label='Make or Buy'
          value={editedProduct?.custom?.makeOrBuy}
          onChange={value => onChange({ path: 'custom.makeOrBuy', value })}
        />
        <FormStringInput
          label='Supplier'
          value={editedProduct?.custom?.supplier}
          onChange={value => onChange({ path: 'custom.supplier', value })}
        />
        <FormIntegerInput
          label='Qty on Hand'
          value={editedProduct?.qtyOnHand}
          onChange={value => onChange({ path: 'qtyOnHand', value })}
        />
        <FormIntegerInput
          label='Min on Hand'
          value={editedProduct?.minOnHand}
          onChange={value => onChange({ path: 'minOnHand', value })}
        />
      </FormContainer>
    </CardTemplate>
  );
}

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
`;
