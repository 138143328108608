// GraphQL
import { gql, useMutation } from '@apollo/client';

const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($filter: JSON, $product: JSON) {
    updateProduct(filter: $filter, product: $product) {
      partNumber
      category
      mappedSkus
      qtyOnHand
      minOnHand
      productionNotes
      defaultOperations {
        default {
          name
          group
          groupIndex
        }
        rush {
          name
          group
          groupIndex
        }
      }
      custom
    }
  }
`;

export const useUpdateProduct = () => {
  const [mutate, { data, loading, error }] = useMutation(UPDATE_PRODUCT);
  return { mutate, data, loading, error };
};
