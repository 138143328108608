// GraphQL
import { gql } from '@apollo/client';

export const GET_WORKORDER_OPERATIONS = gql`
  query GetWorkOrderOperations($sort: JSON, $filter: JSON, $pagination: JSON) {
    operations(sort: $sort, filter: $filter, pagination: $pagination) {
      meta {
        skip
        limit
        totalRecords
      }
      data {
        _id
        name
        qtyComplete
        dateRequired
        dateCompleted
        workOrder {
          workOrderNumber
          type
          orderType
          priority
          status
          partNumber
          productConfig
          qtyToBuild
          dateCreated
          dateRequired
          productionNotes
        }
        product {
          partNumber
          custom
        }
      }
    }
  }
`;

// TODO: Add this back to the query
// product {
//   partNumber
//   custom
// }
