// GraphQL
import { gql } from '@apollo/client';

// Query for the current work order
export const GET_WORKORDER = gql`
  query GetWorkOrder($filter: JSON) {
    workOrder(filter: $filter) {
      data {
        workOrderNumber
        type
        status
        partNumber
        productConfig
        qtyToBuild
        dateCreated
        dateRequired
        productionNotes
        operations {
          _id
          name
          group
          groupIndex
          qtyComplete
          dateRequired
          dateCompleted
        }
        productionNotes
        custom
      }
    }
  }
`;
