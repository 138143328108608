// Ant Design
import { notification } from 'antd';

/**
 * Handles any error that can be returned from Apollo and creates an Ant
 * Design error message to notify the user
 * @param {*} e
 */
export default function (e) {
  // Check if we are online
  if (typeof window !== 'undefined' && !window.navigator.onLine) {
    notification.error({ description: 'Your browser is offline.', duration: 0 });
    return;
  }

  // Handle network errors
  if (e.networkError) {
    for (const error of e.networkError?.result?.errors ?? []) {
      notification.error({ description: error.message, duration: 0 });
    }
    return;
  }

  // Handle graphQL errors
  if (e.graphQLErrors) {
    for (const error of e.graphQLErrors) {
      notification.error({ description: error.message, duration: 0 });
    }
    return;
  }

  if (e.message) {
    notification.error({ description: e.message, duration: 0 });
    return;
  }

  notification.error({ description: 'An unknown error occurred', duration: 0 });
}
