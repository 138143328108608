import React, { useState, useEffect } from 'react';
// Styled components
import styled from 'styled-components';
// Ant Design
import { Popover } from 'antd';
// Material UI
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CheckCircle from '@material-ui/icons/CheckCircleRounded';
// GraphQL
import { useUpdateWorkOrderOperation } from '../../../../graphQL/workOrders/mutations/updateWorkOrderOperation';
// Helper Functions
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';
import parseJwt from '../../../../reusable/helper functions/parseJwt';

export default function RefactorCompletionComponent({ operation, queryParams }) {
  const { qtyComplete } = operation ?? {};
  const { qtyToBuild } = operation?.workOrder ?? {};

  const [value, setValue] = useState(qtyComplete);
  useEffect(() => {
    setValue(operation.qtyComplete ?? null);
  }, [operation]);

  const [visible, setVisible] = useState(false);
  const closePopover = () => {
    setVisible(false);
    setValue(qtyComplete);
  };

  // Mutation for updating the operation
  const { mutate: updateWorkOrderOperation } = useUpdateWorkOrderOperation();

  const token = localStorage.getItem('token');
  const { userId } = parseJwt(token);

  const updateQtyComplete = async () => {
    const qtyComplete = value;

    const operationUpdates = { _id: operation._id, qtyComplete };

    if (qtyComplete >= qtyToBuild) {
      operationUpdates.dateCompleted = new Date();
      operationUpdates.completedById = userId;
    } else {
      operationUpdates.dateCompleted = null;
      operationUpdates.completedById = null;
    }

    try {
      await updateWorkOrderOperation({
        variables: {
          operation: operationUpdates,
        },
        refetchQueries: ['GetWorkOrderOperations'],
      });
      closePopover();
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  return (
    <>
      <span>{`${qtyComplete ?? 0} / ${qtyToBuild}`}</span>
      <Popover
        trigger='click'
        visible={visible}
        onVisibleChange={visible => setVisible(visible)}
        destroyTooltipOnHide
        placement='right'
        content={
          <CustomMenuItem>
            <CompletionInput
              type='number'
              min={0}
              max={qtyToBuild}
              step={1}
              value={value}
              onChange={e => setValue(parseInt(e.target.value))}
            />
            <CompletionButton onClick={updateQtyComplete}>Set Qty Complete</CompletionButton>
          </CustomMenuItem>
        }
        trigger='click'
        style={{ width: '345px' }}>
        <Tooltip title='Mark task complete' placement='top'>
          <IconButton size='medium' style={{ marginLeft: '15px' }}>
            <CheckCircle />
          </IconButton>
        </Tooltip>
      </Popover>
    </>
  );
}

const CustomMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 10px;
  outline: none;
  border: none;
`;
const CompletionButton = styled.button`
  width: 150px;
  text-align: center;
  cursor: pointer;
  background-color: #173753;
  border-radius: 4px;
  text-align: center;
  outline: none;
  border: none;
  color: white;
  margin-left: 15px;
  &:hover {
    transition-duration: 0.3s;
    background-color: #1c5485;
  }
  &:disabled {
    background-color: lightgrey;
    color: darkgrey;
    cursor: not-allowed;
  }
`;
const CompletionInput = styled.input`
  border: 1px solid lightgrey;
  border-radius: 4px;
  margin-right: 15px;
  outline: none;
  width: 40px;
  text-align: center;
  height: 32px;
  margin-right: auto;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
