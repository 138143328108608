// GraphQL
import { gql, useMutation } from '@apollo/client';

const UPDATE_WORKORDER = gql`
  mutation UpdateWorkOrder($filter: JSON, $workOrder: JSON) {
    updateWorkOrder(filter: $filter, workOrder: $workOrder) {
      priority
      status
      partNumber
      productConfig
      qtyToBuild
      dateCreated
      dateRequired
      operations {
        _id
        name
        group
        groupIndex
        qtyComplete
        dateRequired
        dateCompleted
      }
      productionNotes
      custom
    }
  }
`;

export const useUpdateWorkOrder = () => {
  const [mutate, { data, loading, error }] = useMutation(UPDATE_WORKORDER);
  return { mutate, data, loading, error };
};
