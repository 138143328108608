import React, { useState, useEffect } from 'react';
//Routing
import { withRouter, Prompt } from 'react-router-dom';
// Styled components
import styled from 'styled-components';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
// Ant Design
import { Button } from 'antd';
// GraphQL
import { useQuery } from '@apollo/client';
import { GET_WORKORDER } from '../../../../graphQL/workOrders/queries/getWorkOrder';
import { useUpdateWorkOrder } from '../../../../graphQL/workOrders/mutations/updateWorkOrder';
// Custom Components
import DetailsCard from './cards/DetailsCard';
import GalleryCard from './cards/GalleryCard';
import AccountingCard from './cards/AccountingCard';
import EditOperationsCard from './cards/EditOperationsCard/EditOperationsCard';
// Deep Object Diff
import { diff } from 'deep-object-diff';
// Bar Loader
// import BarLoader from 'react-bar-loader';
// Helper Functions
import setNestedKeyOnClone from '../../../../reusable/helper functions/setNestedKeyOnClone';
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

function EditWorkOrder({ match, goBack }) {
  const { workOrderId } = match.params ?? {};
  const isNumberString = /^\d+$/;
  const workOrderNumber = isNumberString.test(workOrderId)
    ? Number(workOrderId)
    : String(workOrderId);

  const { loading: queryLoading, data: queryData } = useQuery(GET_WORKORDER, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: { workOrderNumber },
    },
  });

  // When the current work order is fetched, set it in state
  const [originalWorkOrder, setOriginalWorkOrder] = useState({});
  const [editedWorkOrder, setEditedWorkOrder] = useState({});
  useEffect(() => {
    const workOrder = queryData?.workOrder?.data?.[0];
    if (workOrder) {
      setEditedWorkOrder(workOrder);
      setOriginalWorkOrder(workOrder);
    }
  }, [queryData]);

  // Editing the work order
  const onChange = ({ path, value }) => {
    const newEditedWorkOrder = setNestedKeyOnClone({ object: editedWorkOrder, path, value });
    setEditedWorkOrder(newEditedWorkOrder);
  };

  // Track when the edited work order is different than the original work order
  const [workOrderIsEdited, setWorkOrderIsEdited] = useState(false);
  useEffect(() => {
    const changes = diff(originalWorkOrder, editedWorkOrder);
    if (Object.keys(changes).length > 0) {
      setWorkOrderIsEdited(true);
      // If there are unsaved changes, prompt the user if they try to close
      // the tab or refresh the page
      window.onbeforeunload = () => true;
    } else {
      setWorkOrderIsEdited(false);
      window.onbeforeunload = undefined;
    }
  }, [originalWorkOrder, editedWorkOrder]);

  const onBack = () => {
    // TODO: Check for changes before we go back. If there
    // are changes, prompt the user to save the changes.
    goBack();
  };

  // When the user has saved, automatically go back. We must wait for "productIsEdited" to be false
  const [goBackFlag, setGoBackFlag] = useState(false);
  useEffect(() => {
    if (goBackFlag && !workOrderIsEdited) goBack();
  }, [goBackFlag, workOrderIsEdited]);

  const BackButton = () => (
    <IconButton onClick={onBack}>
      <BackIcon size='small' />
    </IconButton>
  );

  // Reset the work order to original state
  const onCancel = () => {
    setEditedWorkOrder(originalWorkOrder);
  };

  // Mutation for saving the changes to the work order
  const { mutate: updateWorkOrder, loading: updateLoading } = useUpdateWorkOrder();

  // Save the changes to the work order
  const onSave = async () => {
    try {
      await updateWorkOrder({
        variables: {
          filter: { workOrderNumber: editedWorkOrder.workOrderNumber },
          workOrder: editedWorkOrder,
        },
        refetchQueries: ['GetWorkOrder'],
      });

      setGoBackFlag(true);
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  return (
    <>
      {/* 
          If there are unsaved changes, prompt the user before navigating 
          using any kind of routing within the app 
      */}
      <Prompt
        when={workOrderIsEdited}
        message='You have unsaved changes. Are you sure you want to leave the page?'
      />
      <Header>
        <HeaderContent>
          <BackButton />
          <Title>Edit Work Order | {workOrderNumber}</Title>
          {workOrderIsEdited ? (
            <>
              <CancelButton
                size='large'
                onClick={onCancel}>
                Cancel
              </CancelButton>
              <SaveButton
                size='large'
                type='primary'
                onClick={onSave}>
                Save Changes <ArrowBackRounded fontSize='small' />
              </SaveButton>
            </>
          ) : null}
        </HeaderContent>
        {/* {queryLoading || updateLoading ? <StyledBarLoader height={3} color='#8aa1b5' /> : null} */}
      </Header>
      <Body>
        <Col>
          <DetailsCard
            editedWorkOrder={editedWorkOrder}
            onChange={onChange}
          />
          <AccountingCard
            editedWorkOrder={editedWorkOrder}
            onChange={onChange}
          />
        </Col>
        <Col>
          <GalleryCard
            editedWorkOrder={editedWorkOrder}
            onChange={onChange}
          />
          <EditOperationsCard
            workOrder={editedWorkOrder}
            onChange={onChange}
          />
        </Col>
      </Body>
    </>
  );
}
export default withRouter(EditWorkOrder);

const Header = styled.div`
  border-bottom: 2px solid #7e95a8;
  padding-bottom: 15px;
`;
const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  overflow-y: auto;
  height: 100%;
  border-radius: 8px;
  padding-top: 15px;
`;
const Title = styled.div`
  font-size: 24px;
  margin-left: 35px;
`;
const CancelButton = styled(Button)`
  margin-left: auto;
`;
const SaveButton = styled(Button)`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
// const StyledBarLoader = styled(BarLoader)`
//   position: relative;
//   margin-top: -3px;
//   bottom: -14px;
// `;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
