import React, { useState } from 'react';
// GraphQL
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { GET_WORKORDERS } from '../../../../graphQL/workOrders/queries/getWorkOrders';
// Styled components
import styled from 'styled-components';
// Helper functions
import broadcastApolloError from '../../../../reusable/helper functions/broadCastApolloError';

export default function PriorityInput({ priority, onChange }) {
  const [value, setValue] = useState('');

  const handleChange = e => {
    let newValue = parseInt(e.target.value);
    if (!isNaN(newValue)) {
      setValue(newValue);
    } else {
      setValue('');
    }
  };

  const onBlur = () => {
    if (
      value &&
      value !== '' &&
      typeof value === 'number' &&
      value !== priority &&
      value <= maxPriority &&
      value >= 1
    ) {
      onReorder({ draggedFromPriority: priority, droppedOnPriority: value });
      setValue('');
    } else {
      setValue('');
    }
  };

  const onKeyDown = e => {
    if (e.key === 'Enter')
      onReorder({ draggedFromPriority: priority, droppedOnPriority: value });
  };

  const client = useApolloClient();
  const queryData = client.readQuery({ query: GET_WORKORDERS })?.workOrders ?? {};
  const { meta, data: workOrders } = queryData;
  const maxPriority = meta.totalRecords ?? 1;

  const [reorderWorkOrders] = useMutation(
    gql`
      mutation ReorderWorkOrders($draggedFrom: JSON, $droppedOn: JSON) {
        reorderWorkOrders(draggedFrom: $draggedFrom, droppedOn: $droppedOn) {
          workOrderNumber
        }
      }
    `
  );

  const onReorder = async ({ draggedFromPriority, droppedOnPriority }) => {
    const draggedFrom = workOrders.find(
      workOrder => workOrder.priority === draggedFromPriority
    );
    const droppedOn = { priority: droppedOnPriority };

    try {
      await reorderWorkOrders({
        variables: {
          draggedFrom,
          droppedOn,
        },
        refetchQueries: ['GetWorkOrders'],
      });
    } catch (e) {
      broadcastApolloError(e);
    }
  };

  return (
    <Input
      value={value}
      onKeyDown={onKeyDown}
      type='number'
      min={1}
      max={maxPriority ?? 1}
      step={1}
      placeholder={priority}
      onChange={handleChange}
      onBlur={onBlur}
    />
  );
}

const Input = styled.input`
  font-size: 16px;
  width: 100%;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 4px 8px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.05);
  text-align: center;

  &:disabled {
    background-color: #ededed;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
