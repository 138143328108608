import React from 'react';
// Styled components
import styled from 'styled-components';
// Custom components
import CardTemplate from '../../../../../reusable/layout/CardTemplate';
import FormStringInput from '../../../../../reusable/forms/FormStringInput';
import FormIntegerInput from '../../../../../reusable/forms/FormIntegerInput';
import FormDateInput from '../../../../../reusable/forms/FormDateInput';
import PartNumberDropdown from '../../../../../reusable/forms/PartNumberDropdown';
import FormDropdown from '../../../../../reusable/forms/FormDropdown';

function DetailsCard({ editedWorkOrder, onChange, creating }) {
  return (
    <CardTemplate title='Work Order Details'>
      <FormContainer>
        {creating ? (
          <>
            <FormIntegerInput
              label='Priority'
              value={editedWorkOrder.priority}
              onChange={value => onChange({ path: 'priority', value })}
            />
            <div />
            <div />
          </>
        ) : null}
        <FormDropdown
          label='Work Order Type'
          placeholder='Select'
          options={['stock', 'custom', 'rework']}
          value={editedWorkOrder.type || 'stock'}
          onChange={value => onChange({ path: 'type', value })}
          defaultValue='stock'
        />
        <FormIntegerInput
          label='Work Order Number'
          value={editedWorkOrder.workOrderNumber}
          disabled={true}
          onChange={value => onChange({ path: 'workOrderNumber', value })}
        />
        <PartNumberDropdown
          label='Part Number'
          value={editedWorkOrder.partNumber}
          onChange={value => onChange({ path: 'partNumber', value })}
        />
        <FormIntegerInput
          label='Qty To Build'
          value={editedWorkOrder.qtyToBuild}
          onChange={value => onChange({ path: 'qtyToBuild', value })}
        />
        <FormDateInput
          label='Date Required'
          value={editedWorkOrder.dateRequired}
          onChange={value => onChange({ path: 'dateRequired', value })}
        />
        <FormStringInput
          label='Production Notes'
          value={editedWorkOrder.productionNotes}
          onChange={value => onChange({ path: 'productionNotes', value })}
        />
      </FormContainer>
    </CardTemplate>
  );
}
export default DetailsCard;

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
`;
