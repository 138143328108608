import React from 'react';
// Styled components
import styled from 'styled-components';
// Custom components
import CardTemplate from '../../../../../reusable/layout/CardTemplate';
import FormStringInput from '../../../../../reusable/forms/FormStringInput';

export default function AssemblyDetailsCard({ editedProduct, onChange }) {
  return (
    <CardTemplate title='Assembly Details'>
      <FormContainer>
        <FormStringInput
          label='Color'
          value={editedProduct?.custom?.color}
          onChange={value => onChange({ path: 'custom.color', value })}
        />
        <FormStringInput
          label='Bundle 1 Part Number'
          value={editedProduct?.custom?.bundle1PartNumber}
          onChange={value => onChange({ path: 'custom.bundle1PartNumber', value })}
        />
        <FormStringInput
          label='Bundle 2 Part Number'
          value={editedProduct?.custom?.bundle2PartNumber}
          onChange={value => onChange({ path: 'custom.bundle2PartNumber', value })}
        />
        <FormStringInput
          label='Shell 1 Material'
          value={editedProduct?.custom?.shellMaterial}
          onChange={value => onChange({ path: 'custom.shellMaterial', value })}
        />
        <FormStringInput
          label='Shell 1 Length'
          value={editedProduct?.custom?.shellLength}
          onChange={value => onChange({ path: 'custom.shellLength', value })}
        />
        <FormStringInput
          label='Shell 1 Diameter'
          value={editedProduct?.custom?.shellDiameter}
          onChange={value => onChange({ path: 'custom.shellDiameter', value })}
        />
        <FormStringInput
          label='Shell 2 Material'
          value={editedProduct?.custom?.shell2Material}
          onChange={value => onChange({ path: 'custom.shell2Material', value })}
        />
        <FormStringInput
          label='Shell 2 Length'
          value={editedProduct?.custom?.shell2Length}
          onChange={value => onChange({ path: 'custom.shell2Length', value })}
        />
        <FormStringInput
          label='Shell 2 Diameter'
          value={editedProduct?.custom?.shell2Diameter}
          onChange={value => onChange({ path: 'custom.shell2Diameter', value })}
        />
      </FormContainer>
    </CardTemplate>
  );
}

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
`;
