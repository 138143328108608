// GraphQL
import { gql, useMutation } from '@apollo/client';

const REMOVE_PRODUCT = gql`
  mutation RemoveProduct($filter: JSON) {
    removeProduct(filter: $filter) {
      partNumber
    }
  }
`;

export const useRemoveProduct = () => {
  const [mutate, { data, loading, error }] = useMutation(REMOVE_PRODUCT);
  return { mutate, data, loading, error };
};
