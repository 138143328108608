import React from 'react';
// Router
import history from '../../../routing/history';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
// Custom Components
import SalesOrderItemsTable from './SalesOrderItemsTable';
import EditSalesOrder from './Edit&Create/EditSalesOrder';
import CreateSalesOrder from './Edit&Create/CreateSalesOrder';

export default function SaleOrdersRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <SalesOrderItemsTable />
      </Route>
      <Route path={`${path}/edit/:salesOrderNumber`}>
        <EditSalesOrder goBack={() => history.push(path)} />
      </Route>
      <Route path={`${path}/create`}>
        <CreateSalesOrder goBack={() => history.push(path)} salesOrdersPath={path} />
      </Route>
      <Route path={path}>Oops! - 404</Route>
    </Switch>
  );
}
