// GraphQL
import { gql, useMutation } from '@apollo/client';

const UPDATE_WORKORDER_OPERATION = gql`
  mutation UpdateWorkOrderOperation($operation: JSON!) {
    updateOperation(operation: $operation) {
      _id
      name
      group
      groupIndex
      qtyComplete
      dateRequired
      dateCompleted
    }
  }
`;

export const useUpdateWorkOrderOperation = () => {
  const [mutate, { data, loading, error }] = useMutation(UPDATE_WORKORDER_OPERATION);
  return { mutate, data, loading, error };
};
