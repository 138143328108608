import React from 'react';
// Router
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
// Custom Components
import CompanySettings from './users/CompanySettings';

export default function SettingsRoute() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={path}
        component={() => <Redirect to={`${path}/company`}></Redirect>}
      />
      <Route
        exact
        path={`${path}/company`}>
        <CompanySettings />
      </Route>
      <Route path={path}>Oops! - 404</Route>
    </Switch>
  );
}
