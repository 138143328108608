// GraphQL
import { gql, useMutation } from '@apollo/client';

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        givenName
        familyName
        role
        company {
          name
        }
      }
    }
  }
`;

export const useLogin = () => {
  const [mutate, { data, loading, error }] = useMutation(LOGIN);
  return { mutate, data, loading, error };
};
