import React from 'react';
// Styled components
import styled from 'styled-components';
// Custom components
import CardTemplate from '../../../../../reusable/layout/CardTemplate';
import FormStringInput from '../../../../../reusable/forms/FormStringInput';
import FormCurrencyInput from '../../../../../reusable/forms/FormCurrencyInput';

export default function AccountingCard({ editedWorkOrder, onChange }) {
  return (
    <CardTemplate title='Accounting'>
      <FormContainer>
        <ColumnTitle>Customer ← MRC</ColumnTitle>
        <ColumnTitle>MRC → Raven</ColumnTitle>
        <ColumnTitle>MRC ← Raven</ColumnTitle>
        <FormCurrencyInput
          label='Web Assy Price'
          value={editedWorkOrder?.custom?.assyPrice}
          onChange={value => onChange({ path: 'custom.assyPrice', value })}
        />
        <FormStringInput
          label='Purchase Order #'
          value={editedWorkOrder?.custom?.poNumberMRCToRaven}
          onChange={value => onChange({ path: 'custom.poNumberMRCToRaven', value })}
        />
        <FormStringInput
          label='Sales Order #'
          value={editedWorkOrder?.custom?.salesOrderNumberRavenToMRC}
          onChange={value => onChange({ path: 'custom.salesOrderNumberRavenToMRC', value })}
        />
        <FormStringInput
          label='Invoice #'
          value={editedWorkOrder?.custom?.invoiceNumberMRCToCustomer}
          onChange={value => onChange({ path: 'custom.invoiceNumberMRCToCustomer', value })}
        />
        <FormStringInput
          label='Invoice #'
          value={editedWorkOrder?.custom?.invoiceNumberRavenToMRC}
          onChange={value => onChange({ path: 'custom.invoiceNumberRavenToMRC', value })}
          style={{ gridColumnStart: 3, gridRowStart: 3 }}
        />
      </FormContainer>
    </CardTemplate>
  );
}
const ColumnTitle = styled.div`
  font-size: 16px;
  text-align: center;
  color: #173753;
`;
const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
`;
