import React, { useState, useEffect } from 'react';
// Custom Components
import FormStringInput from '../../../../reusable/forms/FormStringInput';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
// GraphQL
import { useUpdateSalesOrderItem } from '../../../../graphQL/salesOrders/mutations/updateSalesOrderItem';

export default function EditableNotes({ item }) {
  const { _id, notes } = item ?? {};

  const [value, setValue] = useState(notes ?? '');
  useEffect(() => {
    setValue(notes ?? '');
  }, [notes]);

  const onChange = value => {
    setValue(value);
  };

  const onBlur = () => {
    if (value !== notes)
      updateSalesOrderItem({
        variables: { item: { _id, notes: value } },
      });
  };

  const { mutate: updateSalesOrderItem, loading, data, error } = useUpdateSalesOrderItem();

  return loading ? (
    <CircularProgress size={28} />
  ) : (
    <FormStringInput value={value} onChange={onChange} onBlur={onBlur} />
  );
}
