/**
 * Handles any error that can be returned from Apollo and returns
 * a user friendly sting
 * @param {*} e
 */
export default function (e) {
  let errorMessage;
  let errorMessageArray = [];

  // Check if we are online
  if (typeof window !== 'undefined' && !window.navigator.onLine) {
    errorMessage = 'Your browser is offline.';
    return errorMessage;
  }

  // Handle network errors
  if (e.networkError) {
    for (const error of e.networkError?.result?.errors ?? []) {
      errorMessageArray.push(error.message);
    }
    errorMessage = errorMessageArray.join('\n');
    return errorMessage;
  }

  // Handle graphQL errors
  if (e.graphQLErrors) {
    for (const error of e.graphQLErrors) {
      errorMessageArray.push(error.message);
    }
    errorMessage = errorMessageArray.join('\n');
    return errorMessage;
  }

  return 'An error occurred.';
}
