import React from 'react';
// Custom components
import SkuPartNumberLookup from '../../../../../../reusable/forms/SkuPartNumberLookup';

export default function EditablePartNumber({ item, rowIndex, editLineItem }) {
  const onProductFound = async ({ product }) => {
    editLineItem({
      rowIndex: rowIndex,
      path: 'partNumber',
      value: product?.partNumber,
    });
    editLineItem({
      rowIndex: rowIndex,
      path: 'custom.boxPartNumber',
      value: product?.custom?.boxPartNumber,
    });
  };

  return <SkuPartNumberLookup item={item} onProductFound={onProductFound} />;
}
